import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { showKeyboard, uppercaseKeyboard } from '../../containers/App/actions';
import { makeSelectKeyboardState } from '../../containers/App/selectors';
import Keyboard from '../reusable/Input/Keyboard';

export function KeyboardModal(props) {
  // console.log('KeyboardModal: ', props);

  const dispatch = useDispatch();

  const hideKeyboard = (event) => {
    dispatch(showKeyboard(false));
  };

  const setUppercaseKeyboard = (event) => {
    dispatch(uppercaseKeyboard(event));
  };

  return props.keyboardData.showKeyboard && props?.keyboardData?.input != null ? (
    <div style={{ height: '200px' }}>
      <Keyboard
        hideKeyboard={(event) => hideKeyboard(event)}
        uppercase={props?.keyboardData?.uppercase}
        setUppercaseKeyboard={(event) => setUppercaseKeyboard(event)}
        defaultKeyboard={'us'}
        inputNode={props?.keyboardData?.input}
        dataset={{ type: 'input' }}
        opacity={0.9}
        isDraggable={false}
        isFirstLetterUppercase={true}
      />
    </div>
  ) : null;
}

const mapStateToProps = createStructuredSelector({
  keyboardData: makeSelectKeyboardState()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyboardModal);
