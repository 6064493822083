/*
 ** EditView sodra define fields for editview reducer
 */
import { BSHOrder, Order, Layer, RecipeLayer, Recipe, IncomingPackage, PlanerSetup } from '../../../utils/models';
import { orderChildCalculateValues, arborealMaterialCodeCalculator, schilligerChildCalculateValues, schneiderModelCalculator } from '../../../utils/modelFunctions/calculators';
import { generalValidator, planerSetupValidator } from '../../../utils/modelFunctions/validators';

const schneider_config = {
  orderFields: [
    [
      [{ ...Order.fields.orderPicture }],
      [
        { ...Order.fields.orderCharge },
        { ...Order.fields.masterpanel },
        { ...Order.fields.rawThickness },
        { ...Order.fields.lamellaThickness },
        { ...Order.fields.rawWidth },
        { ...Order.fields.woodType },
        { ...Order.fields.quality },

        { ...Order.fields.splitWood },
        { ...Order.fields.batchCutOnLength },
        { ...Order.fields.assignedDimensions },
        { ...Order.fields.additionalInfo }
      ]
    ]
    // ]
  ],

  recipeOrderFields: [
    [
      [{ ...Recipe.fields.orderPicture }],
      [
        { ...Recipe.fields.identifier },
        { ...Order.fields.panelThickness },
        { ...Order.fields.oversize, halfsize: true, editable: true },
        { ...Order.fields.lenOversizeL, halfsize: true, editable: true },
        { ...Order.fields.oversizeWidth, halfsize: true },
        { ...Order.fields.lenOversizeC, halfsize: true }
      ]
    ]
  ],

  incomingPackageFields: [
    [
      [
        { ...IncomingPackage.fields.identifier },
        { ...IncomingPackage.fields.quality },
        { ...IncomingPackage.fields.woodType },
        { ...IncomingPackage.fields.thickness },
        { ...IncomingPackage.fields.width },
        { ...IncomingPackage.fields.lamellaLength },
        { ...IncomingPackage.fields.packageNumber },
        { ...IncomingPackage.fields.piecesPerLayer }
      ]
    ]
  ],

  bshorderFields: [
    /* [
       { ...BSHOrder.fields.orderPicture }
     ], */
    [
      // { ...BSHOrder.fields.quality },
      { ...BSHOrder.fields.client },
      { ...BSHOrder.fields.lamellaLength },
      { ...BSHOrder.fields.lamellaWidth },
      { ...BSHOrder.fields.lamellaThickness },
      { ...BSHOrder.fields.rawThickness },
      { ...BSHOrder.fields.rawWidth },
      { ...BSHOrder.fields.stackingNumOfLayers },
      { ...BSHOrder.fields.stackingLamellasPerLayer },
      { ...BSHOrder.fields.numOfLamellas }
    ]
  ],

  layerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, editable: false }, // positionInStack
        { ...Layer.fields.layerId, width: 1.2, editable: true },
        { ...Layer.fields.quality, width: 1.2 },
        { ...Layer.fields.orientation, width: 1.2 },
        { ...Layer.fields.rotation, width: 1.2 }
      ],
      [
        { ...Layer.fields.lamellaLength_schneider, width: 2 },
        { ...Layer.fields.panelWidth, width: 2 }
      ]
    ]
  ],

  recipeLayerFields: [
    [
      [
        { ...RecipeLayer.fields.layerNo, width: 1.2 },
        { ...RecipeLayer.fields.woodType, width: 1.2 },
        { ...RecipeLayer.fields.orientation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.rotation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.quality, width: 1.2 }
      ],
      [
        { ...RecipeLayer.fields.lamellaPlannedThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaPlannedWidth, width: 2 },
        { ...RecipeLayer.fields.minTakeAway, width: 2 }
      ],
      [
        { ...RecipeLayer.fields.hasGlue, width: 1.2 },
        { ...RecipeLayer.fields.whiteGlue, width: 1.2 }
      ]
    ]
  ],

  orderWithRecipeFields: [
    [
      [{ ...Order.fields.recipeSelect }, { ...Recipe.fields.getOrderWithRecipeImage }],
      [{ ...Recipe.fields.identifier }, { ...Order.fields.orderNumber }, { ...Order.fields.panelWidth }, { ...Order.fields.panelLength }]
    ]
  ],

  planerSetupFields: [
    [
      [
        { ...PlanerSetup.fields.rawWidth },
        { ...PlanerSetup.fields.rawThickness },
        { ...PlanerSetup.fields.lamellaPlannedThickness },
        { ...PlanerSetup.fields.reportedThickness },
        { ...PlanerSetup.fields.W1Takeaway },
        { ...PlanerSetup.fields.W3Takeaway },
        { ...PlanerSetup.fields.quality },
        { ...PlanerSetup.fields.posWhiteGlue },
        { ...PlanerSetup.fields.posHotmelt },
        { ...PlanerSetup.fields.linesOfWhiteGlue },
        { ...PlanerSetup.fields.linesOfHotmelt }
      ],
      [
        { ...PlanerSetup.fields.splitWood },
        { ...PlanerSetup.fields.turningZinc },
        { ...PlanerSetup.fields.turningAfterPress },
        { ...PlanerSetup.fields.turningBeforePress },
        { ...PlanerSetup.fields.turningEverySecond },
        { ...PlanerSetup.fields.turningEveryFirst },
        { ...PlanerSetup.fields.disableMixer }
      ],
      [{ ...PlanerSetup.fields.addedWidthFromShortToLong }, { ...PlanerSetup.fields.addedWidthFromLongToShort }, { ...PlanerSetup.fields.addedWidthEndOfGroup }]
    ]
  ],

  /* IF project needs custom validator or calculator for models */
  calculatorsConfig: {
    order: {
      modelCalculator: schneiderModelCalculator,
      childCalculator: null
    }
  }
};

export default schneider_config;
