export const ledinekIcon = [
  '160 160',
  `
    <title>Ledinek logo</title>
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve"  version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 366 366"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
    <style type="text/css">
    <![CDATA[
        .fil1 {fill:#f7c315}
        .fil0 {fill:#323232}
    ]]>
    </style>
    </defs>
    <g id="Layer_x0020_1" transform="">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <polygon class="fil1" points="117,41 213,41 191,250 280,250 272,326 86,326 "/>
    </g>
    </svg>
  `
];
