import { BSHOrder, IncomingPackage, BSHLayer, Recipe } from '../../../utils/models';

const solis_config = {
  incomingPackageFields: [
    [
      [
        // { ...IncomingPackage.fields.id},
        { ...IncomingPackage.fields.identifier },
        { ...IncomingPackage.fields.packageNumber },
        { ...IncomingPackage.fields.lamellaLength },
        { ...IncomingPackage.fields.width },
        { ...IncomingPackage.fields.thickness }
      ],
      [{ ...IncomingPackage.fields.piecesPerLayer }, { ...IncomingPackage.fields.numberOfLayers }]
    ]
  ],

  bshorderFields: [
    [
      [
        //{ ...BSHOrder.fields.id },
        { ...BSHOrder.fields.orderNumber },
        //{ ...BSHOrder.fields.quality },
        { ...BSHOrder.fields.rawThickness },
        { ...BSHOrder.fields.lamellaThickness },
        { ...BSHOrder.fields.rawWidth },
        { ...BSHOrder.fields.lamellaWidth },
        { ...BSHOrder.fields.lamellaLength },
        //{ ...BSHOrder.fields.numberOfLayers },
        //{ ...BSHOrder.fields.numOfLamellas },
        { ...BSHOrder.fields.lineRegimeNum },
        //{ ...BSHOrder.fields.moscaNumberOfBoards },
        //{ ...BSHOrder.fields.lengthMiddleCut1 },
        //{ ...BSHOrder.fields.lengthMiddleCut2 },
        { ...BSHOrder.fields.lengthsQualityData, editable: true }
      ]
      //[{ ...BSHOrder.fields.wrapping }, { ...BSHOrder.fields.headCut }, { ...BSHOrder.fields.middleCut }, { ...BSHOrder.fields.endCut }, { ...BSHOrder.fields.withMosca }]
    ]
  ],

  recipeOrderFields: [[[{ ...Recipe.fields.identifier }, { ...BSHOrder.fields.panelThickness }, { ...BSHOrder.fields.oversize, halfsize: true, editable: true }]]]
};

export default solis_config;
