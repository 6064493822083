/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/history';
import globalReducer from './containers/App/reducer';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import headerReducer from './containers/Header/reducer';
import tableViewReducer from './containers/TableView/reducer';
import websocketReducer from './containers/Websocket/reducer';
import editViewReducer from './containers/EditView/reducer';
import editControlReducer from './containers/EditControlModal/reducer';
import modalManagerReducer from './containers/ModalManager/reducer';
import pressReducer from './containers/Machines/ante/Press/reducer';
import imagePlaceholderReducer from './components/ImagePlaceholder/reducer';
import sidebarReducer from './containers/Sidebar/reducer';
import threedview from './containers/Fiber/reducer';
import WebsocketMessageModalReducer from './containers/WebsocketMessageModal/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    header: headerReducer,
    tableview: tableViewReducer,
    websocket: websocketReducer,
    sidebar: sidebarReducer,
    editview: editViewReducer,
    editcontrol: editControlReducer,
    modalmanager: modalManagerReducer,
    imagereducer: imagePlaceholderReducer,
    press: pressReducer,
    websocketMessageModal: WebsocketMessageModalReducer,
    threedview,
    ...injectedReducers
  });

  return rootReducer;
}
