const arolla_edit_settings = [
  {
    'set-infeed': {
      title: 'Set Infeed',
      settings: [
        { currentVacuumWidth: { type: 'status', identifier: 'currentVacuumWidth', value: 0 } },
        {
          currentVacuumThickness: { type: 'status', identifier: 'currentVacuumThickness', value: 0 }
        },
        { currentVacuumLength: { type: 'status', identifier: 'currentVacuumLength', value: 0 } },
        {
          manualVacuumAllowance: {
            type: 'boolStatus',
            identifier: 'manualVacuumAllowance',
            value: false
          }
        },
        {
          manualMultiplanAllowance: {
            type: 'boolStatus',
            identifier: 'manualMultiplanAllowance',
            value: false
          }
        }
      ]
    }
  },
  {
    'set-superplan': {
      title: 'Set Superplan',
      settings: [
        { packageWidth: { type: 'status', identifier: 'packageWidth', value: 0 } },
        { packageHeight: { type: 'status', identifier: 'packageHeight', value: 0 } },
        {
          sortingInfeedPlannedWidth: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidth',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThickness: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThickness',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-plates-storage': {
      title: 'Set Plates Storage',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingID: { type: 'status', identifier: 'manualFillingID', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } }
      ]
    }
  },
  {
    'arolla-press-print': {
      title: 'Press Print',
      settings: [
        {
          stickerPanelThickness: {
            type: 'nested-status',
            identifier: 'manualSticker',
            childIdent: 'stickerPanelThickness',
            value: 0
          }
        },
        {
          stickerPanelTopQaulity: {
            type: 'nested-status',
            identifier: 'manualSticker',
            childIdent: 'stickerPanelTopQaulity',
            value: 0
          }
        },
        {
          stickerPanelBottomQuality: {
            type: 'nested-status',
            identifier: 'manualSticker',
            childIdent: 'stickerPanelBottomQuality',
            value: 0
          }
        },
        {
          stickerPanelId: {
            type: 'nested-status',
            identifier: 'manualSticker',
            childIdent: 'stickerPanelId',
            value: 0
          }
        }
      ]
    }
  }
];

export default arolla_edit_settings;
