/*
 *
 * EventLog actions
 *
 */

import {
  API_CALL,
  PACKAGES_LOADED,
  CHECKBOX_TOGGLED,
  CLEAR_CHECKED_ITEMS,
  DELETE_ORDER,
  DELETE_PROJECT,
  ITEMS_MOVED,
  ADD_RECIPE,
  ORDER_RECIPE,
  DELETE_RECIPE,
  DELETE_PRODUCT,
  CHANGE_EXTRA,
  DELETE_EXTRA,
  PRINT_STICKER,
  BACKEND_CALL,
  BACKEND_CALL_FINISHED,
  CONTROL_CALL,
  CONTROL_CALL_FINISHED,
  SHOW_LOADER,
  HIDE_LOADER,
  CLEAR_DATA,
  RESET_SOURCE,
  DOWNLOAD_ITEM,
  API_ERROR,
  URL_ACTION,
  DELETE_SUPPLIER
} from './constants';

export function backendCall(source, url, callType, items) {
  return {
    type: BACKEND_CALL,
    source,
    url,
    callType,
    items
  };
}

export function backendCallFinished(source, response) {
  return {
    type: BACKEND_CALL_FINISHED,
    source,
    response
  };
}

export function controlCall(source, identifier, items) {
  return {
    type: CONTROL_CALL,
    source,
    identifier,
    items
  };
}

export function controlCallFinished(source, response) {
  return {
    type: CONTROL_CALL_FINISHED,
    source,
    response
  };
}

export function apiCall(query, sort, apicall, filterArray) {
  return {
    type: API_CALL,
    query,
    sort,
    apicall,
    filterArray
  };
}

export function addRecipe(items) {
  return {
    type: ADD_RECIPE,
    items
  };
}

export function addOrderRecipe(items) {
  return {
    type: ORDER_RECIPE,
    items
  };
}

export function packagesLoaded(page, items, queries) {
  return {
    type: PACKAGES_LOADED,
    page,
    items,
    queries
  };
}

export function fieldCheckboxToggled(item, value) {
  return {
    type: CHECKBOX_TOGGLED,
    item,
    value
  };
}

export function clearCheckedItems() {
  return {
    type: CLEAR_CHECKED_ITEMS
  };
}

export function itemsMoved(response) {
  return {
    type: ITEMS_MOVED,
    response
  };
}

export function deleteOrder(items) {
  return {
    type: DELETE_ORDER,
    items
  };
}

export function deleteProject(items) {
  return {
    type: DELETE_PROJECT,
    items
  };
}

export function deleteRecipe(items) {
  return {
    type: DELETE_RECIPE,
    items
  };
}

export function deleteProduct(items) {
  return {
    type: DELETE_PRODUCT,
    items
  };
}

export function deleteSupplier(items) {
  return {
    type: DELETE_SUPPLIER,
    items
  };
}

export function deleteExtraLamella(items) {
  return {
    type: DELETE_EXTRA,
    items
  };
}

export function changeExtraLamellas(change, id) {
  let c = 1;
  change === 'plusOne' ? (c = 1) : (c = -1);
  return {
    type: CHANGE_EXTRA,
    change: c,
    id
  };
}

export function printSticker(action, id) {
  return {
    type: PRINT_STICKER,
    id
  };
}

export function urlAction(url, action, id, data) {
  return {
    type: URL_ACTION,
    url,
    action,
    id,
    data
  };
}

export function showLoader(value) {
  return {
    type: SHOW_LOADER,
    value
  };
}

export function hideLoader(value) {
  return {
    type: HIDE_LOADER,
    value
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA
  };
}

export function resetSource() {
  return {
    type: RESET_SOURCE
  };
}

export function downloadItem(items) {
  console.log('DownloadItem');
  return {
    type: DOWNLOAD_ITEM,
    items
  };
}

export function apiError(error) {
  return {
    type: API_ERROR,
    error
  };
}
