import modelFieldMessages from '../modelFieldMessages';

export const qualityChoices = {
  I: modelFieldMessages.iQuality,
  0: modelFieldMessages.zeroQuality
};

export const orientationChoices = {
  3: modelFieldMessages.KVHPackageType,
  2: modelFieldMessages.qPackageType,
  1: modelFieldMessages.lPackageType
};

export const rotationChoices = {
  // 2: modelFieldMessages.altRotation,
  1: modelFieldMessages.upRotation,
  0: modelFieldMessages.downRotation
};

export const exitChoices = {
  1: modelFieldMessages.kallesoe,
  2: modelFieldMessages.exit
};

export const ante_config = {
  qualityChoices,
  orientationChoices,
  rotationChoices,
  exitChoices
};

export default ante_config;
