const mm2_edit_settings = [
  {
    'set-infeed': {
      title: 'Set Infeed',
      settings: [
        { rawSortingLoading: { type: 'status', identifier: 'rawSortingLoading', value: null } },
        {
          allowanceRawInfeed: { type: 'boolStatus', identifier: 'allowanceRawInfeed', value: false }
        },
        {
          numberOfBoardsBeforePlaner: {
            type: 'status',
            identifier: 'numberOfBoardsBeforePlaner',
            value: 0
          }
        },
        {
          allowanceMultiplan: { type: 'boolStatus', identifier: 'allowanceMultiplan', value: false }
        }
      ]
    }
  },
  {
    'set-superplan': {
      title: 'Set Superplan',
      settings: [
        {
          sortingInfeedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedThicknessOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        },
        { sortingInfeedWidth: { type: 'status', identifier: 'sortingInfeedWidth', value: 0 } },
        {
          sortingInfeedThickness: { type: 'status', identifier: 'sortingInfeedThickness', value: 0 }
        },
        {
          sortingInfeedPlannedWidth: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidth',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingEtage: { type: 'status', identifier: 'manualFillingEtage', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } },
        { dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }
      ]
    }
  },
  {
    'set-zpress': {
      title: 'Set Zpress',
      settings: [
        {
          whiteGlue: {
            type: 'boolStatus',
            identifier: 'whiteGlue',
            value: false
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingEtage: { type: 'status', identifier: 'manualFillingEtage', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } },
        { dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }
      ]
    }
  },
  {
    'set-zink': {
      title: 'Set Zink',
      settings: [
        {
          woodBufferForDimension: {
            type: 'boolStatus',
            identifier: 'woodBufferForDimension',
            value: false
          }
        },
        {
          negativeWoodBufferForBetterDimensionNext: {
            type: 'status',
            identifier: 'negativeWoodBufferForBetterDimensionNext',
            value: 0
          }
        },
        {
          percentageOfWoodBeforeGoldenEye: {
            type: 'status',
            identifier: 'percentageOfWoodBeforeGoldenEye',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-no-group': {
      title: 'Set No Group',
      settings: [
        { noGroupMode: { type: 'boolStatus', identifier: 'noGroupMode', value: false } },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        { noGroupModeCOversize: { type: 'status', identifier: 'noGroupModeCOversize', value: 0 } },
        { noGroupModeLOversize: { type: 'status', identifier: 'noGroupModeLOversize', value: 0 } },
        { kvhOversize: { type: 'status', identifier: 'kvhOversize', value: 0 } },
        { xcutOversize: { type: 'status', identifier: 'xcutOversize', value: 0 } }
      ]
    }
  },
  {
    'set-editjd': {
      settings: [],
      cherryPick: [
        'id',
        'lamellaThickness',
        'lamellaWidth',
        'lamellaLength',
        'lamellaPlannedThickness',
        'lamellaPlannedWidth',
        'numLamellas',
        'zincDoneNumLamellas',
        'quality',
        'orientation',
        'orderId',
        'type',
        'isWaste',
        'isTest',
        'omitCalculation',
        'FJmachine',
        'producingOnZinc',
        'finishedProducingOnZinc',
        'outputPosition',
        'enoughRawMaterial',
        'repairOrder',
        'extraLamellaOrder',
        'producingOnZPress',
        'finishedProducingOnZPress',
        'throughZPress',
        'acknowledgedAtZinc',
        'emptied',
        'finishedProducingOnZPress',
        'producingOnZPress',
        'throughZPress',
        'zPressDoneNumLamellas',
        'dimensionChangeFromNextGroup',
        'dimBreakOf',
        'dimBreakId',
        'qualityN',
        'extraLamella',
        'forceEmpty'
      ]
    }
  },
  {
    'set-editControlOrder': {
      settings: [],
      cherryPick: ['panelWidth', 'panelThickness', 'panelLength', 'id', 'woodType', 'pressId', 'jobCode', 'quality', 'status', 'group', 'isKVH', 'masterpanel', 'layers', 'sentToPress']
    }
  }
];

export default mm2_edit_settings;
