/*
 *
 * Header reducer
 *
 */
import produce from 'immer';
import _ from 'lodash';
import { DEFAULT_ACTION } from './constants';
import { CLT_STATUS_RECEIVED } from '../Websocket/constants';

export const initialState = {
  packageLoadedSize: 0,
  materialNeededSize: 0,
  sortingQueueSize: 0,
  layersQueueSize: 0,
  ordersQueueSize: 0,
  errorsListSize: 0,
  machineStats: [],
  errorsList: []
};

/* eslint-disable default-case, no-param-reassign */
const headerReducer = (state = initialState, action) =>
  produce(state, (/* draft */) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case CLT_STATUS_RECEIVED:
        if (_.get(action, 'cltstatus.map') !== undefined) {
          return {
            scannedPackagesSize: action?.cltstatus?.map?.scannedPackages?.length !== undefined ? action.cltstatus.map.scannedPackages.length : 0,
            packageLoadedSize: action?.cltstatus?.map?.loadedPackages?.length !== undefined ? action.cltstatus.map.loadedPackages.length : 0,
            stackedPackagesSize: action?.cltstatus?.map?.stackedPackages?.length !== undefined ? action.cltstatus.map.stackedPackages.length : 0,
            materialNeededSize: action?.cltstatus?.map?.rawMaterialNeeded?.length !== undefined ? action.cltstatus.map.rawMaterialNeeded.length : 0,
            sortingQueueSize: action?.cltstatus?.map?.packageMakingQueue?.length !== undefined ? action.cltstatus.map.packageMakingQueue.length : 0,
            layersQueueSize: action?.cltstatus?.map?.sortedJoinedDimensions?.length !== undefined ? action.cltstatus.map.sortedJoinedDimensions.length : 0,
            ordersQueueSize: action?.cltstatus?.map?.orderQueue?.length !== undefined ? action.cltstatus.map.orderQueue.length : 0,
            cltLayersQueueSize: action?.cltstatus?.map?.cltJoinedDimensions?.length !== undefined ? action.cltstatus.map.cltJoinedDimensions.length : 0,
            fjOrdersQueueSize: action?.cltstatus?.map?.fjOrderQueue?.length !== undefined ? action.cltstatus.map.fjOrderQueue.length : 0,
            machineStats: action?.cltstatus?.map?.dailyStats != undefined ? action?.cltstatus?.map?.dailyStats : [],
            errorsListSize: action?.cltstatus?.map?.errors?.length !== undefined ? action.cltstatus.map.errors.length : 0,
            errorsList: action?.cltstatus?.map?.errors !== undefined ? action.cltstatus.map.errors : []
          };
        } else {
          return state;
        }
    }
  });

export default headerReducer;
