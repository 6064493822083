/*
 *
 * Websocket reducer
 *
 */

import { DEFAULT_ACTION, CLT_STATUS_RECEIVED, SCANNER_STATUS_RECEIVED } from './constants';
import ReconnectingWebSocket from './reconnecting-websocket';

export const initialState = {
  cltStatusReceivedTimestamp: 0,
  scannerStatusReceivedTimestamp: 0,
  websocketObject: new ReconnectingWebSocket(`ws://${window.location.host}/websocket/web/`),
  cltstatus: ''
};

function websocketReducer(state = initialState, action) {
  switch (action.type) {
    case CLT_STATUS_RECEIVED:
      return {
        ...state,
        cltStatusReceivedTimestamp: new Date(),
        cltstatus: action.cltstatus
      };
    case SCANNER_STATUS_RECEIVED:
      return {
        ...state,
        scannerStatusReceivedTimestamp: new Date()
      };
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default websocketReducer;
