export const SAVE_ORDER = 'app/EditView/SAVE_ORDER';
export const ORDER_SAVED = 'app/EditView/ORDER_SAVED';
export const LOAD_ORDER = 'app/EditView/LOAD_ORDER';
export const LOAD_PLATES = 'app/EditView/LOAD_PLATES';
export const CLEAR_RESPONSE = 'app/EditView/CLEAR_RESPONSE';
export const RESPONSE_CLEARED = 'app/EditView/RESPONSE_CLEARED';
export const ORDER_LOADED = 'app/EditView/ORDER_LOADED';
export const LAYER_SORT = 'app/EditView/LAYER_SORT';
export const SAVE_RECIPE = 'app/EditView/SAVE_RECIPE';
export const LOAD_RECIPES = 'app/EditView/LOAD_RECIPES';
export const RECIPES_LOADED = 'app/EditView/RECIPES_LOADED';
export const SELECT_RECIPE = 'app/EditView/SELECT_RECIPE';
export const CONFIG_LOADED = 'app/EditView/CONFIG_LOADED';
export const LOAD_MODEL = 'app/EditView/LOAD_MODEL';
export const MODEL_LOADED = 'app/EditView/MODEL_LOADED';
export const SAVE_MODEL = 'app/EditView/SAVE_MODEL';
export const MODEL_SAVED = 'app/EditView/MODEL_SAVED';
export const ORDER_NUMEBR_GENERATED = 'app/EditView/ORDER_NUMEBR_GENERATED';
