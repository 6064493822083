/**
 *
 * StackingBox
 *
 */

import Wrapper from './Wrapper';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import LineMoreContainer from '../LineMoreContainer';
import InlineWrapper from '../../../reusable/InlineWrapper';
import _ from 'lodash';

const BoxField = styled.div`
  padding: 3px;
  font-size: smaller;
`;

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const LittleLamellaBox = styled.div`
  border: 1px solid #414042;
  box-shadow: 1px 1px 1px #777;
  height: 80%;
  width: 80%;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;

  &.empty {
    background-color: #e0e0e0;
  }

  &.full {
    background-color: #f7c315;
  }
`;

export function StackingBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  // const history = useHistory();

  const getPackage = () => {
    const boards = [];
    if (_.get(props, 'status.stackingPackage', null) != null) {
      const numberOfBoards = props.status.stackingPackage.numberOfLamellas;
      const numberOfBoardsPerLayer = props.status.stackingPackage.piecesPerLayer;
      const currentNumberOfBoards = props.status.stackingPackage.numberOfLamellasStacked;
      for (let i = 0; i < numberOfBoards; i++) {
        boards.push(
          <LittleLamellaBox
            key={i}
            style={{
              gridColumn: (i % numberOfBoardsPerLayer) + 1 + '/' + ((i % numberOfBoardsPerLayer) + 2),
              gridRow: parseInt(i / numberOfBoardsPerLayer) + 1 + '/' + (parseInt(i / numberOfBoardsPerLayer) + 2)
            }}
            className={`${i >= currentNumberOfBoards ? 'empty' : 'full'}`}
          />
        );
      }
    }

    return boards;
  };

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> A?? | Stacking</SubInfo>
      </Paper>
      <br />
      <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <span style={{ float: 'left' }}>Current stacking ID:</span>
          <div style={{ float: 'left', marginLeft: '3px' }}>
            <Paper elevation={1}>
              <BoxField>
                <b>{String(_.get(props, 'status.stackingPackage.id', 0))}</b>
              </BoxField>
            </Paper>
          </div>
        </div>
      </div>
      <div style={{ display: 'inline-block' }}>
        <span style={{ float: 'left' }}>Stacked no.</span>
        <div style={{ float: 'left', marginLeft: '3px' }}>
          <Paper elevation={1}>
            <BoxField>
              <b>
                {String(_.get(props, 'status.stackingPackage.numberOfLamellasStacked', 0))}/{String(_.get(props, 'status.currentSetNumberOfBoards', 0))}
              </b>
            </BoxField>
          </Paper>
        </div>
      </div>
      <InlineWrapper style={{ display: 'grid', height: '80%', width: '100%', overflow: 'hidden' }}>{getPackage()}</InlineWrapper>
      <LineMoreContainer></LineMoreContainer>
    </Wrapper>
  );
}

StackingBox.propTypes = {};

export default StackingBox;
