import { lazy } from 'react';

const EtageLagerBox = lazy(() => import('./EtageLagerBox'));
const StackingBox = lazy(() => import('./StackingBox'));
const RawInfeedLoadingBox = lazy(() => import('./RawInfeedLoadingBox'));
// const MicrotecBox = lazy(() => import('./MicrotecBox'));
const AccumulatorBox = lazy(() => import('./AccumulatorBox'));
const SplanBox = lazy(() => import('./SplanZpressBox'));

export default {
  EtageLagerBox,
  StackingBox,
  RawInfeedLoadingBox,
  AccumulatorBox,
  SplanBox
};
