import styled from 'styled-components';

export default styled.div`
  .box {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .layerBtn {
    padding: 12px;
    display: inline-block;
    height: 3.5em;
    width: 95%;
    font-size: 15px;
    text-align: left;
    text-decoration: underline;
    font-weight: bold;
    border: 2.5px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: 3px -3px rgb(151, 151, 151), 4px -4px rgba(136, 136, 136, 0.842), 5px -5px rgba(77, 77, 77, 0.89);
    margin-top: 7px;

    background-color: white;
    border-color: rgba(146, 146, 146, 0.459);
  }
  .hord {
    position: fixed;
    display: inline-block;
    opacity: 1;
    background-color: white;
    width: 200px;
    border-radius: 5px;
    text-align: left;
    font-size: 19px;
    border: 1px solid transparent;
    font-family: 'Montserrat';
    font-weight: bold;
    padding-bottom: 10px;
  }
  .genv {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gena {
    margin-top: 20px;
    padding-left: 20px;
    max-height: 60vh;
    overflow-y: auto;
  }
  .accord {
    margin-top: 20px;
    max-height: 60vh;
    overflow-y: auto;
  }
`;
