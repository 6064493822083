/**
 *
 * LineMoreContainer
 *
 */

import Wrapper from './Wrapper';
import PropTypes from 'prop-types';

function LineMoreContainer(props) {
  // eslint-disable-line react/prefer-stateless-function
  return <Wrapper>{props.children}</Wrapper>;
}

LineMoreContainer.propTypes = {
  children: PropTypes.array
};

export default LineMoreContainer;
