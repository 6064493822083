import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ModalaManager state domain
 */
const selectModalManagerDomain = (state) => state.modalmanager || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ModalManager
 */

const makeSelectModalManager = () => createSelector(selectModalManagerDomain, (substate) => substate);

export default makeSelectModalManager;
export { selectModalManagerDomain };
