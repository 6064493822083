import styled from 'styled-components';

const InlineWrapper = styled.div`
  > div {
    display: inline-block;
    text-align: center;
  }
`;

export default InlineWrapper;
