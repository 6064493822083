import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import { fetchArchiveStatus } from '../Websocket/actions';
import _ from 'lodash';

import { setArchiveModeDate, setArchiveMode } from '../App/actions';
import { useDispatch, connect } from 'react-redux';
import { makeSelectArchiveModeDate } from '../App/selectors';
import { createStructuredSelector } from 'reselect';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useHasPermissions } from '../../utils/hooks/useHasPermissions';
import { PERMISSIONS } from '../../utils/permissions_roles/roles';
import { Warning } from '@material-ui/icons';
import { FileDownload } from '@mui/icons-material';
import { sendToWs } from '../Websocket/actions';
import { Buffer } from 'buffer';
import base64 from 'base-64';
import pako from 'pako';
import globalMessages from '../../globalMessages';
import FormattedMessage from '../../components/TranslationWrapper';

function ArchiveSlider(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [inputDate, setInputDate] = React.useState(null);
  const [intervalStep, setIntervalStep] = React.useState(null);
  const CAN_LOAD_LINESTATUS = useHasPermissions(PERMISSIONS.CAN_LOAD_LINESTATUS);

  const setData = (data) => {
    const maxV = _.get(data, 'size', 0);
    setValue(maxV > 0 ? maxV - 1 : 0);
    setMax(maxV > 0 ? maxV - 1 : 0);
    setMin(0);
    setMinDate(Date.parse(_.get(data, 'first', null)));
    setMaxDate(Date.parse(_.get(data, 'last', null)));
  };

  const arrowClick = (diff) => {
    if (diff < 0) {
      //console.log('arrowClick "-" inputDate: ', inputDate, ' -> ', Date.parse(inputDate));
      const df = Date.parse(inputDate) - intervalStep;
      //console.log('####: ', getFormattedCurrentTime(new Date(df)));
      setInputDate(getFormattedCurrentTime(new Date(df)));
    } else {
      //console.log('arrowClick "+" inputDate: ', inputDate);
      const df = Date.parse(inputDate) + intervalStep;
      setInputDate(getFormattedCurrentTime(new Date(df)));
    }
  };

  const getDataRange = async () => {
    try {
      const { data } = await axios.get(`${window.location.origin}/api/v1/productionline/linestatus/get_size/`);
      setData(data);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };
  useEffect(() => {
    if (props.archive) {
      getDataRange();
      if (!props.archiveModeDate) {
        dispatch(setArchiveModeDate(getFormattedCurrentTime(new Date())));
      }
    }
  }, [props.archive]);

  useEffect(() => {
    if (inputDate != null) {
      dispatch(setArchiveModeDate(inputDate));
    }
  }, [inputDate]);

  useEffect(() => {
    setInputDate(props.archiveModeDate);
    props.dispatch(fetchArchiveStatus(Date.parse(props.archiveModeDate)));
  }, [props.archiveModeDate]);

  useEffect(() => {
    setIntervalStep(_.get(props, 'intervalStep', 60000));
  }, [props.intervalStep]);

  function addLeadingZero(toFormat) {
    let formatted = '';
    // console.log('toFormat: ', toFormat, " length: ",toFormat.length);
    formatted = toFormat.length == 1 ? ('0' + toFormat).slice(-2) : toFormat;
    return formatted;
  }

  const getFormattedCurrentTime = (timeToConvert) => {
    let today, dd, mm, yyyy, h, m, s;
    today = timeToConvert;
    dd = today.getDate();
    dd = addLeadingZero(String(dd));
    mm = today.getMonth() + 1;
    mm = addLeadingZero(String(mm));
    h = today.getHours();
    h = addLeadingZero(String(h));
    m = today.getMinutes();
    m = addLeadingZero(String(m));
    s = today.getSeconds();
    s = addLeadingZero(String(s));
    yyyy = today.getFullYear();

    const datetime = yyyy + '-' + mm + '-' + dd + 'T' + h + ':' + m + ':' + s;
    //console.log('datetime: ', datetime);
    return datetime;
  };

  const selectDate = (e) => {
    setInputDate(e.target.value);
    const df = Date.parse(inputDate);
  };

  const inputRef = useRef();
  const handleInputClick = () => {
    inputRef.current.showPicker();
  };

  const handleLoadStatusClick = () => {
    setConfirmationOpen(true);
  };

  const handleConfirm = () => {
    handleClose();
    dispatch(sendToWs(Date.parse(props.archiveModeDate), 'load-archive-status'));
    dispatch(setArchiveMode(false));
    dispatch(setArchiveModeDate(''));
  };

  const handleClose = () => {
    setConfirmationOpen(false);
  };

  const handleDownloadStatusClick = async () => {
    try {
      const { data } = await axios.post(`/api/v1/productionline/linestatus/get_data/`, {
        statusTime: Date.parse(props.archiveModeDate)
      });

      if (data.data) {
        const base64Data = data.data;
        const compressedData = base64.decode(base64Data);

        try {
          const decompressedData = pako.ungzip(Buffer.from(compressedData, 'binary'));
          const blob = new Blob([decompressedData], { type: 'application/octet-stream' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'lineStatus.json';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error('Error decompressing data:', err);
        }
      }
    } catch (error) {
      console.error('Error downloading status:', error);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div>
        <FormattedMessage {...globalMessages.selectLinestatusTime} />
      </div>
      <Stack spacing={0} direction="column" sx={{ mb: 1 }} alignItems="center">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NavigateBeforeIcon
            onClick={() => {
              arrowClick(-1);
            }}
          />
          <div>
            <input
              onClick={handleInputClick}
              ref={inputRef}
              style={{ background: '', width: '95%', fontSize: 'smaller', cursor: 'pointer' }}
              value={inputDate}
              onChange={selectDate}
              type="datetime-local"
              min={minDate != null ? getFormattedCurrentTime(new Date(minDate)) : getFormattedCurrentTime(new Date())}
              max={maxDate != null ? getFormattedCurrentTime(new Date(maxDate)) : getFormattedCurrentTime(new Date())}></input>
          </div>
          {/* <Slider min={min} max={max} aria-label="Volume" value={value} onChange={handleChange} onChangeCommitted={handleChangeCommited} /> */}
          <NavigateNextIcon
            onClick={() => {
              arrowClick(1);
            }}
          />
        </div>
        {CAN_LOAD_LINESTATUS && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px', width: '90%' }}>
            <Button sx={{ mb: 1 }} startIcon={<Warning />} variant="contained" size="small" color="error" fullWidth onClick={handleLoadStatusClick}>
              <FormattedMessage {...globalMessages.loadStatus} />
            </Button>
            <Button startIcon={<FileDownload />} variant="contained" size="small" color="info" fullWidth onClick={handleDownloadStatusClick}>
              <FormattedMessage {...globalMessages.downloadStatus} />
            </Button>
          </div>
        )}
      </Stack>
      <Dialog open={confirmationOpen} onClose={handleClose}>
        <DialogTitle>
          <b>
            <FormattedMessage {...globalMessages.confirmLoadStatus} />
          </b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage {...globalMessages.loadStatusQuestion} />
            <br />
            <strong>{props.archiveModeDate}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" variant="contained">
            <FormattedMessage {...globalMessages.cancel} />
          </Button>
          <Button onClick={handleConfirm} color="success" autoFocus variant="contained">
            <FormattedMessage {...globalMessages.confirm} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  archiveModeDate: makeSelectArchiveModeDate()
});

export default connect(mapStateToProps)(ArchiveSlider);
