import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: white;
  //border-bottom: 1px solid #848284;
  border-radius: 3px 3px 3px 3px;
  height: 28px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  &.storage {
    background-color: #f1f1f1;
  }

  &.isFooter {
    position: absolute;
    bottom: 0px;
    border-bottom: 0px;
    border-top: 1px solid #ddd;
    border-top-right-radius: 3px,
    border-top-left-radius: 3px,
    border-bottom-left-radius: 0px,
    border-bottom-right-radius: 0px,
  }
`;

export const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;
