/* List of projects using the same origin App - Register/Add new project config here */

import weihag from './weihag-editview-config';
import ante from './ante-editview-config';
import jv20 from './jv20-editview-config';
import arolla from './arolla-editview-config';
import smartlam from './smartlam-editview-config';
import sodra from './sodra-editview-config';
import peetri from './peetri-editview-config';
import schilliger from './schilliger-editview-config';
import bruno from './bruno-editview-config';
import sepa from './sepa-editview-config';
import mm2 from './mm2-editview-config';
import arboreal from './arboreal-editview-config';
import kafe from './kafe-editview-config';
import mosser from './mosser-editview-config';
import eztiles from './eztiles-editview-config';
import schneider from './schneider-editview-config';
import solis from './solis-editview-config';
import baseProject from './baseProject-editview-config';

const editviewList = {
  weihag,
  ante,
  jv20,
  arolla,
  smartlam,
  sodra,
  peetri,
  schilliger,
  bruno,
  sepa,
  mm2,
  arboreal,
  kafe,
  mosser,
  eztiles,
  schneider,
  solis,
  baseProject
};

export default editviewList;
