import styled from 'styled-components';

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px;
  border-radius: 4px 4px 4px 4px;
  background-color: #fff;
  border: none;
  width: 100%;
  height: 42vh;
  margin-top: 10px;

  @media only screen and (max-width: 795px) {
    height: 400px;
  }
`;

export default Wrapper;
