import 'whatwg-fetch';
import auth from './auth';
import { saveAs } from 'file-saver';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options).then(checkStatus).then(parseJSON);
}

function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  let retval;
  if (uri.match(re)) {
    retval = uri.replace(re, `$1${key}=${value}$2`);
  } else {
    retval = `${uri}${separator}${key}=${value}`;
  }
  return retval;
}

export function fetchList(apiUrl, page, filters, additionalParameters) {
  // console.log('fetchList');
  let url = updateQueryStringParameter(apiUrl, 'page', page);
  Object.keys(filters).map((val) => {
    url = updateQueryStringParameter(url, val, filters[val]);
    return val;
  });
  if (additionalParameters !== undefined) {
    Object.keys(additionalParameters).map((val) => {
      url = updateQueryStringParameter(url, val, additionalParameters[val]);
      return val;
    });
  }
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    let promise;
    if (response.status === 200 || response.status === 304) {
      promise = new Promise((resolve) => {
        response.json().then((json) => {
          resolve({ ...json, success: true });
        });
      });
    } else if (response.status == 401) {
      auth.logout();
      promise = new Promise((resolve) => {
        response.json().then((json) => {
          resolve({
            title: 'Fetch response error: ' + response.status,
            message: 'Message: ' + json?.messages?.[0]?.message,
            success: false
          });
        });
      });
    } else {
      promise = new Promise((resolve) => {
        response.json().then((json) => {
          resolve({
            success: false,
            title: 'Fetch response error: ' + response.status,
            message: 'Message: ' + json?.messages?.[0]?.message
          });
        });
      });
    }
    return promise;
  });
}

export function fetchItem(apiUrl) {
  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${auth.getToken()}`
    }
  }).then((response) => {
    let promise;
    if (response.status === 200) {
      promise = new Promise((resolve) => {
        response.json().then((json) => {
          resolve({ ...json, success: true });
        });
      });
    } else {
      promise = new Promise((resolve) => {
        response.json().then((json) => {
          resolve({
            success: false,
            title: 'Fetch response error: ' + response.status,
            message: 'Message: ' + json.messages[0].message
          });
        });
      });
    }
    return promise;
  });
}

export function downloadItem(apiUrl, headers) {
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(headers)
  })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, 'statistics.csv'))
    .then((response) => {
      let promise;
      if (response.status === 200) {
        promise = new Promise((resolve) => {
          resolve({ success: true });
        });
      } else {
        promise = new Promise((resolve) => {
          response.json().then((json) => {
            resolve({
              success: false,
              title: 'Fetch response error: ' + response.status,
              message: 'Message: ' + json.messages[0].message
            });
          });
        });
      }

      return promise;
    });
}

export function saveItem(apiUrl, type, toServer) {
  return fetch(apiUrl, {
    method: type,
    headers: {
      Authorization: 'Bearer ' + auth.getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(toServer)
  }).then((response) => {
    const promise = new Promise((resolve) => {
      if (response.status === 204) {
        resolve({ success: true });
        return;
      }

      response.json().then((json) => {
        const newJson = Object.assign({}, json);

        if (response.status === 200 || response.status === 201) {
          newJson.success = true;
        } else {
          console.log('JSON: ', json);
          newJson.success = false;
          newJson.title = 'Response error: ' + response.status;
          newJson.message = 'Message: ' + json;
        }
        resolve(newJson);
      });
    });
    return promise;
  });
}

export function sendToWS() {
  return fetch(this.apiUrl, {
    method: this.type,
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(this.toServer)
  }).then((response) => {
    const promise = new Promise((resolve) => {
      if (response.status === 204) {
        resolve({ success: true });
        return;
      }

      response.json().then((json) => {
        const newJson = Object.assign({}, json);

        if (response.status === 200 || response.status === 201) {
          newJson.success = true;
        } else {
          newJson.success = false;
          newJson.title = 'Response error: ' + response.status;
          newJson.message = 'Message: ' + json.messages[0].message;
        }
        resolve(newJson);
      });
    });
    return promise;
  });
}
