import styled from 'styled-components';

const Wrapper = styled.div`
  height: 33%;
  position: relative;

  > div:nth-of-type(1) {
    position: absolute;
    bottom: 33%;
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: #414042;
    position: absolute;
    bottom: 20%;
    left: 0;
  }

  &.full:after {
    background-color: #f7c315;
  }

  &.full:before {
    background-color: #f7c315;
  }

  &.emptying {
  }

  .orderEtage {
    position: absolute;
    bottom: -40px;
    left: 5px;
    font-size: 13px;
    text-transform: uppercase;
  }

  .etageQuality {
    position: absolute;
    bottom: -15px;
    right: 5px;
    font-size: 13px;
    text-transform: uppercase;
  }

  .halterSalter {
    width: 10px;
    height: 10px;
    float: left;
    margin-top: 5px;
    margin-right: 4px;
    border-radius: 10px;
  }
`;

export default Wrapper;
