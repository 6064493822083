import { BROADCAST_MESSAGE, SHOW_MESSAGE_TO_CLIENT, CLOSE_MESSAGE_MODAL } from './constants';

export const initialState = {
  messages: [],
  showMessage: false,
  currentMessage: {},
  modalOpen: false, // New state
  lastUUID: null
};

const WebsocektMessageModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case BROADCAST_MESSAGE:
      return {
        ...state,
        lastUUID: action.payload.uuid,
        messages: [...state.messages, action.payload]
      };

    case SHOW_MESSAGE_TO_CLIENT:
      if (action.payload.uuid && action.payload.uuid == state.lastUUID) {
        return state;
      }
      return { ...state, modalOpen: true, currentMessage: action.payload.data };

    case CLOSE_MESSAGE_MODAL:
      return { ...state, modalOpen: false };
  }
  return state;
};

export default WebsocektMessageModalReducer;
