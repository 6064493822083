import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #f7c315;
  border: 1px solid #414042;
  height: 10px;
  width: 11%;
  margin-right: 2%;
  margin-left: 2%;
  vertical-align: top;
  box-shadow: 1px 1px 1px #777;

  &.empty {
    background-color: #e0e0e0;
  }
`;

export default Wrapper;
