/**
 *
 * PressBox
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Wrapper, SubInfo, BoxField, PackageContainer } from './Wrapper';
// import styled from 'styled-components';
import './test.css';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import Button from '../../../Button';
import { useHistory } from 'react-router-dom';
import { MoreIcon, PackageIcon } from '../../../reusable/Icons';
// import StatusItem from '../../../StatusItem';
import { addOpacityToHEXColor } from '../../../../utils/helpers';
import { sendToWs } from '../../../../containers/Websocket/actions';

export function Press(props) {
  // eslint-disable-line react/prefer-stateless-function
  const history = useHistory();

  function getDataForPress(status, order, color) {
    if (status != null && status[order] !== null) {
      return (
        <PackageContainer>
          <span style={{ margin: 'auto', background: 'white', padding: '3px', width: '100%' }}>
            <span style={{ float: 'left', width: '70%' }}>
              <Paper square>
                <BoxField>
                  <b> ID: </b>
                  {status[order].id} | <PackageIcon style={{ width: '15px', height: '20px', color }} />
                  {status[order].layers.length}
                </BoxField>
              </Paper>
            </span>
            <span style={{ float: 'right', width: '30%' }}>
              <Button
                className="button"
                onClick={() => {
                  props.dispatch(sendToWs(order, 'delete-press-order'));
                }}>
                Clear
              </Button>
            </span>
            <span style={{ float: 'left', width: '100%' }}>
              <Paper square>
                <BoxField>
                  <b>
                    {status[order].lamellaThickness} x {status[order].lamellaWidth} x {status[order].lamellaLength} mm{' '}
                  </b>{' '}
                </BoxField>
              </Paper>
            </span>
            <span style={{ float: 'left', width: '100%', height: '30%', background: color }}>
              <BoxField> {status[order].status}</BoxField>
            </span>
          </span>
        </PackageContainer>
      );
    }
    return <PackageContainer />;
  }

  /* order1 = order on press, order2 = order on table */
  let colorOrder1 = 'white';
  let colorOrder2 = 'white';
  try {
    colorOrder1 = addOpacityToHEXColor(_.get(props, 'status.order1.orderColor', '#FFF'), 1);
    colorOrder2 = addOpacityToHEXColor(_.get(props, 'status.order2.orderColor', '#FFF'), 1);
  } catch (e) {
    console.log('Error line/Press', e);
  }

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> B11 | Press </SubInfo>
      </Paper>
      <br />
      <div className="float-container">
        <div className="float-child" style={{ background: colorOrder1 }}>
          {getDataForPress(props.status, 'order1', colorOrder1)}
        </div>
        <div className="float-child" style={{ background: colorOrder2 }}>
          {getDataForPress(props.status, 'order2', colorOrder2)}
        </div>
      </div>
      <div style={{ position: 'absolute', width: '100%', bottom: '0px' }}>
        <Button
          onClick={() =>
            history.push({
              pathname: '/press',
              props: props.status
            })
          }>
          <MoreIcon style={{ width: '2rem', height: '2rem' }} />
          {/* Detailed view */}
        </Button>
      </div>
    </Wrapper>
  );
}

Press.propTypes = {
  dispatch: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapDispatchToProps);

export default compose(withConnect, React.memo)(Press);
