import LineBox from './LineBox';
import LineBoxHeader from './LineBoxHeader';
import AccumulatorBox from './AccumulatorBox';
import EtageLagerBox from './EtageLagerBox';
import LagerLongBox from './LagerLongBox';
import LagerShortBox from './LagerShortBox';
import StackingBox from './StackingBox';
import RawInfeedLoadingBox from './RawInfeedLoadingBox';
import SuperplanBox from './SuperplanBox';
import MicrotecBox from './MicrotecBox';
import ZincBox from './ZincBox';
import PackageInfeedBox from './PackageInfeedBox';
import Press from './Press';
import ProductStackerBox from './ProductStackerBox';
import PressBox from './PressBox';
import LineStatusBox from './LineStatusBox';
import ZincChart from './ZincChart';
import SplanZpressBox from './SplanZpressBox';
import GridBox from './GridBox';
import SwitchBox from './SwitchBox';

export default {
  LineBox,
  LineBoxHeader,
  AccumulatorBox,
  EtageLagerBox,
  LagerLongBox,
  LagerShortBox,
  StackingBox,
  RawInfeedLoadingBox,
  SuperplanBox,
  MicrotecBox,
  ZincBox,
  PackageInfeedBox,
  Press,
  ProductStackerBox,
  PressBox,
  LineStatusBox,
  ZincChart,
  SplanZpressBox,
  GridBox,
  SwitchBox
};
