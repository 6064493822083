/**
 *
 * LineBoxHeader
 *
 */

import PropTypes from 'prop-types';
import { Wrapper } from './Wrapper';
import FormattedMessage from '../../../TranslationWrapper';
import BoxLights from './BoxLights';
import BoxLight from './BoxLight';
import BoxTitle from './BoxTitle';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { getStripeCss } from '../../../../utils/helpers';
import { useState, useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';

const LineBoxHeader = (props) => {
  // eslint-disable-line react/prefer-stateless-function
  // const history = useHistory();
  // console.log('HEADER', props);
  /*   console.log('USER SETTINGS: ', props.userSettings);
  console.log('PROPS: ', props); */

  const [title, setTitle] = useState('');
  const [crown, setCrown] = useState(false);

  let textColor = 'black';
  props.color !== undefined && props.hasColor === 'ZincBox' && String(props.color) !== 'white' ? (textColor = 'white') : (textColor = 'black');
  let backColor = '';
  let background = '';

  function getFullObject() {
    let prefix = _.get(props, 'configData.prefix', '');
    if (prefix.length > 0) {
      return prefix + '-' + _.get(props, 'configData.orderObject');
    } else {
      return _.get(props, 'configData.orderObject');
    }
  }
  props.hasBackColor === 'ZincBox' ? (backColor = props.color) : (backColor = '');
  if (_.get(props, 'configData.orderObject', null) != null) {
    const color = _.get(props, 'prodstatus.map.' + getFullObject() + '.orderColor', null);
    background = getStripeCss(_.get(props, 'prodstatus.map.' + getFullObject(), null));
    if (color != null) backColor = color;
  }

  useEffect(() => {
    // Check for title in localStorage
    const userViewData = JSON.parse(localStorage.getItem('userView')) || [];
    const config = userViewData.find(({ comp }) => comp == props.component);
    if (config && config?.value?.length > 0) {
      setTitle(config.value);
    } else {
      setTitle('');
    }
  }, [props?.userView, props?.configData]);

  function getDoorCount() {
    let doors = 0;
    const areas = [];
    for (const area in props.areas) {
      const doorsOpened = _.get(props, 'prodstatus.map.areasHashMap[' + props.areas[area] + '].doors', 0);
      if (doorsOpened > 0) areas.push(area);
      doors += _.get(props, 'prodstatus.map.areasHashMap[' + props.areas[area] + '].doors', 0);
    }
    return { totalDoors: doors, areas };
  }

  function getError() {
    const errors = _.get(props, 'prodstatus.map.errors', []);
    const foundTypes = {
      warning: false,
      error: false
    };

    if (props.errorAreas) {
      for (const errorArea of props.errorAreas) {
        for (const error of errors) {
          if (error.area === errorArea && !foundTypes[error.type]) {
            foundTypes[error.type] = true;
          }
        }
      }
    }

    const matchedIcons = [];

    if (foundTypes.warning) {
      matchedIcons.push(<WarningIcon key="warning" style={{ color: '#ffcc00', fontSize: '2.2em' }} />);
    }
    if (foundTypes.error) {
      matchedIcons.push(<WarningIcon key="error" style={{ color: '#ff9966', fontSize: '2.2em' }} />);
    }

    return matchedIcons;
  }

  const getDoors = () => {
    if (props?.areas) {
      const doorCountO = getDoorCount(props.areas);
      const doorCount = doorCountO.totalDoors;

      return (
        <div>
          <OverlayTrigger key={1} overlay={<Tooltip>{JSON.stringify(doorCountO.areas)}</Tooltip>} placement="top" delayShow={300} delayHide={150}>
            <MeetingRoomIcon sx={{ color: doorCount > 0 ? 'red' : 'black' }} />
          </OverlayTrigger>
          <span style={{ color: doorCount > 0 ? 'red' : 'black' }}>{doorCount}</span>
        </div>
      );
    } else {
      return <span />;
    }
  };

  const getBoxLights = () => {
    return props?.areas?.map((item, index) => {
      const area = _.get(props, 'prodstatus.map.areasHashMap[' + item + ']', null);
      return (
        <span>
          {area && (
            <OverlayTrigger key={index} overlay={<Tooltip id={`tooltip-${index}`}>{item}</Tooltip>} placement="top" delayShow={300} delayHide={150}>
              <BoxLight className={`_${index.toString()} ${area.automatic ? 'automatic' : ''} ${area.area ? 'area' : ''}`} key={index} />
            </OverlayTrigger>
          )}
        </span>
      );
    });
  };

  const getBoxLights2 = () => {
    return props.lights2.map((item, index) => (
      <OverlayTrigger
        key={index}
        overlay={<Tooltip id={`tooltip2-${index}`}>{typeof props.properties !== 'undefined' && props.properties2[index]}</Tooltip>}
        placement="top"
        delayShow={300}
        delayHide={150}>
        <BoxLight className={`_${index.toString()} ${item} ${props.noLeer ? 'noLeer' : ''}`} key={index} />
      </OverlayTrigger>
    ));
  };

  return (
    <Wrapper className={`${props.type} ${props.footer ? 'isFooter' : ''}`}>
      <Paper
        style={{
          //backgroundColor: backColor,
          opacity: 0.8,
          color: textColor,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
        }}>
        {getBoxLights() && <BoxLights>{getBoxLights()}</BoxLights>}
        {getDoors() && <BoxLights>{getDoors()}</BoxLights>}

        <BoxTitle>
          <span style={{ marginRight: '3px' }}>{props.bereich}</span>
          <FormattedMessage {...props.title} identifier={props.identifier} /> <b>{title}</b>
          <span style={{ marginLeft: '3px' }}>{props.bereich2}</span>
          <span>{getError()}</span>
        </BoxTitle>
        {crown && (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="gold" d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11H5m14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14v1Z" />
          </svg>
        )}
        {props.lights2 && <BoxLights style={{ float: 'right', borderRight: '0px', marginRight: '0px' }}>{getBoxLights2()}</BoxLights>}
        <div
          style={{
            display: 'inline-block',
            float: 'right',
            lineHeight: '28px',
            marginRight: '5px'
          }}>
          {props.children}
        </div>
      </Paper>
    </Wrapper>
  );
};

LineBoxHeader.propTypes = {
  lights: PropTypes.array,
  lights2: PropTypes.array,
  title: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.any,
  bereich: PropTypes.string,
  bereich2: PropTypes.string,
  properties: PropTypes.any
};

export default LineBoxHeader;
