import styled from 'styled-components';

const Wrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-left: 0px;

  span {
    font-weight: 400;
    margin-left: 4px;
  }
`;

export default Wrapper;
