import { call, put, takeLatest, all } from 'redux-saga/effects';
import history from '../../utils/history';
import { SENDING_REQUEST, LOGIN_REQUEST, SET_AUTH, REQUEST_ERROR, LOGIN_SUCCESS, LOGOUT_REQUEST, LOGOUT_SUCCESS, AUTH_REQUEST_RESOLVED } from './constants';
import auth from '../../utils/auth';
import { changeForm, setAuthState } from './actions';
import { packagesLoaded, apiCall, backendCallFinished, showLoader, hideLoader, clearData, apiError } from '../TableView/actions';
import { API_CALL, BACKEND_CALL, CONTROL_CALL, URL_ACTION } from '../TableView/constants';

import { Order } from '../../utils/models';
import { fetchList, saveItem, fetchItem } from '../../utils/request';
import { sendToWs } from '../Websocket/actions';
import { addModal } from '../ModalManager/actions';

export function* authorize({ username, password }) {
  yield put({ type: SENDING_REQUEST, sending: true });

  try {
    const response = yield auth.login(username, password).then(
      function (response) {
        return response;
      },
      function (error) {
        return undefined;
      }
    );

    yield put({ type: AUTH_REQUEST_RESOLVED, data: response });
    yield put({ type: SET_AUTH, user: { username: response?.token?.user, privilege: response?.token?.privilege } });
  } catch (error) {
    yield put({ type: REQUEST_ERROR, error: error.message });
    yield put({ type: SET_AUTH, user: { username: 'logout', privilege: -1 } });
  } finally {
    yield put({ type: SENDING_REQUEST, sending: false });
  }
}

export function* authResolved(action) {
  // console.log('action.data?.response:', action.data?.response);
  if (action.data?.response?.status != undefined && action.data?.response?.status == 200) {
    // NEEDS TO BE FIXED
    yield put({ type: LOGIN_SUCCESS }); // User is logged in (authorized)
    const defaultPage = action.data?.token?.defaultPage || '/dashboard';
    history.push(defaultPage);
  } else {
    yield put(setAuthState(false));
    yield put({ type: REQUEST_ERROR, error: 'login error' });
  }
}

export function* loginFlow(action) {
  try {
    console.log('Login saga loginFlow');
    const request = action;
    const { username, password } = request.data;
    yield call(authorize, { username, password });
  } catch (error) {}
}

export function* logoutFlow(action) {
  console.log('# logoutFlow SAGA');
  // yield put(setAuthState(false, {username: 'logout', privilege: -1}));
  yield put(setAuthState(false));
  yield put(changeForm({ username: '', password: '' }));

  // yield put(setAuthState(false));
  const logoutResp = yield call(auth.logout);
  if (logoutResp) {
    yield put({ type: LOGOUT_SUCCESS });
    history.push('/login');
  }
}

function* apiCallSaga(action) {
  yield put(showLoader(true));
  yield put(clearData());
  const pr = action;
  const q = Object.assign({}, pr.query);
  let page = 1;
  let apicall = '';
  pr?.apicall?.apicall !== undefined ? (apicall = pr.apicall.apicall) : (apicall = 'orders/all/?status=todo');

  if (typeof q.page !== 'undefined') {
    page = parseInt(q.page, 10);
  }

  if (typeof pr.query.ordering === 'undefined') {
    pr.query.ordering = pr.sort;
  }

  let additionalParameters = [];
  if (typeof pr.filterArray !== 'undefined' && typeof pr.filterArray.additionalParameters !== 'undefined') {
    additionalParameters = pr.filterArray.additionalParameters;
  }

  let items = false;
  items = yield fetchList('/api/v1/' + apicall, page, pr.query, additionalParameters);
  Object.keys(pr.query).map((val) => {
    if (val !== 'page' && val !== 'messages' && val !== 'ordering' && val !== 'editable') {
      pr.query.messages[val] = Order.fields[val].title;
    }
    return val;
  });
  if (items.success) {
    yield put(apiError(false));
    yield put(packagesLoaded(page, items, pr.query));
  } else {
    yield put(apiError(true));
    console.log('ITEMS FOR POPOVER: ', items);
    yield put(addModal('modal-infopopover', items.title, items.message));
  }

  yield put(hideLoader(false));
}

/* # MODAL SAGA */
function* backendSaga(action) {
  try {
    const a = action;
    let response;
    if (a.callType == 'GET') {
      response = yield fetchItem(a.url);
    }
    if (Array.isArray(a.items)) {
      response = yield saveItem(
        a.url,
        a.callType,
        a.items.map((item) => item)
      );
    } else if (!Array.isArray(a.items)) {
      response = yield saveItem(a.url, a.callType, a.items);
    }
    if (response.success && a.source != null) {
      yield put(backendCallFinished(a.source, response));
      /* refresh view */
      yield put(apiCall({ ordering: '-addedAt' }, 'id', { apicall: a.source }));
    }
  } catch (e) {
    console.log('error', e);
  }
}

function* controlSaga(action) {
  const a = action;
  yield put(sendToWs(a.items, a.identifier));
}

function* urlActionSaga(action) {
  const a = action;
  const url = a.url.split('?')[0] + a.id + '/' + a.action + '/';
  yield saveItem('/api/v1/' + url, 'POST', a.data);
}

export default function* globalAppSaga() {
  yield all([
    yield takeLatest(BACKEND_CALL, backendSaga),
    yield takeLatest(CONTROL_CALL, controlSaga),
    yield takeLatest(API_CALL, apiCallSaga),
    yield takeLatest(LOGIN_REQUEST, loginFlow),
    yield takeLatest(LOGOUT_REQUEST, logoutFlow),
    yield takeLatest(URL_ACTION, urlActionSaga),
    yield takeLatest(AUTH_REQUEST_RESOLVED, authResolved)
  ]);
}
