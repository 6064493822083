import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the header state domain
 */

const selectEditControlDomain = (state) => state.editcontrol || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by OrdersQueue
 */

const makeSelectEditControl = () => createSelector(selectEditControlDomain, (substate) => substate);

export default makeSelectEditControl;
export { selectEditControlDomain };
