/*
 ** EditView sepa define fields for editview reducer
 */
import { IncomingPackage, Product, WoodSupplier } from '../../../utils/models';

const sepa_config = {
  incomingPackageFields: [
    [
      [
        // { ...IncomingPackage.fields.id},
        { ...IncomingPackage.fields.identifier },
        // { ...IncomingPackage.fields.quality},
        // { ...IncomingPackage.fields.woodType},
        { ...IncomingPackage.fields.thickness },
        { ...IncomingPackage.fields.width },
        { ...IncomingPackage.fields.lamellaLength },
        // { ...IncomingPackage.fields.lotNumber},
        { ...IncomingPackage.fields.packageNumber },
        { ...IncomingPackage.fields.piecesPerLayer }
      ]
    ]
  ],

  productFields: [
    [
      [
        // { ...Order.fields.quality },
        { ...Product.fields.identifier },
        { ...Product.fields.thickness },
        { ...Product.fields.width },
        { ...Product.fields.bottomCut },
        { ...Product.fields.sideCut },
        { ...Product.fields.strengthComb },
        { ...Product.fields.packetSize },
        { ...Product.fields.stickLayerNumbers }
      ]
    ]
  ],

  supplierFields: [[[{ ...WoodSupplier.fields.supplierIndex }, { ...WoodSupplier.fields.identifier }, { ...WoodSupplier.fields.priority }]]]
};

export default sepa_config;
