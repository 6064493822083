import modelFieldMessages from './modelFieldMessages';

class CLTPackage {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    projectNumber: {
      key: 'projectNumber',
      type: 'string',
      title: modelFieldMessages.projectNumber
    },
    projectName: {
      key: 'projectName',
      type: 'string',
      title: modelFieldMessages.projectName
    },
    lamellaLength: {
      key: 'lamellaLength',
      type: 'string',
      title: modelFieldMessages.lamellaLength,
      recalculate: true
    },
    strengthString: {
      key: 'strengthString',
      type: 'string',
      title: modelFieldMessages.strengthString,
      recalculate: true
    },
    lamellaThickness: {
      key: 'lamellaThickness',
      type: 'string',
      title: modelFieldMessages.lamellaThickness
    },
    lamellaWidth: {
      key: 'lamellaWidth',
      type: 'string',
      title: modelFieldMessages.lamellaWidth
    },
    materialCode: {
      key: 'materialCode',
      type: 'string',
      title: modelFieldMessages.materialCode
    },
    woodType: {
      key: 'woodType',
      type: 'string',
      title: modelFieldMessages.woodType,
      recalculate: true
    },
    lamellasDone: {
      key: 'lamellasDone',
      type: 'string',
      title: modelFieldMessages.lamellasDone
    },
    lamellasLeft: {
      key: 'lamellasLeft',
      type: 'string',
      title: modelFieldMessages.lamellasLeft
    },
    numOfLamellas: {
      key: 'numOfLamellas',
      type: 'string',
      title: modelFieldMessages.numOfLamellas
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality,
      recalculate: true
    },
    lamellasUsed: {
      key: 'lamellasUsed',
      type: 'string',
      title: modelFieldMessages.lamellasUsed
    },
    lamellasReserved: {
      key: 'lamellasReserved',
      type: 'string',
      title: modelFieldMessages.lamellasReserved
    },
    lamellasPackaged: {
      key: 'lamellasPackaged',
      type: 'string',
      title: modelFieldMessages.lamellasPackaged
    },
    crossMultiple: {
      key: 'crossMultiple',
      type: 'string',
      title: modelFieldMessages.crossMultiple
    },
    orientation: {
      key: 'orientation',
      type: 'string',
      title: modelFieldMessages.orientation,
      recalculate: true
    },
    rawWidth: {
      key: 'rawWidth',
      type: 'string',
      title: modelFieldMessages.rawWidth,
      recalculate: true
    },
    rawThickness: {
      key: 'rawThickness',
      type: 'string',
      title: modelFieldMessages.rawThickness,
      recalculate: true
    },
    batchId: {
      key: 'batchId',
      type: 'string',
      title: modelFieldMessages.batchId
    },
    planningNumber: {
      key: 'planningNumber',
      type: 'string',
      title: modelFieldMessages.planningNumber,
      recalculate: true
    },
    check: {
      key: 'check',
      format: {
        type: 'checkbox',
        enaCAN_EDIT_ORDERbled: ['status', 'neq', 'producing']
      }
    },
    orderColor: {
      key: 'orderColor',
      type: 'string',
      format: {
        type: 'color'
      }
    }
  };

  static actions = {
    editOrder: {
      key: 'edit',
      type: 'edit',
      identifier: 'editOrder',
      title: modelFieldMessages.edit,
      configuration: {
        model: 'cltPackage',
        url: '/api/v1/orders/cltpackages/'
      }
    },
    deleteControlOrder: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteOrder',
      modal: {
        modal: true,
        buttonText: 'deleteOrder',
        key: 'deleteOrder',
        action: 'deleteOrder',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'fj-order-to-remove-from-queue',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteCltPackage: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteCltPackage',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'deleteCltPackage',
        key: 'deleteCltPackage',
        action: 'deleteCltPackage',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'orders/cltpackages/',
          url: '/api/v1/orders/cltpackages/remove_clt_package/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editControlOrder: {
      adminRequired: false,
      key: 'editControlOrder',
      format: {
        type: 'action',
        modalTo: '/api/v1/orders/all/',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      }
    }
  };
}

export default CLTPackage;
