import modelFieldMessages from './modelFieldMessages';

class Recipe {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id,
      editable: false
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    numOfLayers: {
      key: 'numOfLayers',
      type: 'string',
      title: modelFieldMessages.numOfLayers
    },
    oversizeWidth: {
      key: 'oversizeWidth',
      type: 'string',
      title: modelFieldMessages.oversizeWidth,
      validation: ['float']
    },
    oversizeThickness: {
      key: 'oversizeThickness',
      type: 'string',
      title: modelFieldMessages.oversizeThickness
    },
    oversize: {
      key: 'oversize',
      type: 'string',
      title: modelFieldMessages.oversizeLength
    },
    sandingTop: {
      key: 'sandingTop',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.sandingTop
    },
    sandingBottom: {
      key: 'sandingBottom',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.sandingBottom
    },
    noLayers: {
      key: 'layers',
      type: 'arraylength',
      title: modelFieldMessages.layers
    },
    panelThickness: {
      key: 'panelThickness',
      type: 'string',
      title: modelFieldMessages.panelThickness,
      blank: false,
      validation: ['float', 'aboveZero']
    },
    panelWidth: {
      key: 'panelWidth',
      type: 'string',
      title: modelFieldMessages.panelWidth,
      blank: false,
      validation: ['float', 'aboveZero']
    },
    panelLength: {
      key: 'panelLength',
      type: 'string',
      title: modelFieldMessages.panelLength,
      blank: false,
      validation: ['float', 'aboveZero']
    },
    orderPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getCLTRecipeImage'
    },
    getOrderWithRecipeImage: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getOrderWithRecipeImage'
    }
  };

  static actions = {
    deleteRecipe: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteRecipe',
      modal: {
        modal: true,
        buttonText: 'deleteRecipe',
        key: 'deleteRecipe',
        action: 'deleteRecipe',
        actionData: {
          target: 'backend',
          source: 'orders/recipes/',
          url: '/api/v1/orders/recipes/remove_recipe/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editRecipe: {
      key: 'edit',
      type: 'edit',
      identifier: 'editRecipe',
      configuration: {
        model: 'recipe',
        url: '/api/v1/orders/recipes/'
      }
    }
  };
}

export default Recipe;
