/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import {
  LOAD_REPOS_SUCCESS,
  LOAD_REPOS,
  LOAD_REPOS_ERROR,
  SET_PROJECT_CONFIG,
  SET_ARCHIVE_MODE,
  SET_ARCHIVE_MODE_DATE,
  LOGOUT_REQUEST,
  CHANGE_FORM,
  REQUEST_ERROR,
  CLEAR_ERROR,
  SHOW_KEYBOARD,
  SENDING_REQUEST,
  SET_AUTH,
  SET_INPUT_KEYBOARD,
  SET_UPPERCASE_KEYBOARD,
  SET_SETTINGS
} from './constants';

// The initial state of the App
export const initialState = {
  PROJECT_NAME: '',
  config: {},
  loading: false,
  error: false,
  currentUser: false,
  archiveMode: false,
  archiveModeDate: '',
  /* Login */
  loginState: {
    formState: {
      username: '',
      password: ''
    },
    error: '',
    user: { username: 'logout', privilege: -1 },
    currentlySending: false,
    loggedIn: false,
    isAdmin: false,
    privilege: -1,
    role: '',
    id: 0
  },
  /* User settings */
  settings: [],
  /* Keyboard */
  keyboardState: {
    showKeyboard: false,
    dataset: null,
    input: null,
    uppercase: true
  }
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_REPOS:
        draft.loading = true;
        draft.error = false;
        draft.userData.repositories = false;
        draft.archiveMode = false;
        break;

      case LOAD_REPOS_SUCCESS:
        draft.userData.repositories = action.repos;
        draft.loading = false;
        draft.currentUser = action.username;
        break;

      case LOAD_REPOS_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SET_PROJECT_CONFIG:
        draft.config = action.project_config;
        draft.PROJECT_NAME = action.project_config.name;
        break;
      case SET_ARCHIVE_MODE:
        draft.archiveMode = action.archiveMode;
        break;

      case SET_ARCHIVE_MODE_DATE:
        draft.archiveModeDate = action.archiveModeDate;
        break;

      /* Login */
      case CHANGE_FORM:
        draft.loginState.formState = action.newFormState;
        break;
      case SET_AUTH:
        draft.loginState.loggedIn = action.newAuthState;
        draft.loginState.user = action.user;
        draft.loginState.isAdmin = action.newAuthState;
        draft.loginState.privilege = action?.user?.privilege;
        break;

      case SENDING_REQUEST:
        draft.loginState.currentlySending = action.sending;
        break;
      case LOGOUT_REQUEST:
        draft.loginState.loggedIn = false;
        draft.archiveMode = false;
        draft.archiveModeDate = '';
        // draft.loginState.user = {username: 'logout', privilege: -1}
        // draft.loginState.privilege = -1;
        // console.log('appReducer LOGOUT_REQUEST: ',  draft.loginState);
        break;
      case SET_SETTINGS:
        draft.settings = action.settings;
        break;
      case REQUEST_ERROR:
        console.log('appReducer REQUEST_ERROR: ', action);
        draft.loginState.error = action.error;
        break;
      case CLEAR_ERROR:
        draft.loginState.error = '';
        break;
      case SHOW_KEYBOARD:
        draft.keyboardState.showKeyboard = action.showKeyboard;
        break;
      case SET_INPUT_KEYBOARD:
        draft.keyboardState.input = action.input;
        break;
      case SET_UPPERCASE_KEYBOARD:
        draft.keyboardState.uppercase = action.uppercase;
        break;
    }
  });

export default appReducer;
