import styled from 'styled-components';

export const LineBoxWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  .animateNumber {
    color: black;
    font-size: 1.2rem;
    animation-name: number;
    animation-duration: 1s;
    transition-timing-function: ease-in-out;
  }

  @keyframes number {
    from {
      color: black;
    }
    to {
      color: #97d315;
    }
  }

  .counter {
    font-size: 1rem;
    width: 10px;
    padding: 2px;
    font-weight: bold;
  }

  .emptyDot {
    background-color: #d8dbe0;
    box-shadow: 0px 0px 4px 2px rgb(255 255 255 / 27%);
  }

  .fullDot {
    background-color: #97d315;
    box-shadow: 0px 0px 4px 2px rgb(255 255 255 / 27%);
    animation-name: lamellaEffect;
    animation-duration: 1s;
  }

  @keyframes lamellaEffect {
    from {
      background-color: #97d315;
    }
    to {
      background: #2eb85c;
    }
  }

  .packageBox {
  }
  .beforeLoad {
    background: blue;
  }

  .emptyPlace {
    background: #d8dbe0;
  }

  .emptyStorage {
    background: #ebedef;
  }

  .fullPlace {
    background: #25d025;
  }

  .onLoad {
    background: #25d025;
  }

  .packageSector {
    font-size: 1rem;
    text-align: center;
    background: #f7c315;
  }

  #infoPaper {
    width: 70%;
    padding: 3px;
    margin-left: auto;
    margin-right: auto;
  }

  .lamella {
    margin-bottom: 1vh;
    width: 95%;
    font-size: 0.6vh;
    text-align: center;
    background-size: contain;
    background-repeat: repeat;
    background: #f6dd8a;
    /*position: absolute;*/
    /*z-index: 2;*/
    height: 1.5vh;
    //line-height: 25px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .storageSector {
    font-size: 1vh;
    text-align: center;
    background: #f6dd8a;
    width: 15vmin;
  }

  .lamellaCut {
    float: left;
    height: 1.4vh;
    //border-right:solid 1px red;
  }

  .waste {
    background: #e5535378;
    border-right: solid 1px red;
  }

  .goodWood {
    border-right: solid 1px red;
    //background: #e5535378;
  }
`;

export const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: grid;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //text-align:center;
`;

export const DataInfoInline = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

export const TextField = styled.div`
  float: left;
  margin: 5px;
  font-weight: bold;
  font-size: smaller;
`;

export const BoxField = styled.div`
  padding: 3px;
  font-size: smaller;
`;

export const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

export const Bold = styled.div`
  //font-size: small;
  font-weight: bold;
`;

export const PackageContainer = styled.div`
  position: absolute;
  margin: auto;
  color: white;
  font-size: smaller;
  z-index: 100;
  //background: #f9b115c4;
  height: 5vh;
  width: 8vw;
  max-width: 120px;
  max-height: 45px;
  text-align: left;  
  display: flex;
  justify-content: center;
  font-weight: bold;
  span; b {
    height: auto;
    width: auto;
  }

  &.full {
    box-shadow: 0px 1px 1px 1px black;
    z-index:4;
    background: #ffc107cf;
  }

  &.using {
    background-color: #84d300;
    color: black;
  }

`;

export const KafeContainer = styled.div`
  //position: absolute;

  margin: auto;
  display: flex;
  flex-direction: row;
  color: #4e342e;
  font-size: medium;
  z-index: 100;
  background: #f9b115c4;
  height: 80vh;
  width: 90vw;
  max-height: 1000px;
  max-width: 1500px;
  font-weight: '300;
`;

export const KafeBoxWrapper = styled.div`
  height: 80vh;
  width: 90vw;
  overflow: hidden;
  //position: relative;

  .animateNumber {
    color: black;
    font-size: 1.2rem;
    animation-name: number;
    animation-duration: 1s;
    transition-timing-function: ease-in-out;
  }

  @keyframes number {
    from {
      color: black;
    }
    to {
      color: #97d315;
    }
  }

  .counter {
    font-size: 1rem;
    width: 10px;
    padding: 2px;
    font-weight: bold;
  }

  .emptyDot {
    background-color: #d8dbe0;
    box-shadow: 0px 0px 4px 2px rgb(255 255 255 / 27%);
  }

  .fullDot {
    background-color: #97d315;
    box-shadow: 0px 0px 4px 2px rgb(255 255 255 / 27%);
    animation-name: lamellaEffect;
    animation-duration: 1s;
  }

  .packageBox {
  }
  .beforeLoad {
    background: blue;
  }

  .emptyPlace {
    background: #d8dbe0;
  }

  .emptyStorage {
    background: #ebedef;
  }

  .fullPlace {
    background: #25d025;
  }

  .onLoad {
    background: #25d025;
  }

  .packageSector {
    font-size: 1rem;
    text-align: center;
    background: #f7c315;
  }

  #infoPaper {
    width: 70%;
    padding: 3px;
    margin-left: auto;
    margin-right: auto;
  }

  .waste {
    background: #e5535378;
    border-right: solid 1px red;
  }

  .goodWood {
    border-right: solid 1px red;
    //background: #e5535378;
  }
`;
