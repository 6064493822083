/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';

const ModalHeader = styled.div`
  //background-color: #f7c315;
  font-size: 1.5rem;
  font-weight: bold;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  color: #f9b115;
  background: rgb(0 0 0 / 80%);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px;
  z-index: 2;
`;

export default ModalHeader;
