/**
 *
 * VerticallyAlignedText
 *
 */

import PropTypes from 'prop-types';

function VerticallyAlignedText(props) {
  return (
    <div style={{ ...props.style, display: 'table', height: `${props.height}`, width: '100%' }}>
      <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>{props.children}</div>
    </div>
  );
}

VerticallyAlignedText.propTypes = {
  height: PropTypes.string,
  children: PropTypes.any
};

export default VerticallyAlignedText;
