import { useState, useEffect, useContext } from 'react';
import { UnitSystemContext } from './unitSystemContext';

const unitConversions = {
  length: {
    base: 'meters',
    units: {
      meters: 1,
      feet: 3.28084,
      inches: 39.3701,
      miles: 0.000621371,
      centimeters: 100,
      millimeters: 1000,
      kilometers: 0.001
    }
  },
  weight: {
    base: 'kilograms',
    units: {
      kilograms: 1,
      pounds: 2.20462,
      ounces: 35.274
    }
  },
  volume: {
    base: 'cubic meters',
    units: {
      'cubic meters': 1,
      'cubic centimeters': 1000000,
      'cubic inches': 61023.7441,
      'cubic feet': 35.3147,
      'cubic miles': 2.39913e-10,
      liters: 1000,
      milliliters: 1000000,
      gallons: 264.172,
      quarts: 1056.69,
      pints: 2113.38
    }
  }
};

const useUnitConverter = () => {
  const { unitSystem, toggleUnitSystem } = useContext(UnitSystemContext);

  const convertUnit = (value, type, fromUnit, targetUnit, decimalPoints) => {
    if (unitSystem === 'metric') {
      return value;
    }

    const { units } = unitConversions[type];
    if (!units[fromUnit] || !units[targetUnit]) {
      throw new Error('Invalid unit provided');
    }

    // Check if the last character is a decimal point and store this information
    const endsWithDecimalPoint = _.defaultTo(value, '').toString().slice(-1) === '.';
    const safeDecimalPoints = decimalPoints != null ? decimalPoints : 2;

    // Remove the decimal point for conversion if it exists
    const numericValue = endsWithDecimalPoint ? parseFloat(_.defaultTo(value, '').toString().slice(0, -1)) : parseFloat(value);

    const factorToBase = 1 / units[fromUnit];
    const factorFromBase = units[targetUnit];
    const convertedValue = numericValue * factorToBase * factorFromBase;

    // Construct the result with the decimal point if needed
    const resultValue = parseFloat(convertedValue.toFixed(safeDecimalPoints)).toString() + (endsWithDecimalPoint ? '.' : '');

    return resultValue;
  };

  const getUnitDisplay = (firstUnit, secondUnit) => {
    if (unitSystem == 'metric') {
      return firstUnit;
    } else {
      return secondUnit;
    }
  };

  return {
    unitSystem,
    toggleUnitSystem,
    convertUnit,
    getUnitDisplay
  };
};

export default useUnitConverter;
