/**
 *
 * PackageLoadingBox
 *
 */

import { useState } from 'react';

import Wrapper from './Wrapper';
import Package from '../Package';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

import StatusItem from '../../../StatusItem';
import FormattedMessage from '../../../TranslationWrapper';
import Button from '../../../Button';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { MoreIcon, SettingsIcon } from '../../../reusable/Icons';

import DModal from '../../../common/DialogModal/Modal';
import globalMessages from '../../../../globalMessages';
import ControlModal from '../../../../containers/EditControlModal/index';

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const InfoDiv = styled.div`
  text-align: center;
`;

function RawInfeedLoadingBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  const productionstatus = _.get(props, 'productionstatus', null);
  const history = useHistory();

  const [isModal1Opened, setIsModal1Opened] = useState(false);

  function toggleModal(string) {
    if (string === 'set-infeed') {
      setIsModal1Opened(!isModal1Opened);
      return;
    }

    setIsModal1Opened(false);
  }

  const getPackageString = () => {
    let packageString = '';
    if (props.rawSortingLoading !== null) {
      packageString = props.rawSortingLoading.lamellaThickness + ' x ' + props.rawSortingLoading.lamellaWidth + ' x ' + props.rawSortingLoading.averageLength + ' ' + props.rawSortingLoading.quality;
    }
    return packageString;
  };

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> Loaded packages</SubInfo>
      </Paper>
      <br />
      <DataInfo>
        <Package className={`${props.allowanceRawInfeed ? 'loading' : ''} ${_.get(productionstatus, 'rawSortingLoading.shouldBeRemoved', null) ? 'red' : ''}`}>
          <span style={{ fontSize: '12px' }}>{getPackageString()}</span>
        </Package>
        <InfoDiv>
          <FormattedMessage {...globalMessages.boardsBeforePlaner} />: <b>{String(_.get(productionstatus, 'numberOfBoardsBeforePlaner', 0))}</b>
        </InfoDiv>
        <br />
        <StatusItem item={_.get(props, 'allowanceRawInfeed', null)} type="green">
          <FormattedMessage {...globalMessages.rawInfeedAllowance} identifier={'rawInfeedAllowance'} />
        </StatusItem>
        <StatusItem item={_.get(props, 'allowanceMultiplan', null)} type="green">
          <FormattedMessage {...globalMessages.allowanceMultiplan} identifier={'allowanceMultiplan'} />
        </StatusItem>
        <span style={{ float: 'right' }}>
          <Button onClick={() => setIsModal1Opened(true)}>
            <SettingsIcon style={{ width: '1.2rem', height: '1.2rem' }} />
            {/* Set infeed */}
          </Button>
          <DModal id={'set-infeed'} target={'set-infeed'} isOpened={isModal1Opened} onClose={toggleModal}>
            <ControlModal
              action={'set-infeed'}
              screenstyle={'small'}
              style={{
                position: 'absolute',
                width: '50%',
                height: '80%',
                left: '25%',
                top: '15%',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '2300'
              }}
              close={toggleModal}
            />
          </DModal>
        </span>
      </DataInfo>
      <div style={{ position: 'absolute', width: '100%', bottom: '60px' }}>
        <Button
          onClick={() =>
            history.push({
              pathname: '/rawinfeed',
              props: props.status
            })
          }>
          <MoreIcon style={{ width: '2rem', height: '2rem' }} />
          {/* Detailed view */}
        </Button>
      </div>
    </Wrapper>
  );
}

RawInfeedLoadingBox.propTypes = {};

export default RawInfeedLoadingBox;
