import { useContext } from 'react';
import UserContext from './userContext';

const useCurrentUser = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  // console.log('# useCurrentUser: ', currentUser);
  return {
    currentUser,
    setCurrentUser
  };
};
export default useCurrentUser;
