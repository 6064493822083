const schilliger_edit_settings = [
  {
    'set-infeed': {
      title: 'Set Infeed',
      settings: [
        { rawSortingLoading: { type: 'status', identifier: 'rawSortingLoading', value: null } },
        {
          allowanceRawInfeed: { type: 'boolStatus', identifier: 'allowanceRawInfeed', value: false }
        },
        {
          numberOfBoardsBeforePlaner: {
            type: 'status',
            identifier: 'numberOfBoardsBeforePlaner',
            value: 0
          }
        },
        {
          allowanceMultiplan: { type: 'boolStatus', identifier: 'allowanceMultiplan', value: false }
        }
      ]
    }
  },
  {
    'set-superplan': {
      title: 'Set Superplan',
      settings: [
        {
          id: {
            type: 'nested-nested-status',
            identifier: 'lam_for_ep',
            childIdent: 'joinedDim',
            childChildIdent: 'id',
            value: 0
          }
        },
        {
          stackingNumOfLayers: {
            type: 'nested-nested-status',
            identifier: 'lam_for_ep',
            childIdent: 'joinedDim',
            childChildIdent: 'stackingNumOfLayers',
            value: 0
          }
        },
        {
          stackingLamellasPerLayer: {
            type: 'nested-nested-status',
            identifier: 'lam_for_ep',
            childIdent: 'joinedDim',
            childChildIdent: 'stackingLamellasPerLayer',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingEtage: { type: 'status', identifier: 'manualFillingEtage', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } },
        { dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }
      ]
    }
  },
  {
    'set-zink': {
      title: 'Set Zink',
      settings: [
        {
          woodBufferForDimension: {
            type: 'boolStatus',
            identifier: 'woodBufferForDimension',
            value: false
          }
        }
        // {'negativeWoodBufferForBetterDimensionNext': {'type' : 'status','identifier':'negativeWoodBufferForBetterDimensionNext', 'value': 0}},
        // {'percentageOfWoodBeforeGoldenEye': {'type' : 'status','identifier':'percentageOfWoodBeforeGoldenEye', 'value': 0}},
        // {'changeWidthFrom': {'type' : 'status','identifier':'changeWidthFrom', 'value': 0}},
        // {'changeWidthTo': {'type' : 'value', 'value': 0}},
        // {'changeThicknessFrom': {'type' : 'status','identifier':'changeThicknessFrom', 'value': 0}},
        // {'changeThicknessTo': {'type' : 'value', 'value': 0}},
        // {'changeBoardDimensions': {'type' : 'bool', 'value': false}},
        // {'makeExtraLamellasId': {'type' : 'value', 'value': 0}},
        // {'makeExtraLamellasNum': {'type' : 'value', 'value': 0}},
        // {'makeExtraLamellas': {'type' : 'bool', 'value': false}},
        // {'changePlaneThicknessFrom': {'type' : 'value', 'value': 0}},
        // {'changePlaneThicknessTo': {'type' : 'value', 'value': 0}},
        // {'changePlaneThickness': {'type' : 'bool', 'value': false}},
        // {'stickerTest': {'type' : 'bool', 'value': false}},
      ]
    }
  },
  {
    'set-no-group': {
      title: 'Set No Group',
      settings: [
        { noGroupMode: { type: 'boolStatus', identifier: 'noGroupMode', value: false } },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        { noGroupModeCOversize: { type: 'status', identifier: 'noGroupModeCOversize', value: 0 } },
        { noGroupModeLOversize: { type: 'status', identifier: 'noGroupModeLOversize', value: 0 } },
        { kvhOversize: { type: 'status', identifier: 'kvhOversize', value: 0 } },
        { xcutOversize: { type: 'status', identifier: 'xcutOversize', value: 0 } }
      ]
    }
  },
  {
    'set-editpackage': {
      settings: [],
      cherryPick: ['packageId', 'packageNumber', 'identifier', 'averageLength', 'lamellaLength', '', 'pieces', 'piecesUsed']
    }
  },
  {
    'set-editjd': {
      settings: [],
      cherryPick: [
        'lamellaThickness',
        'lamellaWidth',
        'lamellaLength',
        'lamellaPlannedThickness',
        'lamellaPlannedWidth',
        'numLamellas',
        'zincDoneNumLamellas',
        'producingOnZinc',
        'finishedProducingOnZinc',
        'wrapping',
        'orderTypeString',
        'numLamellasPerLayer',
        'stackingNumOfLayers',
        'separatorDistance',
        'isKVH',
        'insulationWidth',
        'emptying',
        'emptied',
        'finishedProducingInsZinc',
        'finishedProducingOnZPress',
        'producingOnISoZinc',
        'insZincDoneNumLamellas',
        'insNumLamellas',
        'insRawNumLamellas',
        'insulationRawWidth',
        'skipIsoDimension',
        'zincWidthOffset',
        'zincThicknessOffset',
        'zPressDoneNumLamellas',
        'producingOnZPress',
        'forceEmpty',
        'qLamellaLength',
        'qLamellaNum',
        'extraLamellaOrder',
        'multiPieceWidth',
        'multiPieceLength',
        'layerMatrix',
        'orientation',
        'type',
        'isLong',
        'isCross',
        'projectName',
        'frozen'
      ]
    }
  },
  {
    'set-editControlOrder': {
      settings: [],
      cherryPick: ['panelWidth', 'panelLength', 'orderNumber', 'orderImportNumber', 'jobCode', 'multipiecePanel', 'pressingMatrix', 'sentToPress', 'status', 'sanded', 'layers', 'projectName']
    }
  },
  {
    'set-editSortingOrder': {
      settings: [],
      cherryPick: []
    }
  }
];

export default schilliger_edit_settings;
