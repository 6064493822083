/**
 *
 * LittleLamellaBox
 *
 */

import React from 'react';
import Wrapper from './Wrapper';

class LittleLamellaBox extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return <Wrapper style={this.props.style} className={this.props.className}></Wrapper>;
  }
}

export default LittleLamellaBox;
