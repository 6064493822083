import styled from 'styled-components';

export const Wrapper = styled.div`

  width: 100%;
  height: 100%;
  
  .elementResponsive {
      width: 100%;
      height: 70vh;
  }

  .background {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-right auto;
  }

  .elements {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-right auto;
  }

  .node {
    background-color: white;
    outline: 3px solid;
    outline-color: #5b6482;
    border-radius: 3px;
    display: inline-block;
    margin-top: 1%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .nodeFull {
    outline: 3px solid;
    outline-color: #5b6482;
    border-radius: 3px;
    display: inline-block;
    margin-top: 1%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .wrappedPackage {
    width: 70vw;
    height: 2.7vh;
    background: #f6dd8a;
    outline: 3px solid;
    outline-color: #5b6482;
    border-radius: 3px;
    display: inline-block;
    margin-top: 1vh;
    margin-left: auto;
    margin-right: auto;
  }

  .lamellaDrying {
    margin-top: 0.3vh;
    outline: 1px solid;
    outline-color: #5b6482;
    border-radius: 2px;
    height: 1vh;
    margin-bottom: 0.3vh;
  }

  .lamellaExtra {
    box-shadow: 0px 2px 14px 1px #25d02557, 0px 1px 1px 0px #25d0253b, 0px 1px 3px 0px #25d02585;
  }

  .centerLamella {
    border-bottom: 1px solid #d8dbe0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .lamellaLast {
    max-width: 100%;
  }

  .lamellaLast .lamellaCut:last-child {
    float: right;
  }

  .lamellaLast .lamellaCut:first-child {
    float: left;
  }

  .lamellaLabel {
    font-size: smaller;
    width: 80%;
    position: absolute;
    bottom: 0.5vh;
    text-align: center;
    height: 2vh;
    background: rgb(255 255 255 / 22%);
  }

  .lamellaCut {
    float: left;
    height: 4vh;
    border-right: dotted 1px red;
  }


  .lamellaVerticalCut{
    display: grid;
    height: 170px;
    position: absolute;
    right: 0;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .lamella:hover,
  .lamella:active {
    cursor: grab;
    //box-shadow: 0px 2px 14px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    box-shadow: 1px 0px 3px 1px #48494a;
  }

  .cutlistMode:hover,
  .cutlistMode:active {
    cursor: crosshair;
  }

  .waste {
    background: #e5535342;
    content: 'waste';
  }

  .waste:after {
    content: '✘';
    color: black;
  }

  .goodWood {
    //background: #f6dd8a;
    background: #25d02500;

  }

  .goodWood:after {
    content: '✔';
    color: green;
    font-weight: bold;
  }

  .grab {
    font-size: 4vh;
    cursor: grab;
    color: rgb(235, 237, 239);
  }

  .grab:hover,
  .grab:active {
    color: #969696;
  }


.draggableViewMenu {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    //bottom: 10px;
    width: 60%;
    background: #edf2f6;
    height: 40px;
  }

`;

export default Wrapper;
