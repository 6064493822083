import { PACKAGES_LOADED, HIDE_LOADER, CHECKBOX_TOGGLED, CLEAR_CHECKED_ITEMS, ITEMS_MOVED, BACKEND_CALL_FINISHED, SHOW_LOADER, CLEAR_DATA, RESET_SOURCE, API_ERROR } from './constants';
import { UPDATE_COMPONENT } from '../Websocket/constants';
import { MODEL_SAVED } from '../EditView/constants';
import produce from 'immer';
import globalMessages from '../../globalMessages';
const colors = {
  true: 'white',
  false: '#eee'
};

export const initialState = {
  loading: false,
  filters: {},
  title: globalMessages.ltitle,
  items: {
    results: null
  },
  viewShouldRefresh: false,
  colors,
  coloringFieldKey: 'erpImport',
  sort: 'id',
  page: 1,
  itemsMoved: false,
  actions: [],
  checkedItems: [],
  source: '',
  modelsaved: false,
  apiError: false
};

const tableViewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLEAR_DATA:
        draft.items = [];
        break;
      case PACKAGES_LOADED:
        draft.items = action.items;
        draft.page = action.page;
        draft.filters = action.queries;
        draft.sort = typeof action.queries.ordering === 'undefined' ? state.sort : action.queries.ordering;
        draft.viewShouldRefresh = false;
        draft.modelsaved = false;
        break;
      case CHECKBOX_TOGGLED:
        if (action.value) {
          draft.checkedItems = state.checkedItems.concat([action.item]);
        } else {
          draft.checkedItems = state.checkedItems.filter((el) => el.id !== action.item.id);
        }
        break;
      case CLEAR_CHECKED_ITEMS:
        draft.checkedItems = [];
        break;
      case ITEMS_MOVED:
        draft.itemsMoved = true;
        break;
      case SHOW_LOADER:
        draft.loading = true;
        break;
      case HIDE_LOADER:
        draft.loading = false;
        break;
      case BACKEND_CALL_FINISHED:
        draft.source = action.source;
        draft.itemsMoved = true;
        draft.checkedItems = [];
        break;
      case MODEL_SAVED:
        draft.modelsaved = true;
        break;
      case UPDATE_COMPONENT:
        draft.source = action.data.api;
        break;
      case RESET_SOURCE:
        draft.source = '';
        break;
      case API_ERROR:
        draft.apiError = action.error;
        break;
      default:
        break;
    }
  });

export default tableViewReducer;
