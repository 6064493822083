/**
 *
 * TranslationWrapper
 *
 */

import { FormattedMessage } from 'react-intl';

function TranslationWrapper(props) {
  // eslint-disable-line react/prefer-stateless-function
  const getIdentifierIfPresent = (identifier) => {
    return identifier ? '!' + identifier : 'identifier missing';
  };

  return props !== undefined && Object.keys(props).length > 0 && props?.id ? <FormattedMessage {...props} /> : getIdentifierIfPresent(props.identifier);
}

export default TranslationWrapper;
