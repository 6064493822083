import styled from 'styled-components';

const ChartWrapper = styled.div`
  .chartMin {
    border-top: solid 1px red;
    margin-top: 10px;
  }

  .chartMax {
    border-bottom: solid 1px red;
  }
  .chartText {
    font-size: 10px;
    margin-left: 10px;
    font-weight: bold;
  }
`;

export default ChartWrapper;
