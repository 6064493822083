import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the siderbar state domain
 */

const selectSiderbarDomain = (state) => state.sidebar || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Siderbar
 */

const makeSelectSiderbar = () => createSelector(selectSiderbarDomain, (substate) => substate);

export default makeSelectSiderbar;
export { selectSiderbarDomain };
