import styled from 'styled-components';
// import { Link } from 'react-router';

const Wrapper = styled.div`
  //text-align: center;
  margin: 2px;

  &.tiny {
    height: 13px;
    float: left;
    padding-left: 10px;
  }

  &.big {
    font-size: 13px;
  }

  &::before {
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    margin-right: 10px;
    vertical-align: middle;
    margin-bottom: 4px;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
  }

  &.blue:before {
    border: 2px solid #00c1f9;
  }

  &.green:before {
    border: 2px solid #84d300;
  }

  &.yellow:before {
    border: 2px solid #f7c315;
  }

  &.red:before {
    border: 2px solid #ff8f00;
  }

  &.full.blue:before {
    background-color: #00c1f9;
    border: 2px solid #00c1f9;
  }

  &.full.green:before {
    background-color: #84d300;
    border: 2px solid #84d300;
  }

  &.full.red:before {
    background-color: #ff8f00;
    border: 2px solid #ff8f00;
  }

  &.full.yellow:before {
    background-color: #f7c315;
    border: 2px solid #f7c315;
  }
`;

export default Wrapper;
