const ante_edit_settings = [
  {
    'set-infeed': {
      title: 'Set Infeed',
      settings: [
        { rawSortingLoading: { type: 'status', identifier: 'rawSortingLoading', value: null } },
        {
          allowanceRawInfeed: { type: 'boolStatus', identifier: 'allowanceRawInfeed', value: false }
        },
        {
          numberOfBoardsBeforePlaner: {
            type: 'status',
            identifier: 'numberOfBoardsBeforePlaner',
            value: 0
          }
        },
        {
          allowanceMultiplan: { type: 'boolStatus', identifier: 'allowanceMultiplan', value: false }
        }
      ]
    }
  },
  {
    'set-superplan2': {
      title: 'Set Superplan2',
      settings: [
        {
          id: {
            type: 'nested-nested-status',
            identifier: 'wood_before_ep',
            childIdent: 'joinedDim',
            childChildIdent: 'id',
            value: 0
          }
        },
        {
          stackingNumOfLayers: {
            type: 'nested-nested-status',
            identifier: 'wood_before_ep',
            childIdent: 'joinedDim',
            childChildIdent: 'stackingNumOfLayers',
            value: 0
          }
        },
        {
          stackingLamellasPerLayer: {
            type: 'nested-nested-status',
            identifier: 'wood_before_ep',
            childIdent: 'joinedDim',
            childChildIdent: 'stackingLamellasPerLayer',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [{ dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }]
    }
  },
  {
    'set-zink': {
      title: 'Set Zink',
      settings: [
        {
          woodBufferForDimension: {
            type: 'boolStatus',
            identifier: 'woodBufferForDimension',
            value: false
          }
        }
      ]
    }
  },
  {
    'set-no-group': {
      title: 'Set No Group',
      settings: [
        { noGroupMode: { type: 'boolStatus', identifier: 'noGroupMode', value: false } },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        { noGroupModeCOversize: { type: 'status', identifier: 'noGroupModeCOversize', value: 0 } },
        { noGroupModeLOversize: { type: 'status', identifier: 'noGroupModeLOversize', value: 0 } },
        { kvhOversize: { type: 'status', identifier: 'kvhOversize', value: 0 } },
        { xcutOversize: { type: 'status', identifier: 'xcutOversize', value: 0 } }
      ]
    }
  },
  {
    'set-editpackage': {
      settings: [],
      cherryPick: ['packageNumber', 'identifier', 'averageLength', 'lamellaLength', 'lamellaWidth', 'lamellaThickness', 'pieces', 'piecesUsed']
    }
  },
  {
    'set-editjd': {
      settings: [],
      cherryPick: [
        'id',
        'lamellaThickness',
        'lamellaWidth',
        'lamellaLength',
        'lamellaPlannedThickness',
        'lamellaPlannedWidth',
        'numLamellas',
        'zincDoneNumLamellas',
        'producingOnZinc',
        'finishedProducingOnZinc'
      ]
    }
  },
  {
    'set-editControlOrder': {
      settings: [],
      cherryPick: ['layerId', 'orderNumber', 'orderImportNumber', 'lamellasInPackage', 'woodType', 'lamellaThickness', 'lamellaWidth', 'quality', 'lamellaLength']
    }
  }
];

export default ante_edit_settings;
