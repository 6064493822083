/**
 *
 * AccumulatorBoards
 *
 */

import { connect } from 'react-redux';
import Wrapper from './Wrapper';
import InlineWrapper from '../../../reusable/InlineWrapper';
import LittleLamellaBox from '../LittleLamellaBox';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

const TextField = styled.div`
  float: left;
  margin: 5px;
  font-weight: bold;
  font-size: smaller;
`;

const BoxField = styled.div`
  padding: 3px;
  font-size: smaller;
`;

// const SubInfo = styled.div`
//   font-size: smaller;
//   background: #ced2d8;
//   text-align: center;
// `;

// <AccumulatorBoards currentBoards={1} maxBoards={10} boards={6}/>
export function AccumulatorBoards(props) {
  // eslint-disable-line react/prefer-stateless-function
  const getBoards = () => {
    const boards = [];
    for (let i = 0; i < props.boards; i++) {
      boards.push(<LittleLamellaBox key={i} className={`${i / props.boards >= props.currentBoards / props.maxBoards ? 'empty' : ''}`} />);
    }
    return boards;
  };
  return (
    <Wrapper>
      <div>
        <InlineWrapper>
          <div style={{ width: 'calc(97% - 30px)' }}>
            <div style={{ overflow: 'hidden' }}>
              <div>
                <BoxField>
                  <b>{props.currentBoards} lamellas</b>
                </BoxField>
              </div>
            </div>
            <InlineWrapper style={{ height: '10px', width: '100%', float: 'left' }}>{getBoards(props.boards)}</InlineWrapper>
          </div>
        </InlineWrapper>
      </div>
      <div className="orderEtage" style={{ padding: '2px' }}>
        <TextField> Dimensions:</TextField>
        <Paper elevation={1} style={{ float: 'right' }}>
          <BoxField>150 x 1250 x 25 cm</BoxField>
        </Paper>
      </div>
    </Wrapper>
  );
}

export default connect(null)(AccumulatorBoards);
