import styled from 'styled-components';

const Wrapper = styled.div`
  height: calc(100% - 95px);
  text-align: center;
  position: relative;

  .storageSector {
    font-size: 1vh;
    text-align: center;
    background: #f7c3157a;
    width: 15vmin;
  }
`;

export default Wrapper;
