import React, { createContext, useContext, useState, useRef, useEffect } from 'react';

const RefreshDatesContext = createContext();

export const useRefreshDates = () => useContext(RefreshDatesContext);

export const RefreshDatesProvider = ({ children }) => {
  const [refreshDates, setRefreshDates] = useState({});
  const refreshDatesRef = useRef(refreshDates);

  useEffect(() => {
    refreshDatesRef.current = refreshDates;
  }, [refreshDates]);

  const updateRefreshDates = (api, date) => {
    setRefreshDates((dates) => ({ ...dates, [api]: date }));
  };

  return <RefreshDatesContext.Provider value={{ refreshDates, refreshDatesRef, updateRefreshDates }}>{children}</RefreshDatesContext.Provider>;
};
