import styled from 'styled-components';

export const Wrapper = styled.div`
  &.archive {
    -webkit-filter: grayscale(70%);
    -moz-filter: grayscale(70%);
    filter: grayscale(70%);
    transition: all 0.5s ease;
  }
`;
