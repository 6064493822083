import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  .red {
    color: red;
  }

  chart {
    height: 2rem;
    width: 30rem;
  }

  .chart div {
    height: 100%;
    float: left;
  }

  .chart .start {
    border-radius: 2rem 0 0 2rem;
  }

  .chart .end {
    border-radius: 0 2rem 2rem 0;
  }

  .chart .red {
    background-color: red;
  }

  .chart .blue {
    background-color: blue;
  }

  .chart .green {
    background-color: green;
  }

  .chart .cyan {
    background-color: cyan;
  }
`;

export default Wrapper;
