import modelFieldMessages from './modelFieldMessages';

class MachineSetting {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    rawWidth: {
      key: 'rawWidth',
      type: 'string',
      title: modelFieldMessages.rawWidth
    },
    rawThickness: {
      key: 'rawHeight',
      type: 'string',
      title: modelFieldMessages.rawThickness
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality
    },
    pressForce: {
      key: 'pressForce',
      type: 'string',
      title: modelFieldMessages.pressForce
    },
    jointTime: {
      key: 'jointTime',
      type: 'string',
      title: modelFieldMessages.jointTime
    }
  };

  static actions = {
    editOrder: {
      key: 'edit',
      type: 'edit',
      identifier: 'editOrder',
      title: modelFieldMessages.edit,
      configuration: {
        model: 'machineSetting',
        url: '/api/v1/productionline/machinesettings/'
      }
    }
  };
}

export default MachineSetting;
