/*
 *
 * Header constants
 *
 */

export const DEFAULT_ACTION = 'app/TableView/DEFAULT_ACTION';
export const API_CALL = 'app/TableView/API_CALL';
export const PACKAGES_LOADED = 'app/TableView/TODO/PACKAGES_LOADED';
export const CHECKBOX_TOGGLED = 'app/TableView/TODO/CHECKBOX_TOGGLED';
export const CLEAR_CHECKED_ITEMS = 'app/TableView/TODO/CLEAR_CHECKED_ITEMS';
export const ITEMS_MOVED = 'app/TableView/TODO/ITEMS_MOVED';
export const DELETE_ORDER = 'app/TableView/TODO/DELETE_ORDER';
export const DELETE_PROJECT = 'app/TableView/TODO/DELETE_PROJECT';
export const DELETE_RECIPE = 'app/TableView/TODO/DELETE_ORDER';
export const DELETE_PRODUCT = 'app/TableView/TODO/DELETE_PRODUCT';
export const ADD_RECIPE = 'app/TableView/TODO/DELETE_RECIPE';
export const ORDER_RECIPE = 'app/TableView/TODO/ORDER_RECIPE';
export const CHANGE_EXTRA = 'app/TableView/TODO/CHANGE_EXTRA';
export const DELETE_EXTRA = 'app/TableView/TODO/DELETE_EXTRA';
export const PRINT_STICKER = 'app/TableView/TODO/PRINT_STICKER';
/* Confirmation modal */
export const BACKEND_CALL = 'app/TableView/BACKEND_CALL';
export const BACKEND_CALL_FINISHED = 'app/TableView/BACKEND_CALL_FINISHED';
export const CONTROL_CALL = 'app/TableView/CONTROL_CALL';
export const CONTROL_CALL_FINISHED = 'app/TableView/CONTROL_CALL_FINISHED';
export const SHOW_LOADER = 'app/TableView/SHOW_LOADER';
export const HIDE_LOADER = 'app/TableView/HIDE_LOADER';
export const CLEAR_DATA = 'app/TableView/CLEAR_DATA';
export const RESET_SOURCE = 'app/TableView/RESET_SOURCE';
export const DOWNLOAD_ITEM = 'app/TableView/DOWNLOAD_ITEM';
export const API_ERROR = 'app/TableView/API_ERROR';
export const URL_ACTION = 'app/TableView/URL_ACTION';
export const DELETE_SUPPLIER = 'app/TableView/DELETE_SUPPLIER';
