import { createSelector } from 'reselect';
import { initialState } from './reducer';
/**
 * Direct selector to the websocket state domain
 */
// const selectWebsocketDomain = () => (state) => state.get('websocket');
const selectWebsocketDomain = (websocket) => websocket || initialState;
/**
 * Other specific selectors
 */

/**
 * Default selector used by Websocket
 */

const selectWebsocket = () => createSelector(selectWebsocketDomain, (substate) => substate);

export default selectWebsocket;
export { selectWebsocketDomain };
