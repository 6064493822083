/**
 *
 * TextFieldMUI
 *
 * TextField not editable used as Text Label
 */

import TextFieldMUI from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

export const InfoTextField = withStyles({
  root: (props) =>
    // console.log('InfoTextField: ', props),
    ({
      '& #calculator': {
        width: '30%',
        marginBottom: '0vh',
        marginRight: '0vh',
        fontSize: '1em'
      },
      '& #small-calculator': {
        width: '30%',
        marginBottom: '0vh',
        marginRight: '0vh',
        fontSize: '0.8rem'
      },
      '& #long-info': {
        fontSize: 'smaller',
        color: 'black',
        fontWeight: '600',
        textShadow: '1px 1px 1px white',
        cursor: 'help',
        margin: ' -3px 0px -8px 0px'
      },
      '& #smallInfo': {
        width: '100px',
        fontSize: '1em',
        fontWeight: 'bold',
        textShadow: '1px 1px 1px white',
        cursor: 'help',
        boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '0.4rem'
      },
      '& #drying': {
        width: '100%',
        fontSize: '1vmin',
        color: 'white',
        fontWeight: '600',
        textShadow: '1px 1px 1px black',
        cursor: 'help'
      },
      '& #info-label': {
        width: '25vw',
        fontSize: '1em',
        marginBottom: '0vh',
        marginRight: '0vh',
        marginLeft: '0vw',
        color: 'black',
        fontWeight: '',
        height: '1.2em'
      },
      '& #lineBoxInfo': {
        width: '100%',
        fontSize: '1em',
        fontWeight: 'bold',
        // textShadow: '1px 1px 1px white',
        cursor: 'none',
        boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '0.4rem',
        textAlign: 'center',
        color: props.color
        // marginLeft:'auto',
        // marginRight: 'auto',
      },
      '& #fiberInfo': {
        height: '12px',
        width: '100%',
        fontSize: '10px',
        fontWeight: 'bold',
        color: 'white',
        // textShadow: '1px 1px 1px white',
        cursor: 'none',
        // boxShadow:' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '0.4rem',
        textAlign: 'left'
        // color: props.color,
        // marginLeft:'auto',
        // marginRight: 'auto',
      },
      '& #layerOrientation': {
        fontSize: '1.5vh',
        fontWeight: 'normal',
        width: 'auto'
      },
      '& #packageInfo': {
        fontSize: '1vh',
        fontWeight: 'normal',
        width: props?.width,
        background: 'none',
        fontWeight: 'bold'
      },
      '& #longlagerInfo': {
        fontSize: '1vh',
        fontWeight: 'normal',
        maxWidth: '95%',
        // background:'green',
        fontWeight: 'bold'
      },
      '& #editForm': {
        width: '100%',
        fontSize: '1em',
        fontWeight: 'bold',
        // boxShadow:' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '0.4rem',
        textAlign: 'center',
        color: props.color,
        borderColor: 'red'
      },
      '& .MuiOutlinedInput-input': {
        padding: '12.5px 4px'
      },
      '& .MuiInput-root': {
        fontFamily: 'Poppins',
        fontWeight: '500',
        letterSpacing: 'normal',
        lineHeight: '1em'
      },
      '& .MuiInputLabel-root': {
        fontFamily: 'Poppins',
        lineHeight: '1em',
        color: 'black'
      },
      '& label.Mui-focused': {
        color: 'black',
        width: '110%'
        // fontWeight: 'bold'
      },
      '& .MuiInput-underline:after': {
        border: '0.1em  black'
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: 'Poppins',
        '& fieldset': {
          borderColor: 'green'
        },
        '&:hover fieldset': {
          borderColor: '#f9b115'
        },
        '&.Mui-focused fieldset': {
          border: '0.1em solid gray'
        }
      }
    })
})((props) => <TextFieldMUI hiddenLabel={true} color="primary" autoComplete="off" {...props} />);

export const DryingField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: '1vmin',
      color: 'white',
      fontFamily: 'Poppins'
    },
    '& .MuiOutlinedInput-input': {
      paddingBottom: '2px',
      paddingLeft: '3px',
      paddingRight: '3px',
      paddingTop: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }
  }
})((props) => (
  <TextFieldMUI
    {...props}
    size="small"
    sx={{
      fontSize: '8px'
    }}
  />
));

// lineBoxInfo

export const LineBoxInfo = withStyles({
  root: {
    '& .MuiInput-root': {
      width: '75%',
      fontSize: '1em',
      fontWeight: 'bold',
      cursor: 'none',
      boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      borderRadius: '0.4rem',
      textAlign: 'center',
      marginBottom: '10px',
      padding: '5px'
    },

    '& .MuiInputLabel-root': {
      fontSize: '1.3em',
      lineHeight: '1em'
      // color:'red'
    },

    '& label.Mui-focused': {
      color: 'black'
    },

    '&.Mui-focused fieldset': {
      border: '0.1em solid gray'
    }
  }
})((props) => (
  <TextFieldMUI
    // color="primary" {...props}
    {...props}
    size="small"
    hiddenLabel={true}
    color="dark"
    variant="filled"
    sx={{
      fontSize: '10px'
      // textShadow: '1px 1px 1px black',
    }}
  />
));

export const LayerInfo = withStyles({
  root: {
    '& .MuiInput-root': {
      fontSize: '1.3vh',
      fontWeight: 'bold',
      width: 'auto'
    },

    '& .MuiInputLabel-root': {
      fontSize: '1.3em',
      lineHeight: '1em'
      // color:'red'
    },

    '& label.Mui-focused': {
      color: 'black'
    },

    '&.Mui-focused fieldset': {
      border: '0.1em solid gray'
    }
  }
})((props) => (
  <TextFieldMUI
    // color="primary" {...props}
    {...props}
    size="small"
    hiddenLabel={true}
    color="dark"
  />
));

export const PressStackerField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: '1vh',
      fontWeight: 'normal',
      maxWidth: '95%',
      // background:'green',
      fontWeight: 'bold'
    },
    '& .MuiOutlinedInput-input': {
      // padding: '5.5px 3px'
    }
  }
})((props) => (
  <TextFieldMUI
    // color="primary" {...props}
    {...props}
    size="small"
    sx={{
      fontSize: '10px'
      // textShadow: '1px 1px 1px black',
    }}
  />
));
