import modelFieldMessages from '../modelFieldMessages';

export const qualityChoices = {
  2: modelFieldMessages.lcQuality,
  1: modelFieldMessages.laQuality,
  0: modelFieldMessages.saQuality
};

export const orientationChoices = {
  2: modelFieldMessages.qPackageType,
  1: modelFieldMessages.lPackageType
};

export const rotationChoices = {
  1: modelFieldMessages.upRotation,
  0: modelFieldMessages.downRotation
};

export const exitChoices = {
  1: modelFieldMessages.kallesoe,
  2: modelFieldMessages.exit
};

export const arolla_config = {
  qualityChoices,
  orientationChoices,
  rotationChoices,
  exitChoices
};

export default arolla_config;
