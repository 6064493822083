/**
 *
 * AccumulatorBox
 *
 */

import { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Wrapper from './Wrapper';
import styled from 'styled-components';
import './test.css';
import Paper from '@material-ui/core/Paper';
import Button from '../../../Button';
import { MoreIcon } from '../../../reusable/Icons';
import { addModal } from '../../../../containers/ModalManager/actions';

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const EtageBox = styled.div`
  padding: 5px;
  font-size: 8px;
  font-weight: lighter;
  display: inline-block;
  opacity: 0.8;
  margin-left: 5px;
  margin-right: 5px;
  width: 30px;
  height: 25px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export function LagerLongBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  // const history = useHistory();
  // const title = props.message;

  function mapReverse(reverse) {
    const reversed = reverse.map((num, index, reverse) => reverse[reverse.length - 1 - index]);
    return reversed;
  }

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> B11 | Etages Lager Long </SubInfo>
      </Paper>
      <br />
      <div style={{ margin: '0 auto', display: 'table', padding: '20px' }}>
        {props.status !== undefined &&
          props.status !== null &&
          mapReverse(props.status.longEtages).map((etage, i) => (
            <div key={i} style={{ display: 'flex' }}>
              {typeof etage.compartments !== 'undefined' &&
                mapReverse(etage.compartments).map((compartment, j) => {
                  const order = getOrderFromStatus(compartment.orderId, props.status);
                  let orderColor = 'white';
                  // const type = '';
                  if (order != null) {
                    orderColor = order.orderColor;
                  }
                  {
                    return (
                      <div key={`${i}${j}`}>
                        <EtageBox
                          key={`${i}${j}`}
                          style={{
                            backgroundColor: orderColor,
                            color: 'white',
                            left: `${(100 / Object.keys(etage.compartments).length) * j + 5}%`
                          }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          ))}
      </div>
      <div style={{ position: 'absolute', width: '100%', bottom: '0px' }}>
        <Button>
          <MoreIcon style={{ width: '2rem', height: '2rem' }} onClick={() => props.dispatch(addModal('modal-lagerlong'))} />
        </Button>
      </div>
    </Wrapper>
  );

  function getOrderFromStatus(id, status) {
    if (id === 0) {
      return null;
    }

    if (status != null && status.orderQueue !== undefined) {
      for (const index in status.orderQueue) {
        if (status.orderQueue[index].id !== undefined && status.orderQueue[index].id === id) {
          // console.log('status.orderQueue[index]: ',status.orderQueue[index]);
          return status.orderQueue[index];
        }
      }
    }

    return null;
  }
}

LagerLongBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  status: PropTypes.object
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(LagerLongBox);
