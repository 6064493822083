/*
 *
 * Siderbar actions
 *
 */

import { DEFAULT_ACTION, SIDEBAR_SHOW, INDICATOR_CHANGE } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function showSidebar(val) {
  return {
    type: SIDEBAR_SHOW,
    val
  };
}

export function indicatorChange(ident, active) {
  // console.log('indicatorChange: ',ident, ' active: ', active);
  return { type: INDICATOR_CHANGE, active, ident };
}

// export function setAuth(state) {
//   return { type: SET_AUTH, newAuthState: state };
// }
