/*
 *
 * Fiber constants
 *
 */

export const DEFAULT_ACTION = 'app/Websocket/DEFAULT_ACTION';
export const SET_LINE_DRAWER_CONTENT = 'app/Websocket/SET_LINE_DRAWER_CONTENT';
export const TOGGLE_LINE_DRAWER = 'app/Websocket/TOGGLE_LINE_DRAWER';
export const SET_DETAILVIEW_MODAL_CONTENT = 'app/Websocket/SET_DETAILVIEW_MODAL_CONTENT';
export const TOGGLE_DETAILVIEW_MODAL = 'app/Websocket/TOGGLE_DETAILVIEW_MODAL';
export const TOGGLE_ZOOM = 'app/Websocket/TOGGLE_ZOOM';
export const TOGGLE_FOCUS = 'app/Websocket/TOGGLE_FOCUS';
