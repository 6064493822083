import React, { createContext, useState, useEffect } from 'react';

// Create the context object with a default value
export const UnitSystemContext = createContext({
  unitSystem: 'metric',
  toggleUnitSystem: () => {}
});

export const UnitSystemProvider = ({ children }) => {
  const [unitSystem, setUnitSystem] = useState('metric');

  // Effect to load the initial unit system from localStorage
  useEffect(() => {
    const savedUnitSystem = localStorage.getItem('unitSystem');
    if (savedUnitSystem) {
      setUnitSystem(savedUnitSystem);
    }
  }, []);

  // Function to toggle unit system and save it to localStorage
  const toggleUnitSystem = () => {
    const newUnitSystem = unitSystem === 'metric' ? 'imperial' : 'metric';
    setUnitSystem(newUnitSystem);
    localStorage.setItem('unitSystem', newUnitSystem);
  };

  // Provide the context with unitSystem and the toggle function
  return <UnitSystemContext.Provider value={{ unitSystem, toggleUnitSystem }}>{children}</UnitSystemContext.Provider>;
};
