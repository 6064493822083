import modelFieldMessages from './modelFieldMessages';

class Product {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id,
      editable: false
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    bottomCut: {
      key: 'bottomCut',
      type: 'string',
      title: modelFieldMessages.bottomCut
    },
    sideCut: {
      key: 'sideCut',
      type: 'string',
      title: modelFieldMessages.sideCut
    },
    thickness: {
      key: 'thickness',
      type: 'string',
      title: modelFieldMessages.thickness
    },
    width: {
      key: 'width',
      type: 'string',
      title: modelFieldMessages.width
    },
    bandsaw: {
      key: 'bandsaw',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.bandsaw
    },
    scannerStatus: {
      key: 'scannerPreset',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.scannerStatus
    },
    longMaterialStacker: {
      key: 'longMaterialStacker',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.longMaterialStacker
    },
    strengthComb: {
      key: 'strengthCombination',
      type: 'string',
      title: modelFieldMessages.strengthComb
    },
    sawmillSelection: {
      key: 'sawmillSelection',
      type: 'string',
      title: modelFieldMessages.sawmillSelection
    },
    printer: {
      key: 'printer',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.printer
    },
    rauteDataOutput: {
      key: 'rauteDataOutput',
      type: 'string',
      title: modelFieldMessages.rauteDataOutput
    },
    stacker: {
      key: 'stacker',
      type: 'string',
      title: modelFieldMessages.stacker
    },
    packetSize: {
      key: 'packetSize',
      type: 'string',
      title: modelFieldMessages.packetSize
    },
    stickLayerNumbers: {
      key: 'stickLayerNumbers',
      type: 'string',
      title: modelFieldMessages.stickLayerNumbers
    },
    stackingOrientation: {
      key: 'stackingOrientation',
      type: 'string',
      title: modelFieldMessages.stackingOrientation
    },
    fromStrapMachine: {
      key: 'fromStrapMachine',
      type: 'string',
      title: modelFieldMessages.fromStrapMachine
    },
    materialOutput: {
      key: 'materialOutput',
      type: 'string',
      title: modelFieldMessages.materialOutput
    },
    orderPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getCLTRecipeImage'
    },
    getOrderWithRecipeImage: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getOrderWithRecipeImage'
    }
  };

  static actions = {
    deleteProduct: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteProduct',
      modal: {
        modal: true,
        buttonText: 'deleteProduct',
        key: 'deleteProduct',
        action: 'deleteProduct',
        actionData: {
          target: 'backend',
          source: 'sepa/products/',
          url: '/api/v1/sepa/products/remove_product/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteControlPackage: {
      key: 'delete',
      type: 'deletePackage',
      dispatch: 'deletePackage',
      modal: {
        modal: true,
        buttonText: 'deletePackage',
        key: 'deletePackage',
        action: 'deletePackage',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'packagesqueue',
          identifier: 'package-loaded-delete',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editProduct: {
      key: 'edit',
      type: 'edit',
      identifier: 'editProduct',
      configuration: {
        model: 'product',
        url: '/api/v1/sepa/products/'
      }
    }
  };
}

export default Product;
