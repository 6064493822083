/**
 *
 * AccumulatorBox
 *
 */

import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import AccumulatorBoards from '../AccumulatorBoards';
import styled from 'styled-components';
import './test.css';
import Paper from '@material-ui/core/Paper';

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

export function AccumulatorBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  const getEtage = () => {
    const etages = [<AccumulatorBoards key={1} currentBoards={props.currentBoards} maxBoards={props.maxBoards} boards={props.boards} />];

    return etages;
  };

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> Accumulator Etages </SubInfo>
      </Paper>
      <br />
      {getEtage()}
    </Wrapper>
  );
}

AccumulatorBox.propTypes = {
  status: PropTypes.object,
  maxBoards: PropTypes.number,
  currentBoards: PropTypes.number,
  boards: PropTypes.number
};

export default AccumulatorBox;
