/*
 ** EditView arboreal define fields for editview reducer
 */
import { BSHOrder, Order, Layer, RecipeLayer, Recipe, IncomingPackage, Erp } from '../../../utils/models';
import CLTPackage from '../../../utils/CLTPackage';
import MachineSetting from '../../../utils/MachineSetting';
import Project from '../../../utils/Project';
import { orderChildCalculateValues, arborealMaterialCodeCalculator, schilligerChildCalculateValues, schneiderModelCalculator } from '../../../utils/modelFunctions/calculators';
import { generalValidator, planerSetupValidator } from '../../../utils/modelFunctions/validators';
const arboreal_config = {
  machineSettings: [
    [
      [{ ...MachineSetting.fields.id, editable: false }, { ...MachineSetting.fields.rawThickness }, { ...MachineSetting.fields.rawWidth }, { ...MachineSetting.fields.quality }],
      [{ ...MachineSetting.fields.pressForce }, { ...MachineSetting.fields.jointTime }]
    ]
  ],
  orderFields: [
    [
      [{ ...Order.fields.orderPicture }],
      [
        { ...Order.fields.projectNumber },
        { ...Order.fields.projectName },
        { ...Order.fields.masterpanel },
        { ...Order.fields.nestingPanelNumber },
        { ...Order.fields.panelMaterial },
        { ...Order.fields.panelLength },
        { ...Order.fields.panelWidth },
        { ...Order.fields.panelThickness }
      ]
    ],
    // ]
    [[{ ...Order.fields.panelPicture }]]
  ],
  cltPackageFields: [
    [
      [
        { ...CLTPackage.fields.id, editable: false },
        { ...CLTPackage.fields.projectNumber },
        { ...CLTPackage.fields.projectName },
        { ...CLTPackage.fields.materialCode },
        { ...CLTPackage.fields.batchId },
        { ...CLTPackage.fields.numOfLamellas },
        { ...CLTPackage.fields.lamellasDone },
        { ...CLTPackage.fields.lamellasReserved },
        { ...CLTPackage.fields.lamellasUsed },
        { ...CLTPackage.fields.planningNumber }
      ],
      [
        { ...CLTPackage.fields.lamellaLength },
        { ...CLTPackage.fields.rawThickness },
        { ...CLTPackage.fields.rawWidth },
        { ...CLTPackage.fields.orientation },
        { ...CLTPackage.fields.crossMultiple },
        { ...CLTPackage.fields.strengthString },
        { ...CLTPackage.fields.quality },
        { ...CLTPackage.fields.woodType }
      ]
    ]
  ],
  recipeOrderFields: [
    [
      [{ ...Recipe.fields.orderPicture }],
      [
        { ...Recipe.fields.identifier },
        { ...Order.fields.panelThickness },
        { ...Order.fields.oversize, halfsize: true, editable: true },
        { ...Order.fields.lenOversizeL, halfsize: true, editable: true },
        { ...Order.fields.oversizeWidth, halfsize: true },
        { ...Order.fields.lenOversizeC, halfsize: true }
      ]
    ]
  ],

  incomingPackageFields: [
    [
      [{ ...IncomingPackage.fields.clientId }, { ...IncomingPackage.fields.lamellaLength }, { ...IncomingPackage.fields.thickness }, { ...IncomingPackage.fields.width }],
      [{ ...IncomingPackage.fields.quality }, { ...IncomingPackage.fields.woodType }, { ...IncomingPackage.fields.strengthString }]
    ]
  ],

  projectFields: [[[{ ...Project.fields.getThickness }]]],

  bshorderFields: [
    /* [
      { ...BSHOrder.fields.orderPicture }
    ], */
    [
      // { ...BSHOrder.fields.quality },
      { ...BSHOrder.fields.client },
      { ...BSHOrder.fields.lamellaLength },
      { ...BSHOrder.fields.lamellaWidth },
      { ...BSHOrder.fields.lamellaThickness },
      { ...BSHOrder.fields.rawThickness },
      { ...BSHOrder.fields.rawWidth },
      { ...BSHOrder.fields.stackingNumOfLayers },
      { ...BSHOrder.fields.stackingLamellasPerLayer },
      { ...BSHOrder.fields.numOfLamellas }
    ]
  ],

  beamFields: [
    [
      [{ ...Order.fields.orderPicture }],
      [
        { ...Order.fields.projectNumber },
        { ...Order.fields.projectName },
        { ...Order.fields.masterpanel },
        { ...Order.fields.nestingPanelNumber },
        { ...Order.fields.panelMaterial },
        { ...Order.fields.panelLength },
        { ...Order.fields.beamWidth },
        { ...Order.fields.panelThickness },
        { ...Order.fields.numberOfBeams },
        { ...Order.fields.chamfer }
      ]
    ]
  ],

  beamLayerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, halfsize: true },
        { ...Layer.fields.quality, width: 1.2, halfsize: true },
        { ...Layer.fields.strengthString, width: 1.2, halfsize: true },
        { ...Layer.fields.woodType, width: 1.2, halfsize: true },
        // { ...Layer.fields.treatment, width: 1.2, halfsize: true },
        { ...Layer.fields.material, width: 1.2, halfsize: true }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidthNoLimits, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLengthNoLimits, width: 2 },
        { ...Layer.fields.numOfLamellasEditable_noCondition, width: 2 }
        // { ...Layer.fields.minTakeAway, width: 1, halfsize: true },
        // { ...Layer.fields.maxTakeaway, width: 1, halfsize: true }
      ]
    ]
  ],

  layerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, halfsize: true },
        { ...Layer.fields.quality, width: 1.2, halfsize: true },
        { ...Layer.fields.strengthString, width: 1.2, halfsize: true },
        { ...Layer.fields.woodType, width: 1.2, halfsize: true },
        // { ...Layer.fields.treatment, width: 1.2, halfsize: true },
        { ...Layer.fields.material, width: 1.2, halfsize: true },
        { ...Layer.fields.orientation, width: 1.2, halfsize: true }
        // { ...Layer.fields.rotation, width: 1.2, halfsize: true }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLength, width: 2 },
        { ...Layer.fields.numOfLamellas, width: 2 },
        { ...Layer.fields.minTakeAway, width: 1, halfsize: true },
        { ...Layer.fields.maxTakeaway, width: 1, halfsize: true }
      ]
    ],
    [[{ ...Layer.fields.layerPicture }]]
  ],

  recipeLayerFields: [
    [
      [
        { ...RecipeLayer.fields.layerNo, width: 1.2 },
        { ...RecipeLayer.fields.woodType, width: 1.2 },
        { ...RecipeLayer.fields.orientation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.rotation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.quality, width: 1.2 }
      ],
      [
        { ...RecipeLayer.fields.lamellaPlannedThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaPlannedWidth, width: 2 },
        { ...RecipeLayer.fields.minTakeAway, width: 2 }
      ],
      [
        { ...RecipeLayer.fields.hasGlue, width: 1.2 },
        { ...RecipeLayer.fields.whiteGlue, width: 1.2 }
      ]
    ]
  ],

  orderWithRecipeFields: [
    [
      [{ ...Order.fields.recipeSelect }, { ...Recipe.fields.getOrderWithRecipeImage }],
      [{ ...Recipe.fields.identifier }, { ...Order.fields.orderNumber }, { ...Order.fields.panelWidth }, { ...Order.fields.panelLength }]
    ]
  ],

  erpFields: [
    [
      [{ ...Erp.fields.actualRawWidth }, { ...Erp.fields.nominalWidth }, { ...Erp.fields.actualRawThickness }, { ...Erp.fields.nominalThickness }],
      [{ ...Erp.fields.minPlaningOffset }, { ...Erp.fields.maxPlaningOffset }, { ...Erp.fields.plannedThicknessArboreal }]
    ]
  ],

  /* IF project needs custom validator or calculator for models */
  calculatorsConfig: {
    cltPackage: {
      modelCalculator: arborealMaterialCodeCalculator
    }
  }
};

export default arboreal_config;
