/**
 *
 * MicrotecBox
 *
 */

import { useState } from 'react';

import Wrapper from './Wrapper';
import Button from '../../../Button';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import StatusItem from '../../../StatusItem';
import _ from 'lodash';
import { GoldenEyeIcon } from '../../../reusable/Icons';

import DModal from '../../../common/DialogModal/Modal';
import ControlModal from '../../../../containers/EditControlModal/index';

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

export function MicrotecBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  // const history = useHistory();

  const [isModal1Opened, setIsModal1Opened] = useState(false);

  function toggleModal(string) {
    if (string === 'golden-eye-test') {
      setIsModal1Opened(!isModal1Opened);
      return;
    }

    setIsModal1Opened(false);
  }

  const getLastBoard = () => {
    return <div></div>;
    /* return (
      <div className="chart">
        <div style={{width: '25%', backgroundColor: 'red'}}>L</div>
        <div style={{width: '25%', backgroundColor: 'green'}}>L</div>
        <div style={{width: '25%', backgroundColor: 'red'}}>L</div>
        <div style={{width: '25%', backgroundColor: 'green'}}>L</div>
      </div>
    ); */
  };

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo>A13 | Goldeneye</SubInfo>
      </Paper>
      <br />
      <DataInfo>
        <StatusItem item={_.get(props, 'status.goldenEyeConnected', null)} type="green">
          Goldeneye connected
        </StatusItem>
        <span style={{ fontSize: '12px' }}>{getLastBoard()}</span>
        <span style={{ float: 'right' }}>
          <Button onClick={() => setIsModal1Opened(true)}>
            <GoldenEyeIcon style={{ width: '1.2rem', height: '1.2rem' }} />
            {/* Golden eye data */}
          </Button>
          <DModal id={'golden-eye-test'} target={'golden-eye-test'} isOpened={isModal1Opened} onClose={toggleModal}>
            <ControlModal
              action={'golden-eye-test'}
              screenstyle={'small'}
              style={{
                position: 'absolute',
                width: '50%',
                height: '60%',
                left: '25%',
                top: '15%',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '2300'
              }}
              close={toggleModal}
            />
          </DModal>
        </span>
      </DataInfo>
    </Wrapper>
  );
}

MicrotecBox.propTypes = {};

export default MicrotecBox;
