/*
 ** EditView define fields for editview reducer
 */
import { BSHOrder, BSHLayer, Layer, IncomingPackage, PlanerSetup } from '../../../utils/models';
import { smartlamPlanerSetupCalculator } from '../../../utils/modelFunctions/calculators';

const smartlam_config = {
  incomingPackageFields: [
    [
      [
        { ...IncomingPackage.fields.identifier },
        { ...IncomingPackage.fields.pieces },
        { ...IncomingPackage.fields.quality },
        { ...IncomingPackage.fields.woodType },
        { ...IncomingPackage.fields.thickness },
        { ...IncomingPackage.fields.width },
        { ...IncomingPackage.fields.lamellaLength },
        { ...IncomingPackage.fields.packageNumber },
        { ...IncomingPackage.fields.piecesPerLayer },
        { ...IncomingPackage.fields.packageNumber }
      ]
    ]
  ],
  bshorderFields: [
    [
      [
        { ...BSHOrder.fields.erp_order_id, width: 2 },
        { ...BSHOrder.fields.assignedPress },
        { ...BSHOrder.fields.orderNumber },
        { ...BSHOrder.fields.orderImportNumber },
        { ...BSHOrder.fields.finalBeamThickness }
      ],
      [{ ...BSHOrder.fields.stagger }]
    ],
    [[{ ...BSHOrder.fields.beamPicture }]]
  ],
  bshLayerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, editable: false }, // positionInStack
        { ...Layer.fields.layer_erp_id, width: 1.2 },
        { ...Layer.fields.numOfLamellas },
        { ...Layer.fields.totalNumOfLamellas }
      ],
      [
        { ...Layer.fields.lamellaThickness },
        { ...Layer.fields.singleLamellaThickness },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true, editable: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLength_schneider, width: 2 },
        { ...BSHLayer.fields.bottomHeadTakeAway, width: 2, editable: true }
      ],
      [{ ...BSHLayer.fields.bottomTension }, { ...BSHLayer.fields.bottomN1D }, { ...BSHLayer.fields.coreN2M }, { ...BSHLayer.fields.topN1D }, { ...BSHLayer.fields.topTension }]
    ],
    [[{ ...BSHLayer.fields.beams, width: 1, editable: false }], [{ ...BSHLayer.fields.beamWidth, width: 1, editable: false }]],
    [[{ ...BSHLayer.fields.beamPicture, halfsize: false }]]
  ],
  planerSetupFields: [
    [
      [
        { ...PlanerSetup.fields.imperialReportedWidth },
        { ...PlanerSetup.fields.imperialRawWidth },
        { ...PlanerSetup.fields.imperialReportedThickness },
        { ...PlanerSetup.fields.imperialRawThickness }
      ],
      [{ ...PlanerSetup.fields.imperialLamellaPlannedWidth }, { ...PlanerSetup.fields.imperialW1Takeaway }, { ...PlanerSetup.fields.imperialW3Takeaway }]
    ]
  ],
  calculatorsConfig: {
    planerSetup: {
      modelCalculator: smartlamPlanerSetupCalculator
    }
  },
  otherOptions: {
    planerSetup: {
      title: {
        str: '{reportedWidth} x {reportedThickness}',
        keys: {
          reportedWidth: '{reportedWidth}',
          reportedThickness: '{reportedThickness}'
        }
      }
    }
  }
};

export default smartlam_config;
