/**
 *
 * ZincBox
 *
 */

import { useState } from 'react';

import Wrapper from './Wrapper';
import { useHistory } from 'react-router-dom';
import Button from '../../../Button';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import _ from 'lodash';
import FormattedMessage from '../../../TranslationWrapper';

import InlineWrapper from '../../../reusable/InlineWrapper';
import Saw from './Saw.svg';
import Plank from './Plank';
import VerticallyAlignedText from '../../../reusable/VerticallyAlignedText';
import { useDispatch } from 'react-redux';
import { MoreIcon, SendSignalIcon, SettingsIcon, SortingIcon } from '../../../reusable/Icons';
import DModal from '../../../common/DialogModal/Modal';
import ControlModal from '../../../../containers/EditControlModal/index';
import globalMessages from '../../../../globalMessages';
import { sendToWs } from '../../../../containers/Websocket/actions';

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

const TextField = styled.div`
  float: left;
  margin: 5px;
  font-weight: bold;
  font-size: smaller;
`;

const BoxField = styled.div`
  padding: 3px;
  font-size: smaller;
`;

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const Bold = styled.div`
  //font-size: small;
  font-weight: bold;
`;

export function ZincBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  // console.log('ZincBox: ', props);

  const [isModal1Opened, setIsModal1Opened] = useState(false);

  function toggleModal(string) {
    if (string === 'set-zink') {
      setIsModal1Opened(!isModal1Opened);
      return;
    }

    setIsModal1Opened(false);
  }

  let orderColor = null;
  if (_.get(props, 'status.producing', null) != null) {
    orderColor = props.status.producing.orderColor;
  }

  // console.log('orderColor: ', orderColor);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo>A51 | Zink</SubInfo>
      </Paper>
      <br />
      <div style={{ height: '100%' }}>
        <InlineWrapper>
          <div style={{ width: '60%' }}>
            {_.get(props, 'testLamellaNeededa', null)}
            <br />
            {/* {_.get(props, 'status.producing', null) != null && <span><Bold style={{ display: 'inline-block' }}><Paper elevation={1}><BoxField>{props.status.producing.lamellaWidth} x {props.status.producing.lamellaThickness} x {props.status.producing.lamellaLength}</BoxField></Paper></Bold> mm</span>} */}
          </div>
          <div style={{ width: '20%' }}>
            <img className={`${true ? 'rotate' : ''}`} src={Saw} alt="Saw" />
          </div>
        </InlineWrapper>
        <span style={{ float: 'right', marginTop: '25px', marginBottom: '10px', marginRight: '10px' }}>
          <Button onClick={() => setIsModal1Opened(true)}>
            <SettingsIcon style={{ width: '1.2rem', height: '1.2rem' }} />
            {/* Set drying */}
          </Button>

          <DModal id={'set-zink'} target={'set-zink'} isOpened={isModal1Opened} onClose={toggleModal}>
            <ControlModal
              action={'set-zink'}
              screenstyle={'small'}
              style={{
                position: 'absolute',
                width: '50%',
                height: '80%',
                left: '25%',
                top: '15%',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '2300'
              }}
              close={toggleModal}
            />
          </DModal>
        </span>
        <Plank
          // style={{backgroundColor: orderColor, opacity: 0.8}}
          className={`${_.get(props, 'producingTestLamella', null) ? 'test' : ''}`}>
          {_.get(props, 'status.producing', null) != null && (
            <span>
              <Bold style={{ display: 'inline-block' }}>
                <BoxField style={{ color: 'white', marginLeft: '2px' }}>
                  {props.status.producing.lamellaWidth} x {props.status.producing.lamellaThickness} x {props.status.producing.lamellaLength} mm
                </BoxField>
              </Bold>{' '}
            </span>
          )}
        </Plank>
        <DataInfo>
          <span>
            <TextField>
              <FormattedMessage {...globalMessages.order} />:
            </TextField>
          </span>
          {_.get(props, 'status.producing', null) != null ? (
            <span>
              <Paper elevation={1}>
                <BoxField style={{ color: orderColor }}>
                  {props.status.producing.id} | {props.status.producing.type} | {props.status.producing.quality}
                </BoxField>
              </Paper>
            </span>
          ) : (
            <b></b>
          )}
        </DataInfo>
        <VerticallyAlignedText height="15%">
          <DataInfo>
            <span>
              <SortingIcon style={{ float: 'left', margin: '2px' }} />
            </span>
            <span>
              <TextField>
                {' '}
                <FormattedMessage {...globalMessages.lamellas} identifier={'lamellas'} />:
              </TextField>
            </span>
            {_.get(props, 'status.producing', null) && (
              <span style={{ float: 'right', margin: '2px' }}>
                <Paper elevation={1}>
                  <BoxField>
                    {' '}
                    {props.status.producing.zincDoneNumLamellas} / {props.status.producing.numLamellas}
                  </BoxField>
                </Paper>{' '}
              </span>
            )}
          </DataInfo>
        </VerticallyAlignedText>
        <div style={{ position: 'absolute', width: '100%' }}>
          <Button onClick={() => dispatch(sendToWs({}, 'resend-zinc-data'))}>
            <SendSignalIcon /> Resend Zink data
          </Button>
        </div>
        <div style={{ position: 'absolute', width: '100%', bottom: '60px' }}>
          <Button
            onClick={() =>
              history.push({
                pathname: '/zinc',
                props: props.status
              })
            }>
            <MoreIcon style={{ width: '2rem', height: '2rem' }} />
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

ZincBox.propTypes = {};

export default ZincBox;
