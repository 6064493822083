/*
 ** ProductionLine
 */
import AllProductionComponents from '../../../components/line/sepa/allcomponentsexport';

/* Components which are supposed to be the the same along all projects */
import CommonComponentsExport from '../../../components/line/common/commoncomponentsexport';

const sepa_config = {
  /* Define Boxes to be shown in production line screen - Dashboard */
  components: [
    [
      /* Page 1 */
      { RawInfeedLoadingBox: { title: 'vacuumTitle', componentPath: 'importedcomponents' } },
      {
        SplanBox: {
          subtitle: 'SuperPlan Info',
          title: 'planerTitle',
          componentPath: 'importedcomponents'
        }
      },
      { AccumulatorBox: { title: 'accuTitle', componentPath: 'importedcomponents' } },
      { StackingBox: { title: 'productStacking', componentPath: 'importedcomponents' } }
    ],

    [
      /* Page 2 */
    ]
  ],

  /* Importing all components needed from project unique subfolder with components */
  importedcomponents: AllProductionComponents,
  commoncomponents: CommonComponentsExport
};

export default sepa_config;
