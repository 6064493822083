import styled from 'styled-components';

const BoxLights = styled.div`
  border-right: 1px solid #ddd;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 6px;
  width: auto;
`;
export default BoxLights;
