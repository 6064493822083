/*
 ** EditView sodra define fields for editview reducer
 */
import { BSHOrder, Order, Layer, RecipeLayer, Recipe, IncomingPackage, Project, Erp, PlanerSetup } from '../../../utils/models';

const mosser_config = {
  orderFields: [
    [
      [{ ...Order.fields.orderPicture }],
      [{ ...Order.fields.orderNumber }, { ...Order.fields.mosserPanelWidth }, { ...Order.fields.mosserPanelLength }, { ...Order.fields.panelThickness }, { ...Order.fields.panelsNumber }]
    ],
    // ]
    [[{ ...Order.fields.panelPicture }]]
  ],

  recipeOrderFields: [
    [
      [{ ...Recipe.fields.orderPicture }],
      [
        { ...Recipe.fields.identifier },
        { ...Order.fields.panelThickness },
        { ...Order.fields.oversize, halfsize: true, editable: true },
        { ...Order.fields.lenOversizeL, halfsize: true, editable: true },
        { ...Order.fields.oversizeWidth, halfsize: true },
        { ...Order.fields.lenOversizeC, halfsize: true }
      ]
    ]
  ],

  incomingPackageFields: [
    // [
    //   { ...Order.fields.orderPicture }
    // ],
    [
      [
        // { ...IncomingPackage.fields.id},
        { ...IncomingPackage.fields.identifier },
        // { ...IncomingPackage.fields.quality},
        // { ...IncomingPackage.fields.woodType},
        { ...IncomingPackage.fields.thickness },
        { ...IncomingPackage.fields.width },
        { ...IncomingPackage.fields.averageLength },
        // { ...IncomingPackage.fields.lotNumber},
        { ...IncomingPackage.fields.packageNumber },
        { ...IncomingPackage.fields.piecesPerLayer }
      ]
    ]
  ],

  bshorderFields: [
    /* [
      { ...BSHOrder.fields.orderPicture }
    ], */
    [
      // { ...BSHOrder.fields.quality },
      { ...BSHOrder.fields.client },
      { ...BSHOrder.fields.lamellaLength },
      { ...BSHOrder.fields.lamellaWidth },
      { ...BSHOrder.fields.lamellaThickness },
      { ...BSHOrder.fields.rawThickness },
      { ...BSHOrder.fields.rawWidth },
      { ...BSHOrder.fields.stackingNumOfLayers },
      { ...BSHOrder.fields.stackingLamellasPerLayer },
      { ...BSHOrder.fields.numOfLamellas }
    ]
  ],

  layerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, editable: false },
        { ...Layer.fields.qualityChoices, width: 1.2, halfsize: true },
        { ...Layer.fields.quality, width: 1.2, halfsize: true },
        { ...Layer.fields.orientation, width: 1.2, halfsize: true },
        { ...Layer.fields.rotation, width: 1.2, halfsize: true }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLength, width: 2 },
        { ...Layer.fields.numOfLamellas, width: 2 },
        { ...Layer.fields.minTakeAway, width: 1, halfsize: true },
        { ...Layer.fields.maxTakeaway, width: 1, halfsize: true }
      ]
    ],
    [[{ ...Layer.fields.layerPicture }]]
  ],

  recipeLayerFields: [
    [
      [
        { ...RecipeLayer.fields.layerNo, width: 1.2 },
        { ...RecipeLayer.fields.woodType, width: 1.2 },
        { ...RecipeLayer.fields.orientation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.rotation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.quality, width: 1.2 }
      ],
      [
        { ...RecipeLayer.fields.lamellaPlannedThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaPlannedWidth, width: 2 },
        { ...RecipeLayer.fields.minTakeAway, width: 2 }
      ],
      [
        { ...RecipeLayer.fields.hasGlue, width: 1.2 },
        { ...RecipeLayer.fields.whiteGlue, width: 1.2 }
      ]
    ]
  ],

  erpFields: [
    [
      [
        { ...Erp.fields.identifierCode },
        { ...Erp.fields.sidePlanning },
        { ...Erp.fields.underChamfer },
        { ...Erp.fields.upperChamfer },
        { ...Erp.fields.rightMilling },
        { ...Erp.fields.leftMilling },
        { ...Erp.fields.sanding }
      ]
    ]
  ],

  orderWithRecipeFields: [
    [
      [{ ...Order.fields.recipeSelect }, { ...Recipe.fields.getOrderWithRecipeImage }],
      [{ ...Recipe.fields.identifier }, { ...Order.fields.orderNumber }, { ...Order.fields.mosserPanelWidth }, { ...Order.fields.mosserPanelLength }, { ...Order.fields.panelsNumber }]
    ]
  ],

  projectFields: [[[{ ...Project.fields.extraQOversize }, { ...Project.fields.extraLOversize }, { ...Project.fields.description }]]],

  planerSetupFields: [[[{ ...PlanerSetup.fields.quality }, { ...PlanerSetup.fields.mosserPlanerTakeaway }]]]
};

export default mosser_config;
