/*
 *
 * ErrorListModal actions
 *
 */

import { DEFAULT_ACTION, SET_HISTORY_STATE, SOLUTION_OPTIONS_RECIEVED, SEND_FIRST_MESSAGE_DATA, RESET_STATE, SEND_SELECTED_RESPONSE, LAST_RESPONSE_RECIEVED } from './constants';
import { uuidv4 } from '../../utils/helpers';

export const sendFirstMessageData = (message) => {
  const id = uuidv4();
  const payloadData = {
    ...message,
    uuid: id
  };
  return {
    type: SEND_FIRST_MESSAGE_DATA,
    payload: {
      type: 'problem-with-data',
      data: payloadData
    }
  };
};

export const sendSelectedResponse = (message) => {
  return {
    type: SEND_SELECTED_RESPONSE,
    payload: {
      type: 'selected-response',
      data: message
    }
  };
};

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export const solutionOptionsRecieved = (solutions, uuid) => {
  console.log('SOLUTIONS OPTIONS RECIEVED ACTION CALL: ', solutions, uuid);
  return {
    type: SOLUTION_OPTIONS_RECIEVED,
    payload: {
      solutions,
      uuid
    }
  };
};

export const lastResponseRecieved = (lastResponse, uuid) => {
  return {
    type: LAST_RESPONSE_RECIEVED,
    payload: {
      lastResponse,
      uuid
    }
  };
};

export const resetProblemState = () => {
  return {
    type: RESET_STATE
  };
};

export const setHistoryState = (data) => {
  return {
    type: SET_HISTORY_STATE,
    payload: data
  };
};
