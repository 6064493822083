import ReactDOM from 'react-dom';
import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';
import '../Modal/modal.css';

const modalRoot = document.getElementById('portalDialogModal');

const Modal = ({ isOpened, children, onClose }, props) => {
  const [shouldRender, setShouldRender] = useState(isOpened);
  const [animationClass, setAnimationClass] = useState('modal-enter');
  const [overlayAnimationClass, setOverlayAnimationClass] = useState('overlay-enter');

  useEffect(() => {
    if (isOpened) {
      setAnimationClass('modal-enter');
      setOverlayAnimationClass('overlay-enter');
      setShouldRender(true);
    } else {
      setAnimationClass('modal-exit');
      setOverlayAnimationClass('overlay-exit');
      setTimeout(() => {
        setShouldRender(false);
      }, 600); // Duration of exit animation
    }
  }, [isOpened]);

  if (!shouldRender) {
    return null;
  }

  return createPortal(
    <div className={`modalContainerForAnimation`}>
      <div className={`overlay ${overlayAnimationClass}`}></div>
      <div className={`modall ${animationClass}`}>{children}</div>
    </div>,
    modalRoot
  );
};

export default Modal;
