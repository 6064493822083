/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  SET_PROJECT_CONFIG,
  SET_ARCHIVE_MODE,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  CHANGE_FORM,
  REQUEST_ERROR,
  CLEAR_ERROR,
  SENDING_REQUEST,
  SET_AUTH,
  SHOW_KEYBOARD,
  SET_INPUT_KEYBOARD,
  SET_UPPERCASE_KEYBOARD,
  SET_SETTINGS,
  SET_ARCHIVE_MODE_DATE
} from './constants';

/**
 * Dispatched when App is loaded and gets environment variable settings
 */
export function setProjectConfig(project_config) {
  return {
    type: SET_PROJECT_CONFIG,
    project_config
  };
}

/**
 * Dispatched when App is loaded and gets environment variable settings
 */
export function setArchiveMode(archiveMode) {
  // console.log('setArchiveMode: ', archiveMode);
  return {
    type: SET_ARCHIVE_MODE,
    archiveMode
  };
}

export function setArchiveModeDate(archiveModeDate) {
  return {
    type: SET_ARCHIVE_MODE_DATE,
    archiveModeDate
  };
}

/* Login */

export function loginRequest(data) {
  return {
    type: LOGIN_REQUEST,
    data
  };
}

export function requestError(error) {
  return { type: REQUEST_ERROR, error };
}

export function clearError() {
  return { type: CLEAR_ERROR };
}

export function sendingRequest(sending) {
  return { type: SENDING_REQUEST, sending };
}

export function setAuthState(newAuthState, user) {
  return { type: SET_AUTH, newAuthState, user };
}

export function changeForm(newFormState) {
  return { type: CHANGE_FORM, newFormState };
}

export function logout() {
  return { type: LOGOUT_REQUEST };
}

export function showKeyboard(showKeyboard) {
  return {
    type: SHOW_KEYBOARD,
    showKeyboard
  };
}

export function uppercaseKeyboard(uppercase) {
  return {
    type: SET_UPPERCASE_KEYBOARD,
    uppercase
  };
}

export function sendInputKeyboard(input) {
  return {
    type: SET_INPUT_KEYBOARD,
    input
  };
}

export function setSettings(settings) {
  return {
    type: SET_SETTINGS,
    settings
  };
}
