/**
 *
 * LineBox
 *
 */

import Wrapper from './Wrapper';
import PropTypes from 'prop-types';

const LineBox = (props) => {
  // eslint-disable-line react/prefer-stateless-function
  return (
    <Wrapper style={{ borderBottomColor: props.areacolor, height: props?.maxHeight }} className={''}>
      {props.children}
    </Wrapper>
  );
};

LineBox.propTypes = {
  type: PropTypes.string,
  ident: PropTypes.string,
  arrow: PropTypes.string,
  arrowIn: PropTypes.string,
  className: PropTypes.string
};

export default LineBox;
