/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';

const KeyboardWrapper = styled.div`
  .keyboard {
    width: 100%;
    max-width: 1030px;
    margin: 0 auto;
    background: #dadada;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.5);
  }

  .keyboardVisible {
    visibility: hidden;
  }

  /* XLAM DISPLAY - display keyboard on terminal aspect ratio (ratio čejnđes a bit when screen is zoomed out/in)*/
  @media (min-aspect-ratio: 45/40) and (max-aspect-ratio: 55/40) {
    .keyboardVisible {
      visibility: visible;
    }
  }

  .keyboard-row {
    display: flex;
  }

  .keyboard-keysSet {
    display: flex;
    flex-grow: 1;
  }

  .keyboard-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-basis: 100px;
    font-size: 18px;
    height: 50px;
    border-radius: 4px;
    background-color: #f5f5f5;
    border: 1px solid #cecece;
    font-family: 'Roboto';
    font-weight: 300;
    color: #000000;
  }

  .keyboard-button:focus {
    outline: none;
  }

  .keyboard-button:disabled {
    opacity: 0.4;
    cursor: default;
  }

  .keyboard-button:active {
    background-color: #cccccc;
  }

  .keyboard-numberButton {
    flex-grow: 1;
  }

  .keyboard-halfButton {
    flex-basis: 56px;
  }

  .keyboard-space {
    flex-grow: 1;
  }

  .keyboard-utilButton {
    flex-grow: 2;
    max-width: 55px;
  }

  .keyboard-additionalButton {
    flex-basis: 128px;
  }

  .keyboard-submit-button {
    flex-basis: 95px;
    background-color: #63b324;
    border-color: #63b324;
    color: #ffffff;
  }

  .keyboard-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 2000000;
    width: 700px;
    left: calc(50% - 350px);
  }

  .vnc-fill {
    position: fixed;
    bottom: 0;
    z-index: 10000;
    width: 90%;
  }

  .keyboard-button.shift-symbols {
    //background:#00000033;
    //color:white;
    flex-basis: 210px;
  }
`;

export default KeyboardWrapper;
