/*
 ** EditView bruno define fields for editview reducer
 */
import { BSHOrder, IncomingPackage } from '../../../utils/models';

const bruno_config = {
  incomingPackageFields: [
    [
      [
        // { ...IncomingPackage.fields.id},
        { ...IncomingPackage.fields.identifier },
        // { ...IncomingPackage.fields.quality},
        { ...IncomingPackage.fields.averageLength },
        { ...IncomingPackage.fields.thickness },
        { ...IncomingPackage.fields.width }
      ],
      [
        { ...IncomingPackage.fields.lamellaLength },
        { ...IncomingPackage.fields.pieces },
        { ...IncomingPackage.fields.packageNumber }
        // { ...IncomingPackage.fields.piecesPerLayer},
        // { ...IncomingPackage.fields.numberOfLamellas},
      ]
    ]
  ],

  bshorderFields: [
    [
      [
        { ...BSHOrder.fields.orderNumber },
        { ...BSHOrder.fields.client },
        { ...BSHOrder.fields.orderType },
        { ...BSHOrder.fields.lamellaLength },
        { ...BSHOrder.fields.numOfLamellas },
        { ...BSHOrder.fields.lamellaWidth },
        { ...BSHOrder.fields.rawWidth },
        { ...BSHOrder.fields.lamellaThickness },
        { ...BSHOrder.fields.rawThickness },
        { ...BSHOrder.fields.stackingNumOfLayers },
        { ...BSHOrder.fields.stackingLamellasPerLayer },
        { ...BSHOrder.fields.quality },
        { ...BSHOrder.fields.woodType },
        { ...BSHOrder.fields.wrapping },
        { ...BSHOrder.fields.stopper }
      ]
    ]
  ]
};

export default bruno_config;
