const solis_edit_settings = [
  {
    //Loaded Packages Edit
    'set-editpackage': {
      settings: [],
      cherryPick: ['lamellaLength', 'lamellaWidth', 'lamellaThickness', 'numberOfLamellas', 'numberOfLayers', 'piecesPerLayer']
    }
  }
];

export default solis_edit_settings;
