import { ADDMODAL, POPMODAL } from './constants';

export const initialState = {
  modal: [],
  submodalInfo: {}
};

const modalManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDMODAL:
      // console.log('state.modal: ', state.modal);
      if (state.modal.includes(action.modal)) {
        // for now presume something went wrong and POP the last modal, makeshift fix for now
        state.modal = state.modal.filter((e) => e !== action.modal);
        return { modal: state.modal, submodalInfo: state.submodalInfo };
      }

      if (!state.modal.includes(action.modal)) {
        state.modal.push(action.modal);
        state.submodalInfo[action.modal] = {
          subModal: action.submodal,
          data: action.data,
          callback: action.callback
        };
      }
      return { modal: state.modal, submodalInfo: state.submodalInfo };
    case POPMODAL:
      state.modal = state.modal.filter((e) => e !== action.modal);
      return { modal: state.modal, submodalInfo: state.submodalInfo };
    default:
      return state;
  }
};

export default modalManagerReducer;
