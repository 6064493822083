import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid #b0b1b2;
  height: 22.3%;
  margin-bottom: 5%;
  display: table;
  width: 80%;
  word-break: break-all;
  font-size: 14px;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  color: white;
  text-align: center;
  background-color: #3c4b64;
  border-radius: 4px;
  box-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.3);

  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;

  &.dotted {
    border: 1px dashed #b0b1b2;
  }

  line-height: 12px;

  &.beingLoaded {
    border: 3px solid #84d300;
  }

  &.yellow {
    background-color: #f5f5cc;
  }

  &.bold {
    border: 2px solid #f7c315;
    background: #f7c315;
  }

  &.loading {
    background-color: #84d300;
    color: black;
  }

  &.error {
    background-color: #ff0000;
    color: black;
  }

  &.green {
    border: 2px solid #97d315;
  }

  &.red {
    border: 2px solid red;
  }

  &.dummy {
    border: 0;
  }

  svg {
    font-size: 23px;
    margin-left: 10px;
    margin-right: 7px;
    color: #84d300;
    float: right;
  }

  position: relative;

  &.bereichEin:after,
  &.bereichAus:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 5px;
    background-color: #b0b1b2;
    position: absolute;
    right: 2px;
    top: 2px;
  }

  &.bereichEin:after {
    background-color: #00c1f9;
  }
`;

export default Wrapper;
