/**
 *
 * AccumulatorBox
 *
 */

import { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SubInfo, DataInfoInline } from '../../arboreal/LineBoxWrapper';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ChartView from '../../../ChartView';
import { LineChartIcon } from '../../../reusable/Icons';
import Wrapper from './Wrapper.js';
import FormattedMessage from '../../../TranslationWrapper';
import globalMessages from '../../../../globalMessages';

export function ZincChart(props) {
  // eslint-disable-line react/prefer-stateless-function

  const productionstatus = _.get(props, 'productionstatus', null);

  // const history = useHistory();

  // [{time: 0, value: 15}, {time: 1, value: 18},
  //   {time: 2, value: 10}, {time: 3, value: 22}]

  const [controlData, setControlData] = useState([]);

  useEffect(() => {
    // setControlData([controlData.push(cdata)])
    if (props?.productionstatus?.cumulativeWoodChart != undefined) {
      setControlData(props.productionstatus.cumulativeWoodChart);
    }
  }, [props]);

  // console.log('controlData: ', controlData);
  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo>
          <FormattedMessage {...globalMessages.materialStatus} />
        </SubInfo>
      </Paper>
      <br />
      <DataInfoInline>
        <LineChartIcon />
        <span style={{ fontSize: '10px', marginLeft: '5px' }}>
          <FormattedMessage {...globalMessages.materialNeededText} />
        </span>
      </DataInfoInline>
      <div style={{ height: '2vh' }}></div>
      <div
        style={{
          height: '200px',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '99%'
        }}>
        <ChartView data={controlData} />
      </div>
    </Wrapper>
  );
}

ZincChart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  productionstatus: PropTypes.object
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ZincChart);
