/*  TEST LOGIN ACCES */

/* ROLES from loggedin user privilege level */
export const ROLES = {
  SUPER_ADMIN: 3,
  ADMIN: 2,
  CUSTOMER: 1,
  EMPLOYEE: 0,
  LOGOUT: -1
};

export const PERMISSIONS = {
  CAN_ACCES_ROUTE: 1000,
  CAN_EDIT_ORDER: 1001,
  CAN_EDIT_JDQUEUE: 1002,
  CAN_ACCESS_SIDEBAR: 1003,
  CAN_MODIFY_USERS: 1004,
  CAN_MODIFY_GLOBAL_SETTINGS: 1005,
  CAN_ACCESS_ARCHIVE_MODE: 1006,
  CAN_ACCESS_HEADER: 1007,
  CAN_ACCESS_PLC_VNC: 1008,
  CAN_CHERRYPICK: 1009,
  CAN_VIEW_COMM: 1010,
  CAN_SEND_NOTIFICATION: 1011,
  CAN_LOAD_LINESTATUS: 1012,
  CAN_CHANGE_UNIT_SYSTEM: 1012
};

export const getPermissions = {
  [ROLES.SUPER_ADMIN]: [
    PERMISSIONS.CAN_EDIT_JDQUEUE,
    PERMISSIONS.CAN_ACCES_ROUTE,
    PERMISSIONS.CAN_ACCESS_ARCHIVE_MODE,
    PERMISSIONS.CAN_EDIT_ORDER,
    PERMISSIONS.CAN_VIEW_COMM,
    PERMISSIONS.CAN_ACCESS_SIDEBAR,
    PERMISSIONS.CAN_CHERRYPICK,
    PERMISSIONS.CAN_ACCESS_PLC_VNC,
    PERMISSIONS.CAN_ACCESS_HEADER,
    PERMISSIONS.CAN_MODIFY_GLOBAL_SETTINGS,
    PERMISSIONS.CAN_SEND_NOTIFICATION,
    PERMISSIONS.CAN_LOAD_LINESTATUS,
    PERMISSIONS.CAN_CHANGE_UNIT_SYSTEM
  ],
  [ROLES.ADMIN]: [
    PERMISSIONS.CAN_EDIT_ORDER,
    PERMISSIONS.CAN_ACCESS_ARCHIVE_MODE,
    PERMISSIONS.CAN_ACCES_ROUTE,
    PERMISSIONS.CAN_ACCESS_SIDEBAR,
    PERMISSIONS.CAN_CHERRYPICK,
    PERMISSIONS.CAN_ACCESS_PLC_VNC,
    PERMISSIONS.CAN_ACCESS_HEADER,
    PERMISSIONS.CAN_MODIFY_GLOBAL_SETTINGS,
    PERMISSIONS.CAN_CHANGE_UNIT_SYSTEM
  ],
  [ROLES.CUSTOMER]: [PERMISSIONS.CAN_MODIFY_GLOBAL_SETTINGS],
  [ROLES.EMPLOYEE]: [PERMISSIONS.CAN_MODIFY_GLOBAL_SETTINGS, PERMISSIONS.CAN_MODIFY_USERS],
  [ROLES.LOGOUT]: []
};
