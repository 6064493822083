/**
 *
 * SuperplanBox
 *
 */

import { useState } from 'react';
import Wrapper from './Wrapper';
import styled from 'styled-components';
import './responsive_style.css';
import Paper from '@material-ui/core/Paper';
import LineMoreContainer from '../LineMoreContainer';
import Button from '../../../Button';
import _ from 'lodash';
import { SettingsIcon, ThicknessIcon, WidthIcon, PackageIcon } from '../../../reusable/Icons';
import FormattedMessage from '../../../TranslationWrapper';

import DModal from '../../../common/DialogModal/Modal';
import globalMessages from '../../../../globalMessages';
import ControlModal from '../../../../containers/EditControlModal/index';

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

const TextField = styled.div`
  float: left;
  margin: 3px;
  font-weight: bold;
  font-size: 10px;
`;

const BoxField = styled.div`
  padding: 3px;
  font-size: smaller;
`;

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

export function SuperplanBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  // const history = useHistory();
  // const action = props.controlaction;
  // const title = props.message;

  const [isModal1Opened, setIsModal1Opened] = useState(false);

  function toggleModal(string) {
    if (string === 'set-infeed') {
      setIsModal1Opened(!isModal1Opened);
      return;
    }

    setIsModal1Opened(false);
  }

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> A11 | Superplan </SubInfo>
      </Paper>
      <br />
      <DataInfo>
        <span>
          <WidthIcon style={{ float: 'left', margin: '2px', height: '12px' }} />
        </span>
        <span>
          <TextField>
            <FormattedMessage {...globalMessages.rawWidthSuperplan} />
          </TextField>
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedWidth', 0) + _.get(props, 'status.sortingInfeedWidthOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedWidthOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedWidth', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <br />
        <br />
        <span>
          <ThicknessIcon style={{ float: 'left', margin: '2px', height: '12px' }} />
        </span>
        <span>
          <TextField>
            <FormattedMessage {...globalMessages.rawThicknessSuperplan} identifier={'rawThicknessSuperplan'} />
          </TextField>
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedThickness', 0) + _.get(props, 'status.sortingInfeedThicknessOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedThicknessOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedThickness', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <br />
        <br />
        <span>
          <WidthIcon style={{ float: 'left', margin: '2px', height: '12px' }} />
        </span>
        <span>
          <TextField>
            <FormattedMessage {...globalMessages.planedWidthSuperplan} identifier={'planedWidthSuperplan'} />
          </TextField>
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedPlannedWidth', 0) + _.get(props, 'status.sortingInfeedPlannedWidthOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedPlannedWidthOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedPlannedWidth', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <br />
        <br />
        <span>
          <ThicknessIcon style={{ float: 'left', margin: '2px', height: '12px' }} />
        </span>
        <span>
          <TextField>
            <FormattedMessage {...globalMessages.planedThicknessSuperplan} identifier={'planedThicknessSuperplan'} />
          </TextField>
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedPlannedThickness', 0) + _.get(props, 'status.sortingInfeedPlannedThicknessOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedPlannedThicknessOffset', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.sortingInfeedPlannedThickness', 0))}</BoxField>
          </Paper>
        </span>
        <br />
        <br />
        <span>
          <PackageIcon style={{ float: 'left', margin: '2px', height: '12px' }} />
        </span>
        <span>
          <TextField>ArticleId</TextField>
        </span>
        <span style={{ float: 'right', margin: '2px' }}>
          <Paper elevation={1}>
            <BoxField>{String(_.get(props, 'status.planerSettings.rawMaterialId', 0))}</BoxField>
          </Paper>{' '}
        </span>
        <br />
        <br />
        <br /> <br />
        <span style={{ float: 'right' }}>
          <Button onClick={() => setIsModal1Opened(true)}>
            <SettingsIcon style={{ width: '1.2rem', height: '1.2rem' }} />
            {/* Set Superplan */}
          </Button>

          <DModal id={'set-superplan'} target={'set-superplan'} isOpened={isModal1Opened} onClose={toggleModal}>
            <ControlModal
              action={'set-superplan'}
              screenstyle={'small'}
              style={{
                position: 'absolute',
                width: '50%',
                height: '60%',
                left: '25%',
                top: '15%',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '2300'
              }}
              close={toggleModal}
            />
          </DModal>
        </span>
      </DataInfo>
      <LineMoreContainer></LineMoreContainer>
    </Wrapper>
  );
}

SuperplanBox.propTypes = {};

export default SuperplanBox;
