import modelFieldMessages from './modelFieldMessages';

class WoodSupplier {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id,
      editable: false
    },
    supplierIndex: {
      key: 'supplierIndex',
      type: 'string',
      title: modelFieldMessages.supplierIndex
    },
    identifier: {
      key: 'name',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    priority: {
      key: 'priority',
      type: 'string',
      title: modelFieldMessages.priority
    }
  };

  static actions = {
    deleteSupplier: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteProduct',
      modal: {
        modal: true,
        buttonText: 'deleteProduct',
        key: 'deleteProduct',
        action: 'deleteProduct',
        actionData: {
          target: 'backend',
          source: 'sepa/woodsuppliers/',
          url: '/api/v1/sepa/woodsuppliers/remove_wood_supplier/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    priorityUp: {
      key: 'priorityUp',
      format: {
        type: 'urlaction',
        action: 'set_priority',
        icon: 'moveUp',
        data: { move: 'up' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.priorityUp
    },
    priorityDown: {
      key: 'priorityDown',
      format: {
        type: 'urlaction',
        action: 'set_priority',
        icon: 'moveDown',
        data: { move: 'down' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.priorityDown
    },
    deleteSupplier: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteProduct',
      modal: {
        modal: true,
        buttonText: 'deleteProduct',
        key: 'deleteProduct',
        action: 'deleteProduct',
        actionData: {
          target: 'backend',
          source: 'sepa/woodsuppliers/',
          url: '/api/v1/sepa/woodsuppliers/remove_wood_supplier/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    syncDatabaseControl: {
      key: 'syncDatabaseControl',
      format: {
        type: 'wsaction',
        icon: 'sync',
        identifier: 'fetch-productsupplier-data',
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.syncDatabaseControl
    },
    editSupplier: {
      key: 'edit',
      type: 'edit',
      identifier: 'editProduct',
      configuration: {
        model: 'supplier',
        url: '/api/v1/sepa/woodsuppliers/'
      }
    }
  };
}

export default WoodSupplier;
