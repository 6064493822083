/*
 *
 * Header reducer
 *
 */

import { fromJS } from 'immutable';

export const initialState = fromJS({
  // compartmentFields,
});

function editControlReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default editControlReducer;
