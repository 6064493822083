/**
 *
 * AccumulatorBox
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, SubInfo, BoxField, PackageContainer, VacuumLocation, RowContainer } from './Wrapper';
// import styled from 'styled-components';
import './test.css';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { MoreIcon, WidthIcon, ChooseSide, ChangeRoute } from '../../../reusable/Icons';
import StatusItem from '../../../StatusItem';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { sendToWs } from '../../../../containers/Websocket/actions';
import Button1 from '../../../Button';

export function PackageInfeedBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  const history = useHistory();

  let current = 'A';
  props.status && props.status.defaultVacuumLocation ? (current = props.status.defaultVacuumLocation) : (current = 'A');

  const [vacuumLocation, setVacuumLocation] = useState(current);

  useEffect(() => {
    setVacuumLocation(current);
  }, [current]);

  function changeDefaultVacuumLocation(current) {
    current === 'A' ? props.dispatch(sendToWs('B', 'set-default-vacuum-location')) : props.dispatch(sendToWs('A', 'set-default-vacuum-location'));
  }

  function getDataForPackage(status, pckg, location) {
    if (status !== null && status[pckg] !== null && !status[pckg].placeEmpty) {
      let currentlyLoading = false;
      if (
        status.currentlyUsing !== null &&
        status.currentlyUsing.id === status[pckg].id &&
        status.currentlyUsing.lamellaThickness === status[pckg].lamellaThickness &&
        status.currentlyUsing.lamellaWidth === status[pckg].lamellaWidth
      )
        currentlyLoading = true;
      return (
        <PackageContainer className={currentlyLoading ? 'using' : null}>
          <RowContainer>
            <VacuumLocation>
              {' '}
              {currentlyLoading ? <ChangeRoute style={{ height: '15px', width: '15px', color: 'black', margin: '1px' }} /> : ''} <b>{location}</b>
            </VacuumLocation>
          </RowContainer>
          <RowContainer>
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <span style={{ margin: 'auto', width: '100%' }}>
                <span style={{ float: 'left', width: '100%' }}>
                  <BoxField>
                    <Paper square style={{ paddingLeft: '5px', boxShadow: 'none' }}>
                      <b> ID: </b>
                      {status[pckg].id}
                    </Paper>
                  </BoxField>
                </span>

                <span style={{ float: 'left', width: '100%' }}>
                  <BoxField>
                    <Paper square style={{ paddingLeft: '5px', boxShadow: 'none' }}>
                      <b> Layers: </b> {status[pckg].numberOfLayers}{' '}
                    </Paper>
                  </BoxField>
                </span>

                <span style={{ float: 'left', width: '100%' }}>
                  <BoxField>
                    <Paper style={{ paddingLeft: '5px', color: 'red', boxShadow: 'none' }} square>
                      <b>
                        {status[pckg].lamellaThickness} x {status[pckg].lamellaWidth} mm
                      </b>{' '}
                    </Paper>
                  </BoxField>
                </span>

                <span style={{ float: 'left', width: '100%', height: '30%' }}>
                  <BoxField>
                    <Paper style={{ paddingLeft: '5px', boxShadow: 'none' }} square>
                      <b> Pieces/Layer: </b> {status[pckg].piecesPerLayer}
                    </Paper>
                  </BoxField>
                </span>

                <span style={{ float: 'left', width: '100%', height: '30%' }}>
                  <BoxField>
                    <Paper style={{ paddingLeft: '5px', fontSize: '11px', boxShadow: 'none' }} square>
                      <b>
                        {' '}
                        Destination: <WidthIcon style={{ width: '15px', height: '10px' }} /> {status[pckg].destination}
                      </b>
                    </Paper>
                  </BoxField>
                </span>
              </span>
            </div>
          </RowContainer>
        </PackageContainer>
      );
    }
    return <PackageContainer />;
  }

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> B11 | Package infeed </SubInfo>
      </Paper>
      <br />
      <StatusItem item={_.get(props, 'status.combinedAllowanceFromVacuum', null)} type="green">
        Vacuum allowance
      </StatusItem>
      <StatusItem item={_.get(props, 'status.emptyFromVacuumToZink', null)} type="green">
        Empty from vacuum to zink
      </StatusItem>
      <br />
      <div style={{ float: 'center', width: '70px', marginLeft: 'auto', marginRight: 'auto' }}>
        <Button
          variant="outlined"
          style={{ height: '25px', float: 'center' }}
          onClick={() => {
            changeDefaultVacuumLocation(vacuumLocation);
          }}>
          <ChooseSide />
        </Button>
      </div>
      <br />
      <div className="float-container">
        <div className="float-child">{getDataForPackage(props.status, 'vacuumRight_29', 'A')}</div>
        <div className="float-child" style={{ float: 'right' }}>
          {getDataForPackage(props.status, 'vacuumLeft_19', 'B')}
        </div>
      </div>
      <div style={{ position: 'absolute', width: '100%', bottom: '-35px' }}>
        <Button1
          onClick={() =>
            history.push({
              pathname: '/packageinfeed',
              props: props.status
            })
          }>
          <MoreIcon style={{ width: '2rem', height: '2rem' }} />
          {/* Detailed view */}
        </Button1>
      </div>
    </Wrapper>
  );
}

PackageInfeedBox.propTypes = {
  status: PropTypes.object,
  children: PropTypes.any,
  plusOne: PropTypes.any,
  minusOne: PropTypes.any,
  dispatch: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapDispatchToProps);

export default compose(withConnect, React.memo)(PackageInfeedBox);
