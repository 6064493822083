/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';

const ModalFooter = styled.div`
  background-color: white;
  display: flex;
  overflow: visible;
  padding: 10px 12px;
  color: black;
  border-radius: 0px 0px 5px 5px;
  height: 6.1vh;
  font-size: 1.5vh;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.08) 0px -4px 8px;
`;

export default ModalFooter;
