import modelFieldMessages from './modelFieldMessages';
import { Order } from '../utils/models';

class Project {
  static packageTypeChoices = {
    Q: modelFieldMessages.qPackageType,
    L: modelFieldMessages.lPackageType
  };

  static fields = {
    group: {
      key: 'group',
      title: modelFieldMessages.group,
      format: {
        type: 'object',
        str: '{id}',
        defaultStr: '',
        keys: ['id']
      }
    },
    orderColor: {
      key: 'orderColor',
      type: 'string',
      format: {
        type: 'color'
      }
      // title: modelFieldMessages.orderColor,
    },
    layers: {
      key: 'layers',
      type: 'foreignkey',
      title: modelFieldMessages.layers
    },
    extraLOversize: {
      key: 'extraLOversize',
      type: 'string',
      title: modelFieldMessages.extraLOversize
    },
    extraQOversize: {
      key: 'extraQOversize',
      type: 'string',
      title: modelFieldMessages.extraQOversize
    },
    glueOpenTime: {
      key: 'glueOpenTime',
      type: 'string',
      title: modelFieldMessages.glueOpenTime
    },
    glueAmount: {
      key: 'glueAmount',
      type: 'string',
      title: modelFieldMessages.glueAmount
    },
    pressTime: {
      key: 'pressTime',
      type: 'string',
      title: modelFieldMessages.pressTime
    },
    status: {
      key: 'status',
      type: 'string',
      title: modelFieldMessages.status
    },
    orderCharge: {
      key: 'orderCharge',
      type: 'string',
      title: modelFieldMessages.orderCharge
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    paNr: {
      key: 'paNr',
      type: 'string',
      title: modelFieldMessages.paNr
    },
    noLayers: {
      key: 'layers',
      type: 'arraylength',
      title: modelFieldMessages.layers
    },
    oversizeWidth: {
      key: 'oversizeWidth',
      type: 'string',
      title: modelFieldMessages.oversizeWidth,
      blank: false,
      validation: ['float']
    },
    oversize: {
      key: 'oversize',
      type: 'string',
      title: modelFieldMessages.oversize,
      blank: false,
      validation: ['float']
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id,
      input: 'number',
      step: '1'
    },
    pressId: {
      key: 'pressId',
      type: 'string',
      title: modelFieldMessages.pressId
    },
    woodType: {
      key: 'woodType',
      type: 'string',
      title: modelFieldMessages.woodType
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.orderQuality,
      blank: false,
      input: 'text'
    },
    projectNumber: {
      key: 'projectNumber',
      type: 'string',
      title: modelFieldMessages.projectNumber,
      blank: false,
      input: 'text'
    },
    projectName: {
      key: 'projectName',
      type: 'string',
      title: modelFieldMessages.projectName,
      blank: false,
      input: 'text'
    },
    client: {
      key: 'client',
      type: 'string',
      title: modelFieldMessages.clientTitle,
      blank: false,
      filterable: true
    },
    pieces: {
      key: 'pieces',
      type: 'string',
      title: modelFieldMessages.piecesTitle,
      width: 2
    },
    incomingLamellaWidth: {
      key: 'incomingLamellaWidth',
      title: modelFieldMessages.incomingLamellaWidth,
      type: 'string'
    },
    incomingLamellaThickness: {
      key: 'incomingLamellaThickness',
      title: modelFieldMessages.incomingLamellaThickness,
      type: 'string'
    },
    lamellaWidth: {
      title: modelFieldMessages.lamellaWidth,
      key: 'lamellaWidth',
      type: 'string',
      blank: false,
      validation: ['float'],
      input: 'number',
      step: '1'
    },
    lamellaThickness: {
      title: modelFieldMessages.lamellaThickness,
      key: 'lamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float'],
      input: 'number',
      step: '.1'
    },
    lamellaLength: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      validation: ['float']
    },
    lamellaPlannedWidth: {
      title: modelFieldMessages.lamellaPlannedWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    lamellaPlannedThickness: {
      title: modelFieldMessages.lamellaPlannedThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.1'
    },
    pressureAvgFinal: {
      title: modelFieldMessages.pressureAvgFinal,
      key: 'pressureAwgFinal',
      type: 'string'
    },
    addedAt: {
      key: 'addedAt',
      title: modelFieldMessages.addedAt,
      format: {
        type: 'date'
      }
    },
    erpDate: {
      key: 'erpDate',
      title: modelFieldMessages.erpDate,
      format: {
        type: 'date'
      }
    },
    completedAt: {
      key: 'completedAt',
      title: modelFieldMessages.completedAt,
      format: {
        type: 'date'
      }
    },
    lineNumber: {
      key: 'line',
      title: modelFieldMessages.lineNumber
    },
    priority: {
      key: 'priority',
      title: modelFieldMessages.priority
    },
    panelWidth: {
      key: 'panelWidth',
      type: 'string',
      title: modelFieldMessages.panelWidth,
      blank: false,
      validation: ['float', 'aboveZero'],
      recalculate: true,
      input: 'number',
      step: '1'
    },
    panelLength: {
      key: 'panelLength',
      type: 'string',
      title: modelFieldMessages.panelLength,
      blank: false,
      validation: ['float', 'aboveZero'],
      recalculate: true,
      input: 'number',
      step: '1'
    },
    panelsNumber: {
      key: 'panelsNumber',
      type: 'string',
      title: modelFieldMessages.panelsNumber,
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '1'
    },
    panelsDone: {
      key: 'panelsDone',
      type: 'string',
      title: modelFieldMessages.panelsDone
    },
    dimensions: {
      key: 'dimensions',
      title: modelFieldMessages.panelDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{panelThickness} x {panelWidth} x {panelLength} mm',
        keys: ['panelThickness', 'panelWidth', 'panelLength']
      }
    },
    kvhDimensions: {
      key: 'Dimensions',
      title: modelFieldMessages.kvhDimensions,
      format: {
        type: 'eval_function',
        str: "{isKVH} == true ? ({lamellaThickness} + 'x' + {lamellaWidth} + 'x' + {lamellaLength}) : ({panelThickness} + 'x' + {panelWidth} + 'x' + {panelLength})",
        keys: {
          isKVH: '{isKVH}',
          lamellaWidth: '{lamellaWidth}',
          lamellaLength: '{lamellaLength}',
          lamellaThickness: '{lamellaThickness}',
          panelThickness: '{panelThickness}',
          panelWidth: '{panelWidth}',
          panelLength: '{panelLength}'
        },
        evalFinalString: true
      }
    },
    orderDimensions: {
      key: 'dimensions',
      title: modelFieldMessages.lamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{panelThickness} x {orderWidth} x {panelLength} mm',
        keys: ['panelThickness', 'orderWidth', 'panelLength']
      }
    },
    packageDimensions: {
      key: 'package_dimensions',
      title: modelFieldMessages.lamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} mm',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength']
      }
    },
    incomingPackageDimensions: {
      key: 'package_dimensions',
      title: modelFieldMessages.incomingLamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{incomingLamellaThickness} x {incomingLamellaWidth} mm',
        keys: ['incomingLamellaThickness', 'incomingLamellaWidth']
      }
    },
    type: {
      key: 'type',
      type: 'choices',
      title: modelFieldMessages.orderType,
      format: {
        type: 'discreteChoices',
        choices: Order.packageTypeChoices
      }
    },
    isKVH: {
      key: 'isKVH',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.isKVH
    },
    projectActive: {
      key: 'active',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.projectActive
    },
    jobCode: {
      key: 'jobCode',
      type: 'string',
      title: modelFieldMessages.jobCode,
      blank: false
    },
    rowsNumber: {
      key: 'rowsNumber',
      type: 'string',
      title: modelFieldMessages.rowsNumber,
      blank: false
    },
    colsNumber: {
      key: 'colsNumber',
      type: 'string',
      title: modelFieldMessages.colsNumber,
      blank: false
    },
    kvhBoards: {
      key: 'kvhBoards',
      type: 'string',
      title: modelFieldMessages.kvhBoards,
      blank: false
    },
    orderPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getCLTOrderImage'
    },
    recipe: {
      key: 'recipe',
      type: 'foreignkey',
      title: modelFieldMessages.recipe
    },
    recipeSelect: {
      key: 'recipeSelect',
      type: 'fetchSelect',
      title: modelFieldMessages.recipeSelect,
      format: {
        type: 'urlChoices',
        select: '/api/v1/recipes/all',
        identifier: 'identifier',
        dataChange: 'id'
      }
    },
    inPackageAlready: {
      key: 'inPackageAlready',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.inPackageAlready
    },
    numberOfPanels: {
      key: 'numberOfPanels',
      type: 'string',
      title: modelFieldMessages.numberOfPanels
    },
    description: {
      key: 'description',
      type: 'string',
      title: modelFieldMessages.description
    },
    getThickness: {
      key: 'projectWidths',
      type: 'get-list',
      format: {
        select: '/api/v1/orders/projects/',
        type: 'get-list',
        title: modelFieldMessages.getThickness,
        changeVariable: 'lamellaWidth'
      }
    }
  };

  static actions = {
    deleteProject: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteProject',
      modal: {
        modal: true,
        buttonText: 'deleteProject',
        key: 'deleteProject',
        action: 'deleteProject',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'orders/projects/',
          url: '/api/v1/orders/projects/remove_projects/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteControlOrder: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteProject',
      modal: {
        modal: true,
        buttonText: 'deleteProject',
        key: 'deleteProject',
        action: 'deleteProject',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'projectsqueue',
          identifier: 'project-to-remove-from-queue',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editProject: {
      key: 'edit',
      type: 'edit',
      identifier: 'editProject',
      configuration: {
        model: 'project',
        url: '/api/v1/orders/projects/'
      }
    }
  };
}

export default Project;
