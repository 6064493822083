import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100% - 95px);
  text-align: center;
  position: relative;

  .float-container {
    width: 20vw;
    height: 17vh;
    display: inline-block;
  }

  @media only screen and (max-width: 920px) {
    .float-container {
      width: 45vw;
      height: 17vh;
      display: inline-block;
    }
  }

  .float-child {
    width: 45%;
    float: left;
    overflow: hidden;
    height: 17vh;
    text-align: center;
  }
`;

export const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

export const TextField = styled.div`
  float: left;
  margin: 0px;
  font-size: smaller;
  font-weight: 600;
`;

export const BoxField = styled.div`
  font-size: smaller;
  float: left;
  width: 100%;
`;

export const PackageContainer = styled.div`
  //border: 1px dashed #256D7B;
  color: black;
  font-size: 12px;
  z-index: 100;
  //background: rgb(37 109 123 / 20%);
  height: 60%;
  width: 100%;
  text-align: left;
  display: inline-block;

  span,
  b {
    height: auto;
    width: auto;
  }

  &.full {
    border: 1px solid black;
    z-index: 4;
    background: #ffc107;
  }

  &.using {
    background-color: rgb(132 211 0);
    color: black;
    box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 47%);
  }
`;

export const VacuumLocation = styled.div`
  //border: 1px dashed #256D7B;
  color: black;
  font-size: 12px;
  z-index: 100;
  //background: rgb(37 109 123 / 20%);
  height: 60%;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: center;
`;

export const RowContainer = styled.div`
  margin: 0 auto;
  width: '100%';
  text-align: center;
`;
