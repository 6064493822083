import styled from 'styled-components';

const BoxLight = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1px;
  margin-right: 1px;
  //background: #969696; /* Fallback */
  //background: radial-gradient(40% 35%, #ccc, #969696 60%);

  @keyframes color {
    0% {
      background-color: rgba(255, 255, 255, 0);
    }
    50% {
      background-color: rgba(132, 211, 0, 1);
    }
    100 {
      background-color: rgba(255, 255, 255, 0);
    }
  }

  &.area {
    background-color: rgba(255, 255, 255, 0);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  &.automatic {
    background-color: rgba(132, 211, 0, 1) !important;
  }

  &._0 {
    border: 2px solid #00c1f9;
  }

  &._0.true {
    background-color: #00c1f9;
  }

  &._1 {
    border: 2px solid #84d300;
  }

  &._1.true {
    background-color: #a1ff04;
  }

  &._2 {
    border: 2px solid #f7c315;
  }

  &._2.true {
    background-color: #f7c315;
  }

  &._2.noLeer {
    display: none;
  }

  &._3 {
    border: 2px solid #ff8f00;
  }

  &._3.true {
    background-color: #ff8f00;
  }

  &._4 {
    border: 2px solid #ff8f00;
  }

  &._4.true {
    background-color: #ff8f00;
  }

  &._5 {
    border: 2px solid #ff8f00;
  }

  &._5.true {
    background-color: #ff8f00;
  }
`;

export default BoxLight;
