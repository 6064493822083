import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import selectWebsocket from './selectors';

import { SEND_TO_WS, FETCH_ARCHIVE_CLT_STATUS } from './constants';
import { uuidv4 } from '../../utils/helpers';
import { cltStatusReceived } from './actions';
import axios from 'axios';
import pako from 'pako';
import { Buffer } from 'buffer';
import { makeSelectLoginState } from '../App/selectors';

function* sendToWsSaga(action) {
  const a = action;
  const state = yield select(selectWebsocket());
  const user = yield select(makeSelectLoginState());
  try {
    state.websocket.websocketObject.send(
      JSON.stringify({
        type: a.wsType,
        timestamp: new Date().getTime(),
        sent: true,
        guid: uuidv4(),
        data: JSON.stringify(a.data),
        frontend: true,
        user: user
      })
    );
  } catch (error) {
    console.log('sendToWsSaga error', error);
  }
}

function* fetchOldStatusSaga(action) {
  if (action != undefined) {
    const apiCall = () => {
      return axios
        .post(`${window.location.origin}/api/v1/productionline/linestatus/get_data/`, {
          statusTime: action.statusTime
        })
        .then((response) => response.data)
        .catch((err) => {
          throw err;
        });
    };

    try {
      const response = yield call(apiCall);
      if (response.json !== undefined) {
        const start = new Date().getTime();
        const buffer = new Buffer.from(response.json, 'base64');
        let output = pako.inflate(buffer, { to: 'string' });
        output = output.replaceAll('Infinity', -1);
        const ws = JSON.parse(output);
        ws.inflateCycle = new Date().getTime() - start;
        ws.originalSize = output.length;
        ws.compressedSize = buffer.length;
        console.log(ws);
        yield put(cltStatusReceived(ws));
      }
    } catch (error) {
      console.log(error);
    }
  }
}
export default function* cltStatusSaga() {
  yield all([yield takeLatest(SEND_TO_WS, sendToWsSaga), yield takeLatest(FETCH_ARCHIVE_CLT_STATUS, fetchOldStatusSaga)]);
}
