import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  .red {
    color: red;
  }
`;

export default Wrapper;
