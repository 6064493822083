import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100% - 95px);
  text-align: center;
  position: relative;

  .float-container {
    width: 20vw;
    display: inline-block;
  }

  @media only screen and (max-width: 920px) {
    .float-container {
      width: 45vw;
      height: 17vh;
      display: inline-block;
    }
  }

  .float-child {
    width: 50%;
    float: left;
    overflow: hidden;
    height: 8.5vh;
    text-align: center;
  }

  .button {
    color: black;
    font-size: smaller;
  }
`;

export const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

export const TextField = styled.div`
  float: left;
  margin: 5px;
  font-weight: bold;
  font-size: smaller;
`;

export const BoxField = styled.div`
  padding: 3px;
  font-size: smaller;
`;

export const PackageContainer = styled.div`
  color: white;
  font-size: 12px;
  z-index: 100;
  //background: white;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;

  span,
  b {
    height: auto;
    width: auto;
  }

  &.full {
    border: 1px solid black;
    z-index: 4;
    background: rgb(37 109 123 / 87%);
  }

  &.using {
    background-color: #84d300;
    color: black;
  }
`;
