/*
 ** EditView ante define fields for editview reducer
 */
import { BSHOrder, Order, Layer, RecipeLayer, Recipe, IncomingPackage } from '../../../utils/models';

const ante_config = {
  orderFields: [
    [
      [{ ...Order.fields.orderPicture }],
      [{ ...Order.fields.quality }, { ...Order.fields.id }, { ...Order.fields.orderNumber }, { ...Order.fields.panelLength }, { ...Order.fields.panelWidth }, { ...Order.fields.panelThickness }]
    ],
    [[{ ...Order.fields.panelPicture }]]
  ],

  recipeOrderFields: [
    [
      [{ ...Recipe.fields.orderPicture }],
      [
        { ...Recipe.fields.identifier },
        { ...Order.fields.panelThickness },
        { ...Order.fields.oversize, halfsize: true, editable: true },
        { ...Order.fields.lenOversizeL, halfsize: true, editable: true },
        { ...Order.fields.oversizeWidth, halfsize: true },
        { ...Order.fields.lenOversizeC, halfsize: true }
      ]
    ]
  ],

  incomingPackageFields: [
    [
      // [
      //   { ...Order.fields.orderPicture }
      // ],
      [
        // { ...IncomingPackage.fields.id},
        { ...IncomingPackage.fields.identifier },
        // { ...IncomingPackage.fields.quality},
        // { ...IncomingPackage.fields.woodType},
        { ...IncomingPackage.fields.thickness },
        { ...IncomingPackage.fields.width },
        { ...IncomingPackage.fields.lamellaLength },
        // { ...IncomingPackage.fields.lotNumber},
        { ...IncomingPackage.fields.packageNumber },
        { ...IncomingPackage.fields.piecesPerLayer }
      ]
    ]
  ],

  bshorderFields: [
    [
      /* [
      { ...BSHOrder.fields.orderPicture }
    ], */
      [
        // { ...BSHOrder.fields.quality },
        { ...Order.fields.id },
        { ...Order.fields.orderNumber },
        { ...Order.fields.lamellaThickness },
        { ...Order.fields.lamellaPlannedThickness },
        { ...Order.fields.lamellaWidth },
        { ...Order.fields.lamellaPlannedWidth },
        { ...Order.fields.lamellaLength }
      ]
    ]
  ],

  recipeBSHOrderFields: [
    [
      [{ ...BSHOrder.fields.orderPicture }],
      [
        { ...Recipe.fields.identifier },
        { ...BSHOrder.fields.lamellaThickness },
        { ...BSHOrder.fields.oversize, halfsize: true, editable: true },
        { ...BSHOrder.fields.lenOversizeL, halfsize: true, editable: true },
        { ...BSHOrder.fields.oversizeWidth, halfsize: true },
        { ...BSHOrder.fields.lenOversizeC, halfsize: true }
      ]
    ]
  ],

  layerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, editable: false },
        { ...Layer.fields.qualityChoices, width: 1.2, halfsize: true },
        { ...Layer.fields.orientation, width: 1.2, halfsize: true },
        { ...Layer.fields.rotation, width: 1.2, halfsize: true }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLength, width: 2 },
        { ...Layer.fields.numOfLamellas, width: 2 },
        { ...Layer.fields.minTakeAway, width: 1, halfsize: true },
        { ...Layer.fields.maxTakeaway, width: 1, halfsize: true }
      ]
    ]
  ],

  recipeLayerFields: [
    [
      [
        { ...RecipeLayer.fields.layerNo, width: 1.2 },
        { ...RecipeLayer.fields.woodType, width: 1.2 },
        { ...RecipeLayer.fields.orientation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.rotation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.quality, width: 1.2 }
      ],
      [
        { ...RecipeLayer.fields.lamellaPlannedThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaPlannedWidth, width: 2 },
        { ...RecipeLayer.fields.minTakeAway, width: 2 }
      ],
      [
        { ...RecipeLayer.fields.hasGlue, width: 1.2 },
        { ...RecipeLayer.fields.whiteGlue, width: 1.2 }
      ]
    ]
  ],

  kvhLayerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 2, halfsize: true },
        { ...Layer.fields.separatorDistance, width: 2, halfsize: true },
        { ...Layer.fields.orientation, width: 1.2, halfsize: true },
        { ...Layer.fields.rotation, width: 1.2, halfsize: true },
        { ...Layer.fields.quality, width: 2, halfsize: true },
        { ...Layer.fields.woodType, width: 2, halfsize: true },
        { ...Layer.fields.batchId, width: 1.2 },
        { ...Layer.fields.primingTop, width: 2, quartersize: true },
        { ...Layer.fields.primingBottom, width: 2, quartersize: true },
        { ...Layer.fields.chamferTop, width: 2, quartersize: true },
        { ...Layer.fields.chamferBottom, width: 2, quartersize: true }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
        { ...Layer.fields.numKVHLayers, width: 2, halfsize: true },
        { ...Layer.fields.numLamellasPerLayer, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLength, width: 2, halfsize: true },
        { ...Layer.fields.numOfLamellas, width: 2, halfsize: true }
      ]
    ]
  ]
};

export default ante_config;
