import globalMessages from '../../globalMessages';
import { MODEL_LOADED, MODEL_SAVED, CONFIG_LOADED, CLEAR_RESPONSE, ORDER_NUMEBR_GENERATED } from './constants';
import produce from 'immer';
import { generalValidator, planerSetupValidator, schneiderValidator } from '../../utils/modelFunctions/validators';
import { orderChildCalculateValues, arborealMaterialCodeCalculator, schilligerChildCalculateValues, schneiderModelCalculator } from '../../utils/modelFunctions/calculators';
import projectList from './config/editviewList';
import _ from 'lodash';

const project_name = process.env.REACT_APP_PROJECT_NAME;

// console.log('project_name: ', project_name);
// console.log('project config: ', projectList[project_name]);

const bshLayerFields = projectList[project_name].bshLayerFields;

const orderFields = projectList[project_name].orderFields;

const recipeOrderFields = projectList[project_name].recipeOrderFields;

const incomingPackageFields = projectList[project_name].incomingPackageFields;

const bshorderFields = projectList[project_name].bshorderFields;

const beamFields = projectList[project_name].beamFields;

const beamLayerFields = projectList[project_name].beamLayerFields;

// const recipeBSHOrderFields = projectList[project_name].recipeBSHOrderFields;

const projectFields = projectList[project_name].projectFields;

const projectOrderFields = projectList[project_name].projectOrderFields;

const plateFields = projectList[project_name].plateFields;

// const recipeProjectFields = projectList[project_name].recipeProjectFields;

const layerFields = projectList[project_name].layerFields;

const widthoffsetFields = projectList[project_name].widthoffsetFields;

const recipeLayerFields = projectList[project_name].recipeLayerFields;

// const kvhLayerFields = projectList[project_name].kvhLayerFields;

const orderWithRecipeFields = projectList[project_name].orderWithRecipeFields;

const orderLayerRecipeFields = projectList[project_name].orderLayerRecipeFields;

const layerWithRecipeFields = projectList[project_name].layerWithRecipeFields;

const planerSetupFields = projectList[project_name].planerSetupFields;

const productFields = projectList[project_name].productFields;

const supplierFields = projectList[project_name].supplierFields;

const cltPackageFields = projectList[project_name].cltPackageFields;

const machineSettingFields = projectList[project_name].machineSettings;

// const erpFields = projectList[project_name]['erpFields'];

export const initialState = {
  config: null,
  title: globalMessages.addUser,
  response: null,
  loading: true,
  item: null,
  initialItem: null,
  setFields: null,
  itemDefault: {
    client: '',
    colsNumber: 0,
    isKVH: false,
    isActive: false,
    jobCode: '',
    lamellaLength: 0,
    lamellaThickness: 0,
    lamellaWidth: 0,
    layerNumber: 0,
    layers: [],
    numOfLamellas: 0,
    numOfLamellasWood: 0,
    numOfLamellasIns: 0,
    orderCharge: '',
    orderNumber: '',
    orderWidth: 0,
    oversize: 0,
    oversizeWidth: 0,
    panelLength: 0,
    panelThickness: 0,
    panelWidth: 0,
    quality: '',
    rawThickness: 0,
    rawWidth: 0,
    orientation: 1,
    beamWidth: []
  },
  form: {
    api: {
      url: '/api/v1/orders/all/',
      type: 'POST'
    },
    modelLoaded: false,
    childFields: null,
    defaultValues: {
      orderNumber: Math.floor(Date.now() / 60000),
      separatorDistance: 4,
      oversize: 20,
      oversizeWidth: 60
    },
    defaultLayerValues: {
      oversize: 20,
      oversizeWidth: 20,
      minTakeAway: 20,
      maxTakeaway: 150,
      widthoffset: [],
      lengthoffset: [],
      fixedRecipeLayer: false,
      hollow: false,
      numOfLamellasWood: 0,
      numOfLamellasIns: 0,
      beamWidth: [],
      lengthoffsetWidth: 0
    }
  }
};

function sortLayers(layers) {
  const sortingLayers = layers;
  let l;
  let m;

  if (sortingLayers !== undefined && sortingLayers !== null && Array.isArray(sortingLayers)) {
    l = [...sortingLayers].sort((a, b) => (a.layerNo < b.layerNo ? -1 : 1));
  }

  // Sortiranje offsetov po vrsti (idji vcasih pomesani)
  for (let i = 0; i < l.length; i++) {
    if (l[i]?.widthoffset != undefined && l[i]?.widthoffset.length > 0) {
      l[i].widthoffset = sortWidthOffset(l[i].widthoffset);
    }
  }

  for (let i = 0; i < l.length; i++) {
    if (l[i]?.lengthoffset != undefined && l[i]?.lengthoffset.length > 0) {
      l[i].lengthoffset = sortLengthOffset(l[i].lengthoffset);
    }
  }

  return l;
}

function sortWidthOffset(widthoffset) {
  const sortingWidthOffset = widthoffset;
  let l;
  if (sortingWidthOffset !== undefined && sortingWidthOffset !== null && Array.isArray(sortingWidthOffset)) {
    l = [...sortingWidthOffset].sort((a, b) => (a.offset < b.offset ? -1 : 1));
  }
  return l;
}

function sortLengthOffset(lengthoffset) {
  const sortingLengthOffset = lengthoffset;
  let m;
  if (sortingLengthOffset !== undefined && sortingLengthOffset !== null && Array.isArray(sortingLengthOffset)) {
    m = [...sortingLengthOffset].sort((a, b) => (a.offset < b.offset ? -1 : 1));
  }
  return m;
}

function setFields(config, draft) {
  switch (config.model) {
    case 'order':
      // console.log('config.model: ', config.model);
      draft.form.fields = orderFields;
      draft.form.childFields = layerFields;
      draft.form.modelName = 'order';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], generalValidator);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], orderChildCalculateValues);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    case 'bshorder':
      draft.form.fields = bshorderFields;
      draft.form.childFields = bshLayerFields;
      draft.form.modelName = ' bshorder';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    // case 'layers':
    //   draft.form.fields = layerFields;
    //   draft.form.childFields = widthoffsetFields;
    //   draft.form.modelName = 'layer';
    //   draft.form.childFieldName = 'widthoffset';
    //   draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);;
    //   draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
    //   draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
    //
    //   draft.form.modelLoaded = true;

    //   break;

    case 'layers':
      draft.form.fields = layerFields;
      draft.form.childFields = null;
      draft.form.modelName = 'layer';
      draft.form.childFieldName = null;
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      //draft.form.countChildrenProp = 'id';
      break;

    case 'beam':
      draft.form.fields = beamFields;
      draft.form.childFields = beamLayerFields;
      draft.form.modelName = 'order';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = null;
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    case 'recipe':
      draft.form.fields = recipeOrderFields;
      draft.form.childFields = recipeLayerFields;
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelName = 'order';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);

      draft.form.modelLoaded = true;
      break;

    case 'packages':
      draft.form.fields = incomingPackageFields;
      draft.form.childFields = null;
      draft.form.childFieldName = null;
      draft.form.childFieldNameSingle = null;
      draft.form.modelName = 'packages';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);

      draft.form.modelLoaded = true;
      break;

    case 'project':
      draft.form.fields = projectFields;
      draft.form.childFields = projectOrderFields;
      draft.form.childFieldName = 'orders';
      draft.form.childFieldNameSingle = 'order';
      draft.form.modelName = 'project';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'cltPackage':
      draft.form.fields = cltPackageFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'cltPackage';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'machineSetting':
      draft.form.fields = machineSettingFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'machineSetting';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'orderWithRecipe':
      draft.form.fields = orderWithRecipeFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'orderWithRecipe';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'layerWithRecipe':
      draft.form.fields = orderLayerRecipeFields;
      draft.form.childFields = layerWithRecipeFields;
      draft.form.childFieldName = 'recipeLayer';
      draft.form.childFieldNameSingle = 'id';
      draft.form.modelName = 'layerWithRecipe';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'arollaPlates':
      draft.form.fields = plateFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'ProjectPlate';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'product':
      draft.form.fields = productFields;
      draft.form.childFields = null;
      draft.form.modelName = 'product';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);

      draft.form.modelLoaded = true;
      break;

    case 'supplier':
      draft.form.fields = supplierFields;
      draft.form.childFields = null;
      draft.form.modelName = 'supplier';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);

      draft.form.modelLoaded = true;
      break;

    case 'cltpackage':
      draft.form.fields = orderFields;
      draft.form.childFields = layerFields;
      draft.form.modelName = 'order';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);

      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    case 'erp':
      draft.form.fields = _.get(projectList, '[' + project_name + "]['erpFields']", []);
      draft.form.childFields = null;
      draft.form.modelName = 'erp';
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = null;
      draft.form.modelValidator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'planerSetup':
      draft.form.fields = planerSetupFields;
      draft.form.childFields = null;
      draft.form.modelName = 'planerSetup';
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = null;
      draft.form.modelValidator = planerSetupValidator;
      draft.form.childCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, [project_name, 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.options = _.get(projectList, [project_name, 'otherOptions', config.model], null);
      draft.form.modelLoaded = true;
      break;

    default:
      draft.form.fields = [];
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = '';
      draft.form.modelValidator = null;
      draft.form.childCalculator = null;
      draft.form.modelCalculator = null;
      draft.form.childCalculator = null;
      draft.form.modelLoaded = false;
      break;
  }
}

const editViewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MODEL_SAVED:
        draft.response = action.response;
        break;
      case CLEAR_RESPONSE:
        draft.response = null;
        break;
      case CONFIG_LOADED:
        draft.config = action.config;
        setFields(action.config, draft);
        break;
      case ORDER_NUMEBR_GENERATED:
        draft.form.defaultValues.orderNumber = action.time;
        break;
      case MODEL_LOADED: {
        draft.item = action.response;
        if (draft.item?.layers) draft.item.layers = sortLayers(action.response.layers);
        draft.initialItem = draft.item;
        draft.loading = false;
        break;
      }
      default:
        return state;
    }
  });

export default editViewReducer;
