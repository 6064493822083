/**
 *
 * ProducStackerBox
 *
 */

import React from 'react';

import Wrapper from './Wrapper';
import Button from '../../../Button';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { sendToWs } from '../../../../containers/Websocket/actions';

const BatchBox = styled.div`
  color: black;
  font-size: 12px;
  text-align: left;
  height: 57px;
  margin-bottom: 7px;
`;

const InfoBox = styled.div`
  padding: 2px;
  font-size: smaller;
`;

function hexToRgbA(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
}

export function ProductStackerBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  const getBatchStatus = (title, order) => {
    return order != null ? (
      <BatchBox style={{ backgroundColor: hexToRgbA(order.orderColor, '0.2') }}>
        <span>
          <span style={{ width: '70%', float: 'left' }}>
            <Paper elevation="0" style={{ backgroundColor: hexToRgbA(order.orderColor, '0.2') }} square>
              <InfoBox>
                <b>{title} </b>
              </InfoBox>
            </Paper>
          </span>
          <span style={{ width: '30%', float: 'right' }}>
            <Button
              className="button"
              onClick={() => {
                props.dispatch(sendToWs(title, 'delete-stacker-order'));
              }}>
              Clear
            </Button>
          </span>
          <span>
            <Paper elevation="0" square>
              <InfoBox>
                <b> ID: </b>
                {order.id}
              </InfoBox>
            </Paper>
          </span>
          <span>
            <Paper elevation="0" square>
              <InfoBox>
                <b> Length: </b> {order.lamellaLength}mm | <b> No.: </b> {order.numLamellas}
              </InfoBox>
            </Paper>
          </span>
        </span>
      </BatchBox>
    ) : (
      <BatchBox>
        <InfoBox>
          <b>{title}</b>
        </InfoBox>
      </BatchBox>
    );
  };

  return (
    <Wrapper>
      <span style={{ fontSize: '12px' }}>{getBatchStatus('superplanOrder', _.get(props, 'status.superplanOrder', null))}</span>
      <span style={{ fontSize: '12px' }}>{getBatchStatus('stackingEntryJD', _.get(props, 'status.stackingEntryJD', null))}</span>
      <span style={{ fontSize: '12px' }}>{getBatchStatus('stackingEntryLastDataSent', _.get(props, 'status.stackingEntryLastDataSent', null))}</span>
      <span style={{ fontSize: '12px' }}>{getBatchStatus('zPressOrder', _.get(props, 'status.zPressOrder', null))}</span>
      <span style={{ fontSize: '12px' }}>{getBatchStatus('zPressLastDataSent', _.get(props, 'status.zPressLastDataSent', null))}</span>
      <span style={{ fontSize: '12px' }}>{getBatchStatus('stackingJD', _.get(props, 'status.stackingJD', null))}</span>
    </Wrapper>
  );
}

ProductStackerBox.propTypes = {
  dispatch: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapDispatchToProps);

export default compose(withConnect, React.memo)(ProductStackerBox);
