import styled from 'styled-components';

const Wrapper = styled.div`
  /* width: 100%;
text-align: inherit; */
  font-size: smaller;
  font-family: Poppins;
  height: 85%;
  /* a: hover {
background: #f9b115;
color: #000015;
transition: all 250ms linear;
} */

  .orderButton {
    background-color: #f7c315;
    -webkit-transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
  }

  .prodlineButton {
    font-size: 0.8rem;
    text-align: center !important;
    background: none;
    font-family: Poppins;
  }

  .prodlineMore {
    font-size: 0.8rem;
    text-align: center !important;
    background: none;
    font-family: Poppins;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
    background: #f0f0f3;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* background: white;
box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px #aeaec052; */
  }

  .prodlinemore hover: {
    background: #f9b115;
    box-shadow: #3c4b64 1px 1px 1px 1px;
    color: #000015;
    transition: all 250ms linear;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
  }

  @keyframes redPulsate {
    0% {
      background-color: rgba(255, 0, 0, 0);
    }
    50% {
      background-color: rgba(255, 0, 0, 0.4);
    }
    100% {
      background-color: rgba(255, 0, 0, 0);
    }
  }

  .red-pulsating-button {
    animation: redPulsate 1s infinite;
  }

  .incrementButton {
    text-align: center !important;
    background: #8a93a221;
    box-shadow: 1px 1px 1px -1px rgb(250 250 250), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin-right: 11px;
    margin-left: 11px;
    margin-top: 0.2vh;
    height: 2vh;
  }

  .incrementbutton hover: {
    background: #f9b115;
    box-shadow: #3c4b64 1px 1px 1px 1px;
    color: #000015;
    transition: all 250ms linear;
  }

  .settingsButton {
    text-align: center !important;
    background: rgb(255 255 255 / 30%);
    box-shadow: 1px 1px 1px -1px rgb(250 250 250), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    //margin-top:0.4vh;
    height: 2.5vh;
    margin: 0px;
    padding: 0px;
    font-family: Poppins;
  }

  .settingsbutton hover: {
    background: #f9b115;
    box-shadow: #3c4b64 1px 1px 1px 1px;
    color: #000015;
    transition: all 250ms linear;
  }

  .infeedButton {
    text-align: left;
    box-shadow: 1px 1px 1px -1px rgb(250 250 250), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    background: #8a93a221;
    font-size: 1em;
    padding: 3px;
    width: 130px;
    font-family: Poppins;
    margin-left: 10px;
  }

  .infeedHorizontal {
    margin-left: 10px;
  }

  .infeedbutton hover: {
    background: #f9b115;
    box-shadow: #3c4b64 1px 1px 1px 1px;
    color: #000015;
    transition: all 250ms linear;
  }

  .clearButton {
    text-align: left;
    box-shadow: 1px 1px 1px -1px rgb(250 250 250), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    //background:  green;
    font-size: 0.6em;
    font-weight: bold;
    //padding: 3px;
    //width:30px;
    min-width: 0px;
    font-family: 'Montserrat';
  }

  .smallbutton {
    background-image: -webkit-linear-gradient(top, #f4f1ee, #fff);
    background-image: linear-gradient(top, #f4f1ee, #fff);
    border-radius: 50%;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), inset 0px 2px 1px 1px white, inset 0px -3px 1px 1px rgba(204, 198, 197, 0.5);
    float: left;
    width: 3rem;
    height: 3rem;
    margin: 0 30px 30px 0;
    position: relative;

    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }

  .smallbutton:after {
    color: #ccc;
    content: '';
    display: block;
    font-size: 30px;

    text-decoration: none;
    text-shadow: 0px -1px 1px #bdb5b4, 1px 1px 1px white;
    position: absolute;
    width: 3rem;
    height: 3rem;
  }

  .smallbutton:hover {
    background-image: -webkit-linear-gradient(top, #fff, #f4f1ee);
    background-image: linear-gradient(top, #fff, #f4f1ee);
    color: #f9b115;
  }
`;

export default Wrapper;
