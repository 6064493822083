/* List of projects using the same origin App - Register/Add new project config here */

// import weihag from './weihag-jdqueue-config';
// import ante from './ante-jdqueue-config';
// import arolla from './arolla-jdqueue-config';
// import smartlam from './smartlam-jdqueue-config';
// import sodra from './sodra-jdqueue-config';
// import schilliger from './schilliger-jdqueue-config';
// import bruno from './bruno-jdqueue-config';
// import sepa from './sepa-jdqueue-config';
// import mm2 from './mm2-jdqueue-config';
import arboreal from './arboreal-linestatusBox-config';
// import eztiles from './eztiles-jdqueue-config';
// import mosser from './mosser-jdqueue-config';
// import schneider from './schneider-jdqueue-config';
// import solis from './solis-jdqueue-config';
// import baseProject from './baseProject-jdqueue-config';

const jdqueueList = {
  //   weihag,
  //   ante,
  //   arolla,
  //   smartlam,
  //   sodra,
  //   schilliger,
  //   bruno,
  //   sepa,
  //   mm2,
  arboreal
  //   mosser,
  //   eztiles,
  //   schneider,
  //   solis,
  //   baseProject
};

export default jdqueueList;
