import styled from 'styled-components';

const Plank = styled.div`
  width: 100%;
  background-color: #414042;
  height: 20px;
  margin-top: -10px;
  margin-bottom: 20px;

  &.test {
    background-color: #84d300;
  }
`;

export default Plank;
