import { createGlobalStyle } from 'styled-components';
// import {rtl} from '../app/containers/App/index'
import ProjectList from '../app/containers/App/config/projectList';

const project_name = process.env.REACT_APP_PROJECT_NAME;
const projectList = ProjectList;
const project_configuration = projectList[project_name];
const rtl = _.get(project_configuration, 'direction', 'ltr');

const GlobalStyle = createGlobalStyle`
  @-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    font-weight: 500;
    font-size: small;
 
    
    @media only screen and (min-width: 992px) {
      overflow-y: hidden;
    }
  }

  body {
    font-family: Poppins, Montserrat, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: Poppins, Aclonica, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label,
  span {
    font-family: Poppins, Aclonica, Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  .rotate {
    width: 40px;
    -webkit-animation:spin 8s linear infinite;
    -moz-animation:spin 8s linear infinite;
    animation:spin 8s linear infinite;
  }
`;

export default GlobalStyle;
