import { POPMODAL, ADDMODAL } from '../../containers/ModalManager/constants';

import produce from 'immer';

export const initialState = {
  opened: false
};

const imagePlaceholderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADDMODAL:
        draft.opened = true;
        return draft;
      case POPMODAL:
        draft.opened = false;
        return draft;
      default:
        return state;
    }
  });

export default imagePlaceholderReducer;
