import { BROADCAST_MESSAGE, CLOSE_MESSAGE_MODAL, SHOW_MESSAGE_TO_CLIENT } from './constants';
import { uuidv4 } from '../../utils/helpers';

export const broadcastMessage = (title, message) => {
  const id = uuidv4();
  return {
    type: BROADCAST_MESSAGE,
    payload: {
      type: 'broadcast_message',
      data: { title, message },
      uuid: id
    }
  };
};

export const closeMessageModal = () => ({
  type: CLOSE_MESSAGE_MODAL
});

export const showMessageToClient = (message) => ({
  type: SHOW_MESSAGE_TO_CLIENT,
  payload: message
});
