/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = (state) => state.global || initialState;

const selectRouter = (state) => state.router;

/* Project configuration global state regarding .env */
const makeSelectConfig = () => createSelector(selectGlobal, (globalState) => globalState.config);

const makeSelectCurrentUser = () => createSelector(selectGlobal, (globalState) => globalState.currentUser);

const makeSelectLoading = () => createSelector(selectGlobal, (globalState) => globalState.loading);

const makeSelectLocation = () => createSelector(selectRouter, (routerState) => routerState.location);

const makeSelectArchiveMode = () => createSelector(selectGlobal, (globalState) => globalState.archiveMode);

const makeSelectArchiveModeDate = () => createSelector(selectGlobal, (globalState) => globalState.archiveModeDate);

const makeSelectLoginState = () => createSelector(selectGlobal, (globalState) => globalState.loginState);

const makeSelectKeyboardState = () => createSelector(selectGlobal, (globalState) => globalState.keyboardState);

export {
  selectGlobal,
  makeSelectCurrentUser,
  makeSelectLoading,
  makeSelectLocation,
  makeSelectConfig,
  makeSelectArchiveMode,
  makeSelectArchiveModeDate,
  makeSelectLoginState,
  makeSelectKeyboardState
};
