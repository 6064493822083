/**
 *
 * ChartView
 *
 */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, Label, ReferenceLine, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import ChartWrapper from './chartStyle';
import _ from 'lodash';

function ChartView(props) {
  // eslint-disable-line react/prefer-stateless-function

  // console.log("ChartView data: ", props.data);
  // console.log("Length: ", props.data.range);

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [data, setData] = useState([{ value: 0 }]);

  useEffect(() => {
    setData(_.get(props, 'data.chartData', [{ value: 0 }]));
    setMin(_.get(props, 'data.minValue', 0));
    setMax(_.get(props, 'data.maxValue', 0));
  }, [props.data]);

  const formatYAxisTick = (tickItem) => {
    if (tickItem >= 1000000) {
      return `${(tickItem / 1000000).toFixed(1)}M`;
    } else if (tickItem >= 1000) {
      return `${(tickItem / 1000).toFixed(1)}K`;
    }
    return tickItem;
  };

  function generateChart() {
    const chart = [];
    const maxC = (
      <ChartWrapper>
        <div className="chartMax">
          <span className="chartText">{'max: ' + Math.round(max * 100) / 100}</span>
        </div>
      </ChartWrapper>
    );
    const minC = (
      <ChartWrapper>
        <div className="chartMin">
          <span className="chartText">{'min: ' + Math.round(min * 100) / 100}</span>
        </div>
      </ChartWrapper>
    );
    const current = (
      <ChartWrapper>
        <div className="chartMin">
          <span className="chartText">{'current: ' + data[data.length - 1]?.value}</span>
        </div>
      </ChartWrapper>
    );

    chart.push(maxC);
    chart.push(
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgb(80, 80, 80)',
          height: '100%',
          width: 'calc(100% - 10px)',
          borderRadius: '10px',
          padding: '5px',
          margin: '5px',
          paddingLeft: '0px'
        }}>
        <ResponsiveContainer flexGrow="1">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={['auto', 'auto']} tick={{ fill: 'whitesmoke', paddingLeft: 0 }} tickFormatter={formatYAxisTick} />
            <Line type="monotone" dataKey="value" stroke="#f7c315" strokeWidth={2} dot={false} />
            <ReferenceLine y={data[data.length - 1]?.value} strokeWidth={1} stroke="white">
              <Label
                position="top"
                value={data[data.length - 1]?.value}
                style={{
                  fontWeight: 'bold',
                  zIndex: 10,
                  fill: 'whitesmoke',
                  stroke: 'black',
                  strokeWidth: 1.5,
                  paintOrder: 'stroke'
                }}
              />
            </ReferenceLine>
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
    chart.push(minC);
    chart.push(current);
    return chart;
  }

  return generateChart();
}

ChartView.propTypes = {
  actionsBar: PropTypes.func,
  passwordPrompt: PropTypes.func,
  isSecure: PropTypes.bool,
  password: PropTypes.string,
  canvas: PropTypes.string,
  canvasHeight: PropTypes.string,
  options: PropTypes.object
};

export default ChartView;
