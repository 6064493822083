import FormattedMessage from '../../../components/TranslationWrapper';
import globalMessages from '../../../globalMessages';
import BackupIcon from '@material-ui/icons/Backup';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const sidebarConfig = {
  modalroutes: ['modal-lagershort', 'modal-drying', 'modal-lagerlong', 'modal-orders-queue', 'modal-jd-queue', 'modal-material-needed', 'modal-packages-queue', 'modal-sorting-queue'],
  sidebar: [
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: <FormattedMessage {...globalMessages.productionline} identifier={'productionline'}/>,
    //   to: '/dashboard',
    //   icon: 'cilSettings',
    // },
    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: <FormattedMessage {...globalMessages.productionstatus} identifier={'productionstatus'}/>,
    //   to: '/dashboard',
    //   icon: 'cilShareBoxed',
    //   _children: [
    //     {
    //       _tag: 'CSidebarNavItem',
    //       id: 'modal-material-needed',
    //       name: <FormattedMessage {...globalMessages.materialNeededTitle} identifier={'materialNeededTitle'}/>,
    //       to: '/dashboard',
    //     },
    //     {
    //       _tag: 'CSidebarNavItem',
    //       id: 'modal-packages-queue',
    //       name: <FormattedMessage {...globalMessages.packagesQueueTitle} identifier={'packagesQueueTitle'}/>,
    //       to: '/dashboard',
    //     },
    //   ],
    // },
    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: <FormattedMessage {...globalMessages.maschines} identifier={'maschines'}/>,
    //   route: '/machines',
    //   icon: 'cilLayers',
    //   _children: [
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: <FormattedMessage {...globalMessages.infeed} identifier={'infeed'}/>,
    //       to: '/rawinfeed',
    //     },
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: <FormattedMessage {...globalMessages.presstacker} identifier={'presstacker'}/>,
    //       to: '/presstacker',
    //     },
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: <FormattedMessage {...globalMessages.stacker} identifier={'stacker'}/>,
    //       to: '/stacker',
    //     },
    //   ],
    // },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage {...globalMessages.productionline} identifier={'productionline'} />,
      to: '/fiber',
      icon: 'cilSpreadsheet'
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage {...globalMessages.products} identifier={'products'} />,
      to: '/products',
      icon: 'cilSpreadsheet'
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage {...globalMessages.suppliers} identifier={'suppliers'} />,
      to: '/suppliers',
      icon: 'cilSpreadsheet'
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage {...globalMessages.eventlog} identifier={'eventlog'} />,
      to: '/eventlog/events',
      icon: 'cilSpreadsheet'
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage {...globalMessages.vnc} identifier={'vnc'} />,
      to: '/vnc',
      icon: 'cilSpreadsheet'
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage {...globalMessages.statistics} identifier={'statistics'} />,
      to: '/statistics',
      icon: 'cilSpreadsheet'
    },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: <FormattedMessage {...globalMessages.statistics} identifier={'statistics'}/>,
    //   to: '/rautestats',
    //   icon: 'cilSpreadsheet',
    // },

    {
      _tag: 'CSidebarNavDivider'
    }
  ],
  indicatorItems: [
    {
      ident: 'backend',
      message: globalMessages.indicatorBackend,
      active: false,
      icon: <BackupIcon />
    },
    {
      ident: 'control',
      message: globalMessages.indicatorControl,
      active: false,
      icon: <SettingsRemoteIcon />
    },
    {
      ident: 'scanner',
      message: globalMessages.indicatorZebra,
      active: false,
      icon: <QrCodeScannerIcon />
    }
  ],
  statusItem: []
};

export default sidebarConfig;
