import styled from 'styled-components';

const NavMenu = styled.div`
  color: white;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'><line x1='0' y1='10' x2='10' y2='0' stroke-width='1' stroke='#414042' /></svg>")
    #323232;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 14px, auto;
  //font-size: 13px;
  text-align: center;
  padding-bottom: 6px;
  padding-top: 15px;
  width: 100%;
`;

export default NavMenu;
