export const xlamBanner = [
  '808 140',
  `
    <title>xlam banner</title>
    <svg xmlns="http://www.w3.org/2000/svg" transform="translate(-600)" xml:space="preserve" width="264.898mm" height="40.9023mm" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 -800 28504 4268"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
    <style type="text/css">
    <![CDATA[
        .filb0 {fill:black;fill-rule:nonzero}
    ]]>
    </style>
    </defs>
    <g id="Layer_x0020_1" >
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <path class="filb0" d="M917 741l348 -711 564 0 -603 1046 650 1062 -593 0 -360 -726 -358 726 -565 0 620 -1062 -604 -1046 560 0 341 711
        zm1776 677l-852 0 0 -378 852 0 0 378
        zm876 343l904 0 0 377 -1393 0 0 -2108 489 0 0 1731
        zm2297 -33l-626 0 -122 410 -515 0 687 -2108 262 0 0 2 2 -2 262 0 688 2108 -515 0 -123 -410
        zm-514 -376l401 0 -197 -654 -8 0 -196 654
        zm2416 153l9 0 476 -1475 647 0 0 2108 -489 0 0 -1358 -9 -1 -464 1359 -329 0 -456 -1339 -9 1 0 1338 -489 0 0 -2108 641 0 472 1475
        zm3258 381l9 0 784 -1856 212 0 0 2108 -174 0 0 -1770 -9 -2 -756 1772 -124 0 -773 -1815 -8 1 0 1814 -174 0 0 -2108 232 0 781 1856
        zm2672 -336l-925 0 -212 588 -180 0 775 -2108 163 0 768 2108 -179 0 -210 -588zm-870 -155l814 0 -400 -1117 -8 0 -406 1117
        zm3139 743l-174 0 -1197 -1804 -9 2 0 1802 -174 0 0 -2108 174 0 1197 1799 9 -1 0 -1798 174 0 0 2108
        zm1666 -588l-924 0 -213 588 -180 0 775 -2108 164 0 768 2108 -180 0 -210 -588
        zm-869 -155l813 0 -400 -1117 -8 0 -405 1117
        zm3066 459c-46,76 -132,148 -257,214 -125,67 -282,100 -472,100 -242,0 -440,-83 -595,-250 -154,-167 -231,-384 -231,-649l0 -386
        c0,-261 74,-473 224,-637 150,-164 343,-246 580,-246 229,0 411,60 545,181 135,120 200,274 196,460l-4 9 -159 0
        c0,-146 -50,-266 -151,-361 -100,-94 -242,-141 -427,-141 -189,0 -341,68 -457,205 -116,137 -174,312 -174,527l0 389
        c0,220 60,401 181,542 121,140 278,211 472,211 147,0 268,-22 362,-67 93,-44 158,-95 195,-155l0 -539 -567 0 0 -148 739 0 0 741
        zm1662 -736l-945 0 0 873 1093 0 0 147 -1267 0 0 -2108 1260 0 0 148 -1086 0 0 792 945 0 0 148zm685 67l0 953 -174 0 0 -2106 702 0
        c226,0 399,49 520,148 120,99 181,243 181,434 0,112 -32,212 -97,300 -64,88 -155,153 -272,193 130,33 224,92 281,176 58,84 86,190 86,318l0 198
        c0,65 8,124 23,177 16,52 41,94 77,127l0 35 -176 0c-36,-35 -61,-84 -76,-147 -14,-62 -21,-128 -21,-195l0 -192
        c0,-130 -42,-232 -126,-307 -83,-74 -200,-112 -349,-112l-579 0zm0 -148l508 0
        c190,0 329,-38 417,-114 87,-77 131,-180 131,-311 0,-139 -43,-245 -131,-321 -87,-74 -220,-112 -397,-112l-528 0 0 858z"/>
    </g>
    </svg>
  `
];
