/*
 *
 * Websocket constants
 *
 */

export const DEFAULT_ACTION = 'app/Websocket/DEFAULT_ACTION';
export const CLT_STATUS_RECEIVED = 'app/Websocket/CLT_STATUS_RECEIVED';
export const CONTROL_STATUS_CHANGED = 'app/Websocket/CONTROL_STATUS_CHANGED';
export const SCANNER_STATUS_RECEIVED = 'app/Websocket/SCANNER_STATUS_RECEIVED';
export const SCANNER_STATUS_CHANGED = 'app/Websocket/SCANNER_STATUS_CHANGED';
export const SEND_TO_WS = 'app/Websocket/SEND_TO_WS';
export const SEND_TO_BACKEND = 'app/Websocket/SEND_TO_BACKEND';
export const NEW_CLT_STATUS = 'app/Websocket/NEW_CLT_STATUS';
export const UPDATE_COMPONENT = 'app/Websocket/UPDATE_COMPONENT';
export const FETCH_ARCHIVE_CLT_STATUS = 'app/Websocket/FETCH_ARCHIVE_CLT_STATUS';
export const VNC_CLIENT_LIST = 'app/Websocket/VNC_CLIENT_LIST';
