/**
 *
 * Package
 *
 */

import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import TextWrapper from './TextWrapper';

function Package(props) {
  // eslint-disable-line react/prefer-stateless-function
  return (
    <Wrapper className={props.className}>
      <TextWrapper>{props.children}</TextWrapper>
    </Wrapper>
  );
}

Package.propTypes = {
  children: PropTypes.any
};

export default Package;
