/*
 *
 * EventLog actions
 *
 */

import { ADDMODAL, POPMODAL } from './constants';

export function addModal(modal, submodal, data, callback) {
  return {
    type: ADDMODAL,
    modal,
    submodal,
    data,
    callback
  };
}

export function popModal(modal) {
  return {
    type: POPMODAL,
    modal
  };
}
