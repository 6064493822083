import { SET_COMPARTMENT_VALUES } from './constants';
import { put, all, takeLatest } from 'redux-saga/effects';
import { sendToWs } from '../Websocket/actions';

function* saveSaga(action) {
  const p = action;
  yield put(sendToWs(p.item.changedsettings, p.compartment));
}

export default function* editControlSaga() {
  yield all([yield takeLatest(SET_COMPARTMENT_VALUES, saveSaga)]);
}
