/*
 *
 * Fiber reducer
 *
 */
import produce from 'immer';
import { DEFAULT_ACTION, SET_LINE_DRAWER_CONTENT, TOGGLE_LINE_DRAWER, SET_DETAILVIEW_MODAL_CONTENT, TOGGLE_DETAILVIEW_MODAL, TOGGLE_ZOOM, TOGGLE_FOCUS } from './constants';

export const initialState = {
  drawerContent: 'EMPTY',
  drawerOpened: false,
  detailviewContent: 'EMPTY',
  detailviewOpened: false,
  focus: null,
  zoom: false
};

/* eslint-disable default-case, no-param-reassign */
const fiberReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LINE_DRAWER_CONTENT:
        console.log('Reducer SET_LINE_DRAWER_CONTENT: ', action.drawerContent);
        draft.drawerContent = action.drawerContent;
        break;
      case TOGGLE_LINE_DRAWER:
        console.log('Reducer TOGGLE_LINE_DRAWER: ', action.drawerOpened);
        draft.drawerOpened = action.drawerOpened;
        break;
      case SET_DETAILVIEW_MODAL_CONTENT:
        console.log('Reducer SET_DETAILVIEW_MODAL_CONTENT: ', action.detailviewContent);
        draft.detailviewContent = action.detailviewContent;
        break;
      case TOGGLE_DETAILVIEW_MODAL:
        console.log('Reducer TOGGLE_LINE_DRAWER: ', action.detailviewOpened);
        draft.detailviewOpened = action.detailviewOpened;
        break;
      case TOGGLE_ZOOM:
        console.log('Reducer TOGGLE_ZOOM: ', action.zoom);
        draft.zoom = action.zoom;
        break;
      case TOGGLE_FOCUS:
        console.log('Reducer TOGGLE_FOCUS: ', action);
        draft.focus = action.focus;
        break;
      case DEFAULT_ACTION:
        break;
    }
  });

export default fiberReducer;
