import useCurrentUser from './useCurrentUser';
import _ from 'lodash';

export const useHasPermissions = (permissionNames, location) => {
  const { currentUser } = useCurrentUser();
  const { permissions } = currentUser;

  if (!permissions || _.get(location, 'pathname', '').includes('login')) {
    return false;
  }

  if (typeof permissionNames === 'number') {
    return permissions.includes?.(permissionNames);
  } else if (Array.isArray(permissionNames)) {
    return permissions.some((permissionName) => Boolean(permissionNames.includes?.(permissionName)));
  } else {
    return false;
  }
};
