/**
 *
 * MenuItem
 *
 */

import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import Wrapper from './Wrapper';

function StatusItem(props) {
  // eslint-disable-line react/prefer-stateless-function
  return <Wrapper className={`${props.item ? 'full' : 'empty'} ${props.type} ${props.item == null ? 'red' : props.className}  ${props.size == null ? '' : props.size}`}>{props.children}</Wrapper>;
}

StatusItem.propTypes = {
  type: PropTypes.string,
  item: PropTypes.bool
};

export default StatusItem;
