/**
 *
 * LineStatusBox
 *
 */

import { useState } from 'react';

import Wrapper from './Wrapper';
import Button from '../../../Button';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import _ from 'lodash';
import FormattedMessage from '../../../TranslationWrapper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import config_lists from './config/config_lists';

import InlineWrapper from '../../../reusable/InlineWrapper';
import VerticallyAlignedText from '../../../reusable/VerticallyAlignedText';
import { MoreIcon, SortingIcon } from '../../../reusable/Icons';
import globalMessages from '../../../../globalMessages';
import axios from 'axios';

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

const TextField = styled.div`
  float: left;
  margin: 5px;
  font-weight: bold;
  font-size: smaller;
`;

const BoxField = styled.div`
  padding: 3px;
  font-size: smaller;
`;

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

// const Bold = styled.div`
//   //font-size: small;
//   font-weight: bold;
// `;

export function LineStatusBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  // console.log('LineStatusBox: ', props);

  const project_name = process.env.REACT_APP_PROJECT_NAME;

  const config_options = config_lists[project_name];

  // console.log('LineStatusBox: ', props, 'config_options', config_options);

  const [isModal1Opened, setIsModal1Opened] = useState(false);
  const [productionState, setProductionState] = useState('');

  const handleChange = (e) => {
    console.log('CHANGE: ', e);
    setProductionState(e.target.value);
    axios.post('api/v1/productionline/assign_production_state/', { state: e.target.value }).then((response) => console.log('Production state set: ', response));
    // axios.post('api/v1/packages/incomingpackages/sepa_assign_package/', rawPackageData).then(response => console.log('get_product'));
  };

  // function toggleModal(string) {
  //   if (string === 'set-zink') {
  //     setIsModal1Opened(!isModal1Opened);
  //     return;
  //   }

  //   setIsModal1Opened(false);
  // }

  let orderColor = null;
  if (_.get(props, 'status.producing', null) != null) {
    orderColor = props.status.producing.orderColor;
  }

  // console.log('orderColor: ', orderColor);
  // const history = useHistory();
  // const dispatch = useDispatch();
  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo>LineStatus</SubInfo>
      </Paper>
      <br />
      <div style={{ height: '100%', textAlign: 'center' }}>
        <InlineWrapper>
          {config_options === undefined ? (
            <div style={{ width: '80%', height: '80%' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={productionState} label="Status" onChange={handleChange}>
                  <MenuItem value={'production'}>Production</MenuItem>
                  <MenuItem value={'mechanical issues'}>Mechanical issues</MenuItem>
                  <MenuItem value={'etc'}>etc...</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : (
            <div style={{ width: '80%', height: '80%' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={productionState} label="Status" onChange={handleChange}>
                  {config_options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </InlineWrapper>

        <VerticallyAlignedText height="15%">
          <DataInfo>
            <span>
              <SortingIcon style={{ float: 'left', margin: '2px' }} />
            </span>
            <span>
              <TextField>
                {' '}
                <FormattedMessage {...globalMessages.status} identifier={'status'} />:
              </TextField>
            </span>
            {
              /* _.get(props, 'status.producing', null) && */
              <span style={{ float: 'right', marginRight: '10%' }}>
                <Paper elevation={1}>
                  <BoxField> {productionState} </BoxField>
                </Paper>{' '}
              </span>
            }
          </DataInfo>
        </VerticallyAlignedText>
        <div style={{ position: 'absolute', width: '100%' }}></div>
        <div source="prodlineButton" style={{ position: 'absolute', width: '100%', bottom: '60px', textAlign: 'center' }}>
          <Button
            onClick={() =>
              //   history.push({
              //     pathname: '/zinc',    //nastavit moras novi path in stran, kjer se bo kazal production state
              //     props: props.productionstatus
              //   }
              // )
              console.log('CLICKED, need to redirect')
            }>
            <MoreIcon className="prodlineMore" style={{ width: '2rem', height: '2rem' }} />
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

LineStatusBox.propTypes = {};

export default LineStatusBox;
