export const ledinekLogo = [
  '608 140',
  `
    <title>ledinek logo</title>
    <svg xmlns="http://www.w3.org/2000/svg" width="608" height="140" version="1.1">
        <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2981423087888" transform="translate(30.0000 15.0000) scale(2.60000, 2.60000)" style="fill:#f7c315;">
            <polygon points="177,0 190,0 188,17 197,0 211,0 200,18 208,38 194,38 188,21 186,38 173,38 "/>
            <polygon points="149,0 174,0 173,10 160,10 160,14 171,14 170,24 159,24 158,28 171,28 170,38 145,38 "/>
            <polygon points="112,0 128,0 133,23 135,0 146,0 141,38 126,38 121,15 119,38 108,38 "/>
            <polygon points="96,0 109,0 105,38 92,38 "/>
            <polygon points="32,0 57,0 56,10 43,10 43,14 54,14 53,24 42,24 41,28 54,28 53,38 28,38 "/>
            <polygon points="4,0 17,0 14,28 26,28 25,38 0,38 "/>
            <path d="M60 0l13 0c4,0 6,0 8,1 5,0 8,3 10,8 1,3 1,6 0,10 0,4 -1,7 -2,10 -1,2 -3,4 -4,5 -2,2 -4,3 -6,3 -2,1 -4,1 -6,1 -1,0 -2,0 -4,0l-13 0 4 -38zm12 9l-2 21c2,0 4,-1 5,-2 1,-1 3,-4 3,-9 1,-5 0,-8 -1,-9 -1,-1 -2,-1 -3,-1 0,0 -1,0 -2,0z"/>
        </g>
        </g>
    </svg>
  `
];
